import { ReactSDKClient } from '@optimizely/react-sdk'

import { DeviceType } from '@/hooks/use-window-size/types'
import { OPTIMIZELY_FEATURE } from '@/optimizely/constants'

import heroPolygons from './hooks/hero-polygons.json'
import { GeolocatedHeroData, Polygon, HomepageRedesignVariableType } from './types'

export const isPointInPolygon = (long: number, lat: number, polygon: Polygon): boolean => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    const x = long,
        y = lat

    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0],
            yi = polygon[i][1]
        const xj = polygon[j][0],
            yj = polygon[j][1]

        const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
        if (intersect) {
            inside = !inside
        }
    }

    return inside
}

export const getGeolocatedHeroData = (longitude: number, latitude: number): GeolocatedHeroData | undefined => {
    const heroData = heroPolygons.find((hero: GeolocatedHeroData) => {
        const { polygon } = hero
        return isPointInPolygon(longitude, latitude, polygon)
    })

    return heroData
}

export const initialHomepageRedesignVariables: HomepageRedesignVariableType = {
    getinPricing: false,
    exposedFilters: false,
    scrollableArrows: false,
    expandedLayout: false,
    eventsUnder100: false,
    aboveTheFold: false,
    tileCardSize: false,
    browseCategories: false,
}

/**
 * @returns {HomepageRedesignVariableType} The object of values of the homepage redesign feature
 *    (for either desktop or mobile).
 *  There is no homepage redesign feature for tablet, so tablet always returns false.
 */
export const getHomepageRedesignVariable = (
    optimizely: ReactSDKClient | null,
    deviceType: DeviceType,
): HomepageRedesignVariableType => {
    const feature =
        deviceType === DeviceType.MOBILE
            ? OPTIMIZELY_FEATURE.homepageRedesignMobile
            : OPTIMIZELY_FEATURE.homepageRedesignDesktop

    if (!optimizely?.isFeatureEnabled(feature)) return initialHomepageRedesignVariables

    const featureVariables = optimizely?.getFeatureVariables(feature) as HomepageRedesignVariableType

    return {
        ...initialHomepageRedesignVariables,
        ...featureVariables,
    }
}
