export const SEARCH_TERM_SESSION_STORAGE_KEY = 'searchTerm'

export const setSearchTermInStorage = (value: string): void => {
    if (!sessionStorage) return
    sessionStorage.setItem(SEARCH_TERM_SESSION_STORAGE_KEY, value)
}

export const getStoredSearchTermValue = (): string => {
    if (typeof sessionStorage !== 'undefined') {
        return sessionStorage.getItem(SEARCH_TERM_SESSION_STORAGE_KEY) ?? ''
    }
    return ''
}

export const removeSearchTermFromStorage = (): void => {
    if (!sessionStorage) return
    sessionStorage.removeItem(SEARCH_TERM_SESSION_STORAGE_KEY)
}
