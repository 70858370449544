import type { $FC } from 'react'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import debounce from 'lodash/debounce'
import inRange from 'lodash/inRange'
import { useRouter } from 'next/router'

import { useQuery } from '@/api'
import { FIRST_TRENDING_SET } from '@/components/shared/searchbar/components/focus-search-suggestions/components/browse-by-trending/types'
import { getStoredSearchTermValue, setSearchTermInStorage } from '@/components/shared/searchbar/utils'
import type { CATEGORY_IDS } from '@/constants'
import { CATEGORY_NAMES, MAX_DEBOUNCE_WAIT, MIN_CHARACTERS_FOR_SEARCH, SEARCH_SUGGESTION_ENDPOINT } from '@/constants'
import usePageName from '@/hooks/use-page-name'
import { useRecentSearchResults } from '@/hooks/use-recent-search-results'
import type { PerformerImage, ProductionListSelectorType, Venue } from '@/types/app-types'
import { PageType } from '@/types/app-types'
import { saveLastItemListName, trackSelectFocusSearchSuggestion, trackSelectSearchSuggestion } from '@/utils/analytics'
import { trackEvent } from '@/utils/analytics/ga360'
import { trackSelectPerformer } from '@/utils/analytics/segment'

import TrendingsContext from '../trendings/context'
import { AnalyticsIds } from '../utils/analytics'

import {
    getAlgoliaQueryId,
    getPerformerById,
    getPerformersWithAssets,
    getProductionListRows,
    getProductions,
    getVenues,
    hasResults,
} from './selectors'
import type {
    CursorStateByCategory,
    SuggestionContextType,
    SuggestionDataType,
    SuggestionDispatch,
    SuggestionProviderProps,
    SuggestionSelectorType,
    SuggestionStateType,
} from './types'
import { AlgoliaIndexTypes } from './types'
import {
    clearAlgoliaQueryIdCookie,
    getSuggestionCursorState,
    initialAlgoliaQueryIdsSelector,
    updateAlgoliaQueryIdCookie,
} from './utils'

const FIVE_THOUSAND_MILES = 8045000 // 5,000 miles
const initialOptionsState = { includeIpAddress: true, radius: FIVE_THOUSAND_MILES }
export const initialCursorPositionByCategory = {
    performerSelectState: null,
    productionSelectState: null,
    venueSelectState: null,
}

export const defaultContextValue: SuggestionContextType = {
    state: {
        navigating: { current: false },
        query: '',
        cursorPosition: NaN,
        cursorPositionByCategory: initialCursorPositionByCategory,
    },
    selectors: {
        performers: [],
        productionListRows: [],
        productions: [],
        venues: [],
        hasResults: false,
        algoliaQueryIds: initialAlgoliaQueryIdsSelector,
    },
    dispatch: {
        setQuery: () => null,
        setOptions: () => null,
        setSearchTerm: () => null,
        handleNavigateTrendingItem: () => null,
        handleNavigateRecentItem: () => null,
        handleSetCursorPosition: () => null,
        handleGoToSelectedItem: () => null,
        handleNavigatePerformerItem: () => null,
        handleNavigateProductionItem: () => null,
        handleNavigateVenueItem: () => null,
    },
}

const SuggestionsContext = createContext<SuggestionContextType>(defaultContextValue)
const { Provider } = SuggestionsContext

export const SuggestionsProvider: $FC<SuggestionProviderProps> = ({ initialProps, children }) => {
    const { pathname, push: nextRouterPush, query: urlParams } = useRouter() || {}

    useEffect(() => {
        if (pathname === '/home') {
            clearAlgoliaQueryIdCookie()
        }
    }, [pathname])

    const searchTerm = getStoredSearchTermValue()

    // Tied to controlled components, this needs to be kept up to date to ensure the UI is reflective of what has been typed
    const [query, setQuery] = useState<string>((urlParams?.searchTerm as string) || searchTerm)

    // Invoking `setDebouncedQuery` short-circuits the debounce logic, prefer `updateDebouncedQuery` below
    const [debouncedQuery, setDebouncedQuery] = useState(query)

    // We only want one instance of this function, if it changes with a dependency it can break search
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const updateDebouncedQuery = useCallback(
        debounce((query: string) => setDebouncedQuery(query), MAX_DEBOUNCE_WAIT),
        [],
    )

    const [options, setOptions] = useState<typeof initialOptionsState>(initialOptionsState)
    const { recentSearchResults, addRecentSearchResult } = useRecentSearchResults()
    const pageName = usePageName()
    const navigating = useRef(false)

    const [cursorPosition, setCursorPosition] = useState(0) // 0 = unselectes, 1 ~ n = index of selected item
    const [cursorPositionByCategory, setCursorPositionByCategory] = useState<CursorStateByCategory>(
        initialCursorPositionByCategory,
    )

    const {
        selectors: { trendings },
    } = useContext(TrendingsContext)

    const resetSuggestionCursorState = () => {
        setCursorPosition(0)
        setCursorPositionByCategory(initialCursorPositionByCategory)
    }

    const handleNavigateTrendingItem = useCallback((title: string, index: number) => {
        setSearchTerm(title)

        trackSelectFocusSearchSuggestion({
            title: title,
            focusGroup: 'Trending',
            pageLocation: window.location.href,
            index: index + 1,
        })
    }, [])

    const handleNavigateRecentItem = useCallback((title: string, index: number) => {
        setSearchTerm(title)

        trackSelectFocusSearchSuggestion({
            title: title,
            focusGroup: 'Recents',
            pageLocation: window.location.href,
            index: index + 1,
        })
    }, [])

    const handleNavigatePerformerItem = useCallback(
        (performer: PerformerImage, index: number) => {
            const performerByID = getPerformerById(contextValue.state, performer.id)

            updateAlgoliaQueryIdCookie(
                AlgoliaIndexTypes.PERFORMERS,
                contextValue.selectors.algoliaQueryIds.performer,
                index,
            )

            trackSelectPerformer(index, performerByID)
            setSearchTerm(performer.name)

            addRecentSearchResult({
                type: 'performer',
                title: performer.name,
                url: performer.url,
            })

            trackEvent('Search Selection', `${pageName} Page`, 'Performer', performer.id, false)

            trackSelectSearchSuggestion({
                name: performer.name,
                pageType: 'Performer',
                category: performer.category,
                pageLocation: window.location.href,
                index: index + 1,
            })
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [addRecentSearchResult, pageName],
    )

    const handleNavigateProductionItem = useCallback(
        (production: ProductionListSelectorType, index: number) => {
            const categoryId: CATEGORY_IDS | undefined = production.category

            updateAlgoliaQueryIdCookie(
                AlgoliaIndexTypes.PRODUCTIONS,
                contextValue.selectors.algoliaQueryIds.production,
                index,
            )

            addRecentSearchResult({
                type: 'production',
                title: production.name,
                url: production.href,
            })

            trackEvent('Search Selection', `${pageName} Page`, 'Production', production.id, false)

            trackSelectSearchSuggestion({
                name: production.name,
                pageType: 'Production',
                category: categoryId && CATEGORY_NAMES[categoryId],
                pageLocation: window.location.href,
                index: index + 1,
            })

            saveLastItemListName(AnalyticsIds.SearchBar)
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [addRecentSearchResult, pageName],
    )

    const handleNavigateVenueItem = useCallback(
        (venue: Venue, index: number) => {
            const venueUrl: string | undefined = venue.webPath || venue.organicUrl

            setSearchTerm(venue.name)
            updateAlgoliaQueryIdCookie(AlgoliaIndexTypes.VENUES, contextValue.selectors.algoliaQueryIds.venue, index)

            if (venueUrl) {
                addRecentSearchResult({
                    type: 'venue',
                    title: venue.name,
                    url: venueUrl,
                })
            }

            trackEvent('Search Selection', `${pageName} Page`, 'Venue', venue.id, false)

            trackSelectSearchSuggestion({
                name: venue.name,
                pageType: PageType.Venue,
                category: undefined,
                pageLocation: window.location.href,
                index: index + 1,
            })
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [addRecentSearchResult, pageName],
    )

    const handleGoToSelectedItem = useCallback(() => {
        if (!query) {
            // for Recent Search AND Trending

            if (cursorPosition <= recentSearchResults.length) {
                // Recent Search
                const trendingListIndexPosition = cursorPosition - 1

                const { title: recentSearchTitle, url: recentSearchPath } =
                    recentSearchResults[trendingListIndexPosition]

                handleNavigateRecentItem(recentSearchTitle, trendingListIndexPosition)
                navigating.current = true
                nextRouterPush(recentSearchPath)
            }

            const endRangeIndex = recentSearchResults.length + trendings.length + 1 // +1 because the `end` in `inRange` does not considered in range

            if (inRange(cursorPosition, recentSearchResults.length, endRangeIndex)) {
                // Trending
                const trendingListIndexPosition = cursorPosition - 1 - recentSearchResults.length
                const trendingTitle = trendings?.[trendingListIndexPosition]?.term || ''
                const trendingPath = trendings?.[trendingListIndexPosition]?.url || ''

                handleNavigateTrendingItem(trendingTitle, trendingListIndexPosition)
                navigating.current = true
                nextRouterPush(trendingPath)
            }
        } else {
            // Query has search term(s)
            const { performerSelectState, productionSelectState, venueSelectState } = cursorPositionByCategory

            if (performerSelectState) {
                const selectedPerformerIndex = performerSelectState - 1
                const selectedPerformer = contextValue.selectors.performers[selectedPerformerIndex]

                handleNavigatePerformerItem(selectedPerformer, selectedPerformerIndex)

                if (!selectedPerformer?.url) {
                    return
                }

                navigating.current = true
                nextRouterPush(selectedPerformer.url)
            } else if (productionSelectState) {
                const selectedProductionIndex = productionSelectState - 1
                const selectedProduction = contextValue.selectors.productionListRows[selectedProductionIndex]

                handleNavigateProductionItem(selectedProduction, selectedProductionIndex)

                if (!selectedProduction?.href) {
                    return
                }

                navigating.current = true
                nextRouterPush(selectedProduction.href)
            } else if (venueSelectState) {
                const selectedVenueIndex = venueSelectState - 1
                const selectedVenue = contextValue.selectors.venues[selectedVenueIndex]

                handleNavigateVenueItem(selectedVenue, selectedVenueIndex)

                if (!selectedVenue?.webPath) {
                    return
                }

                navigating.current = true
                nextRouterPush(selectedVenue.webPath)
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        cursorPosition,
        cursorPositionByCategory,
        query,
        recentSearchResults,
        handleNavigatePerformerItem,
        handleNavigateProductionItem,
        handleNavigateRecentItem,
        handleNavigateTrendingItem,
        handleNavigateVenueItem,
        nextRouterPush,
    ])

    const handleSetCursorPosition = useCallback(
        (pos: number) => {
            if (query && !data) {
                resetSuggestionCursorState()

                return
            }

            if (!query) {
                // follows the same logic in <FocusSearchSuggestions />
                const totalTrendingCount = recentSearchResults.length === 0 ? trendings?.length : FIRST_TRENDING_SET

                const totalItemCount = recentSearchResults.length + totalTrendingCount
                const resultCursorPosition = pos > totalItemCount ? 0 : pos < 0 ? totalItemCount : pos

                setCursorPosition(resultCursorPosition)
            } else {
                const { resultCursorPosition, resultCursorPositionByCategory } = getSuggestionCursorState(
                    contextValue.selectors,
                    pos,
                )

                setCursorPosition(resultCursorPosition)
                setCursorPositionByCategory(resultCursorPositionByCategory)
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [query, recentSearchResults.length],
    )

    // reset cursor position when query updates
    useEffect(() => {
        resetSuggestionCursorState()

        updateDebouncedQuery && updateDebouncedQuery(query)
    }, [query, updateDebouncedQuery])

    const setSearchTerm = (value: string) => {
        setSearchTermInStorage(value)
        setQuery(value)
    }

    const { fetching, data, error } = useQuery<SuggestionDataType>({
        endpoint: SEARCH_SUGGESTION_ENDPOINT,
        params: { query: debouncedQuery, ...options, includeIpAddress: false },
        cacheKey: `${SEARCH_SUGGESTION_ENDPOINT}${debouncedQuery}`,
        enabled: !!debouncedQuery && debouncedQuery.length >= MIN_CHARACTERS_FOR_SEARCH,
        initialData: initialProps?.data,
    })

    const contextValue = useMemo(() => {
        const state: SuggestionStateType = {
            fetching,
            data,
            error,
            query,
            navigating,
            cursorPosition,
            cursorPositionByCategory,
        }

        const selectors: SuggestionSelectorType = {
            performers: getPerformersWithAssets(state),
            productionListRows: getProductionListRows(state),
            productions: getProductions(state),
            venues: getVenues(state),
            hasResults: hasResults(state),
            algoliaQueryIds: getAlgoliaQueryId(state),
        }

        const dispatch: SuggestionDispatch = {
            setQuery,
            setOptions,
            setSearchTerm,
            handleSetCursorPosition,
            handleNavigateRecentItem,
            handleGoToSelectedItem,
            handleNavigateTrendingItem,
            handleNavigatePerformerItem,
            handleNavigateProductionItem,
            handleNavigateVenueItem,
        }

        return { state, selectors, dispatch }
    }, [
        fetching,
        data,
        error,
        query,
        cursorPosition,
        cursorPositionByCategory,
        handleGoToSelectedItem,
        handleNavigatePerformerItem,
        handleNavigateProductionItem,
        handleNavigateRecentItem,
        handleNavigateTrendingItem,
        handleNavigateVenueItem,
        handleSetCursorPosition,
    ])

    return <Provider value={contextValue}>{children}</Provider>
}

export default SuggestionsContext
