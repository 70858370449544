import React, { FC } from 'react'

import { ButtonV2 } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import type { MenuItem as MenuItemType } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import { isCurrencyMenuItem } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'
import ProductionFooter from '@/components/pages/production/components/footer/component'
import { useLocalizedCurrencyFeature } from '@/optimizely/features/internationalization/hooks/localized-currency'

import HeaderCurrencyPicker from '../header-currency-picker'
import MenuItem from '../menu-item'

import styles from './styles.module.scss'

interface MenuProps {
    active: boolean
    activeSubmenuPath: number[]
    backTitle: string
    footerElement?: React.ReactNode
    headerElement: React.ReactNode
    menuItems: MenuItemType[]
    parentLabel: string
    title?: string
    isTrendingCategoryEnabled: boolean
    isNavOpen?: boolean
    onBackButtonClick: () => void
    onCloseButtonClick: () => void
    onItemClick: (index: number) => void
}

const Menu: FC<MenuProps> = ({
    active,
    activeSubmenuPath,
    backTitle,
    footerElement,
    headerElement,
    menuItems,
    parentLabel,
    isTrendingCategoryEnabled,
    title,
    isNavOpen,
    onBackButtonClick,
    onCloseButtonClick,
    onItemClick,
}) => {
    const activeChild = activeSubmenuPath.length ? activeSubmenuPath[0] : null
    const activeChildSubmenuPath = activeSubmenuPath.length > 1 ? activeSubmenuPath.slice(1) : []

    const mergedMenuClassNames = classNames(styles.menu, { [styles.active]: active })
    const router = useRouter()
    const [isLocalizedCurrencyEnabled] = useLocalizedCurrencyFeature()

    return (
        <div className={mergedMenuClassNames}>
            <div className={styles.header}>
                {headerElement}
                <ButtonV2
                    className={styles.closeButton}
                    aria-label="Close Button"
                    data-testid="close-button"
                    icon="close"
                    importance="text"
                    onClick={onCloseButtonClick}
                />
            </div>
            <div className={styles.content}>
                {title && <div className={styles.title}>{title}</div>}
                <ul className={styles.menuItems}>
                    {menuItems.map((menuItem, index) => {
                        menuItem.parentLabel = parentLabel ? `${parentLabel},` : ''
                        if (menuItem.level === 1) {
                            if (isCurrencyMenuItem(menuItem.label)) {
                                if (isLocalizedCurrencyEnabled) {
                                    return (
                                        <HeaderCurrencyPicker
                                            key={index}
                                            index={index}
                                            activeSubmenuPath={activeSubmenuPath}
                                            onBackButtonClick={onBackButtonClick}
                                            onCloseButtonClick={onCloseButtonClick}
                                            onItemClick={onItemClick}
                                        />
                                    )
                                }
                            } else {
                                return (
                                    <MenuItem
                                        activeChild={activeChild}
                                        activeChildSubmenuPath={activeChildSubmenuPath}
                                        index={index}
                                        key={index}
                                        menuItem={menuItem}
                                        menuTitle={backTitle}
                                        parentLabel={parentLabel}
                                        isTrendingCategoryEnabled={!!isTrendingCategoryEnabled}
                                        onBackButtonClick={onBackButtonClick}
                                        onCloseButtonClick={onCloseButtonClick}
                                        onItemClick={onItemClick}
                                    />
                                )
                            }
                        } else {
                            return (
                                <MenuItem
                                    activeChild={activeChild}
                                    activeChildSubmenuPath={activeChildSubmenuPath}
                                    index={index}
                                    key={index}
                                    menuItem={menuItem}
                                    menuTitle={backTitle}
                                    parentLabel={parentLabel}
                                    isTrendingCategoryEnabled={!!isTrendingCategoryEnabled}
                                    onBackButtonClick={onBackButtonClick}
                                    onCloseButtonClick={onCloseButtonClick}
                                    onItemClick={onItemClick}
                                />
                            )
                        }
                    })}
                    {footerElement}
                </ul>
                {router?.pathname === '/[slug]/production/[id]' && (
                    <ProductionFooter renderOnMobileAndTablet open={isNavOpen} />
                )}
            </div>
        </div>
    )
}

export default Menu
