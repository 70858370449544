import React from 'react'

import { useI18n } from '@shopify/react-i18n'
import { Button, Text } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import { CountryFlag } from '@/design-system/custom-icons'

import styles from './styles.module.scss'

interface CurrencyPickerItemProps {
    label: string
    flag?: string
    isSelected: boolean
    onClick: (value: string) => void
}

const CurrencyPickerItem: React.FC<CurrencyPickerItemProps> = ({ label, flag, isSelected, onClick }) => {
    const [i18n] = useI18n()
    const mergedClassName = classNames(styles.currencyLink, {
        [styles.selectedBackground]: isSelected,
    })
    return (
        <li data-testid={`currency-picker-item-${label}`} className={styles.currencyOption}>
            <Button
                data-testid={`currency-picker-item-btn-${label}`}
                className={mergedClassName}
                value={label}
                onClick={() => onClick(label)}
            >
                <CountryFlag flag={flag} />
                <Text altFont weight={isSelected ? 'regular-bold' : 'regular'} className={styles.linkText}>
                    {label}
                    {` - ${i18n.getCurrencySymbol(label || '').symbol}`}
                </Text>
            </Button>
        </li>
    )
}

export default CurrencyPickerItem
