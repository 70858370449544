import { IMAGES } from '@/constants'
import type { CatalogAsset, CatalogImageAsset, Performer } from '@/types/app-types'

export const EXTERNAL_IMAGE_URL_MID_PATH = '/t_production_hero_image_new'

type ImageSize = 'mobile' | 'desktop'

const imageSizeMap = {
    mobile: 'mobilehero',
    desktop: 'hero',
}
const defaultSizeImageMap = {
    mobile: IMAGES.DEFAULT_HERO_MOBILE,
    desktop: IMAGES.DEFAULT_HERO_DESKTOP,
}

export const getExternalHeroImage = (performerId: number, assets: CatalogAsset[] | undefined): string | undefined => {
    if (!assets?.length || !process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX) return undefined
    const asset: CatalogAsset | undefined = assets.find((asset) => asset.id === performerId)
    if (!asset || !asset?.images?.length) return undefined
    const image: CatalogImageAsset | undefined = asset.images.find((image) => image.dataModelName === 'EXTERNAL')
    const imageFilePath = image?.path
    return imageFilePath
        ? `${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}${EXTERNAL_IMAGE_URL_MID_PATH}${imageFilePath}`
        : undefined
}

export const getHeroImage = (
    performerId: number,
    assets: CatalogAsset[] | undefined,
    size: ImageSize = 'mobile',
): string => {
    if (!assets?.length) return ''
    const asset: CatalogAsset | undefined = assets.find((asset) => asset.id === performerId)
    let image: CatalogImageAsset | undefined = undefined

    image = getImageBySize(asset?.images, size)
    // If performer image is not available, check for Subcategory image
    if (!image && asset?.relatedResources && asset.relatedResources.length > 0) {
        const subCategoryResource = asset.relatedResources.find(
            (resource) => resource.type.toLowerCase() === 'subcategory',
        )
        image = getImageBySize(subCategoryResource?.images, size)
        // If Subcategory image is not available, check for Category image
        if (!image) {
            const categoryResource = asset.relatedResources.find(
                (resource) => resource.type.toLowerCase() === 'category',
            )
            image = getImageBySize(categoryResource?.images, size)
        }
    }

    return image?.path.replace(/^(https:|http:)/g, '') || defaultSizeImageMap[size]
}

export const getImageBySize = (
    imageList: CatalogImageAsset[] | undefined,
    size: ImageSize = 'mobile',
): CatalogImageAsset | undefined => {
    return imageList?.find((image: CatalogImageAsset) => image.size?.toLowerCase() === imageSizeMap[size])
}

export const isParkingPerformer = (performers: Performer[]): boolean => {
    if (performers?.length !== 1) {
        // a parking has to be a single production
        return false
    }
    const { name = '', parkingId = -1, master = false } = performers[0]

    return /parking/i.test(name) && parkingId === 0 && master
}
