import type { IconType } from '@vividseats/vivid-ui-kit'

const commonShouldRenderChildrenInUpperLevelIds = ['more', 'auth', 'other']
export const mobileShouldRenderChildrenInUpperLevelIds = [
    'trending',
    'account',
    ...commonShouldRenderChildrenInUpperLevelIds,
]
export const desktopShouldRenderChildrenInUpperLevelIds = [...commonShouldRenderChildrenInUpperLevelIds]

const commonDividerIds = [
    'other',
    'sports-mlb-arizona-diamondbacks',
    'sports-nba-atlanta-hawks',
    'sports-ncaab-all',
    'sports-ncaaf-alabama-crimson-tide',
    'sports-nfl-arizona-cardinals',
    'sports-nhl-anaheim-ducks',
]
export const mobileDividerIds = [
    'trendingCategory',
    'sports', // per removal of web20_trending_category_feature remove this as well since now trending would be the first menu item
    'more',
    'mlb',
    'concerts-music-festivals',
    'theater-comedy',
    ...commonDividerIds,
]
export const desktopDividerIds = [...commonDividerIds]

const commonWithBorderIds = ['auth-sign-out', 'account-sign-out', 'mobile-auth-sign-in', 'mobile-auth-sign-out']
export const mobileWithBorderIds = [...commonWithBorderIds]
export const desktopWithBorderIds = [...commonWithBorderIds]

export const commonStrongIds = []

const commonShouldRenderOnlyWhenAuthenticatedIds = ['account', 'auth-sign-out']
export const mobileShouldRenderOnlyWhenAuthenticatedIds = [
    ...commonShouldRenderOnlyWhenAuthenticatedIds,
    'mobile-auth-sign-out',
]
export const desktopShouldRenderOnlyWhenAuthenticatedIds = [...commonShouldRenderOnlyWhenAuthenticatedIds]

const commonShouldRenderOnlyWhenNotAuthenticated: string[] = []
export const mobileShouldRenderOnlyWhenNotAuthenticatedIds = [
    'account-sign-out',
    'mobile-auth-sign-in',
    ...commonShouldRenderOnlyWhenNotAuthenticated,
]
export const desktopShouldRenderOnlyWhenNotAuthenticatedIds = [
    'desktop-auth-sign-in',
    ...commonShouldRenderOnlyWhenNotAuthenticated,
]

const commonNarrowSubmenuIds: string[] = []
export const mobileNarrowSubmenuIds = [...commonNarrowSubmenuIds]
export const desktopNarrowSubmenuIds = ['account', ...commonNarrowSubmenuIds]

const commonTopSideIds: string[] = []
export const mobileTopSideIds = ['account', ...commonTopSideIds]
export const desktopTopSideIds = [...commonTopSideIds]

const commonRightSideIds: string[] = []
export const mobileRightSideIds = [...commonRightSideIds]
export const desktopRightSideIds = ['more', 'account', ...commonRightSideIds]

export const commonIconsIds = new Map<IconType, string[]>([['user', ['account', 'desktop-auth-sign-in']]])
