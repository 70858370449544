import React, { useContext } from 'react'

import SuggestionsContext from '@/context/suggestions'
import type { ProductionListSelectorType } from '@/types/app-types'

import ProductionSuggestionContent from './components/production-suggestion-content'

type ProductionSuggestionProps = {
    production: ProductionListSelectorType
    index: number
}

const ProductionSuggestion: React.FC<ProductionSuggestionProps> = ({ production, index }) => {
    const {
        state: {
            cursorPositionByCategory: { productionSelectState },
        },
        dispatch: { handleNavigateProductionItem },
    } = useContext(SuggestionsContext)

    const onClick = () => {
        handleNavigateProductionItem(production, index)
    }

    return (
        <ProductionSuggestionContent
            data-testid={`production-suggestion-${production.id}`}
            date={production.date}
            day={production.day}
            title={production.name}
            label={`${production.time} • ${production.location}`}
            href={production.href}
            onClickCapture={onClick}
            isSelected={productionSelectState === index + 1}
        />
    )
}

export default ProductionSuggestion
