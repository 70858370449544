import React, { FC, useContext } from 'react'

import { Text } from '@vividseats/vivid-ui-kit'

import { UK } from '@/constants'
import productionDetailsContext from '@/context/production-details'
import { useGeneralAdmissionUKFeature } from '@/optimizely/features/internationalization/hooks/general-admission-uk'

import { SEATING_RESTRICTION_PLACEMENT } from './types'

export interface SeatingRestrictionsDisclaimerProps {
    placement: SEATING_RESTRICTION_PLACEMENT
    className?: string
}

export const SeatingRestrictionsDisclaimer: FC<SeatingRestrictionsDisclaimerProps> = ({ className, placement }) => {
    const [isGeneralAdmissionUKEnabled] = useGeneralAdmissionUKFeature()

    const {
        selectors: { productionDetails },
    } = useContext(productionDetailsContext)

    if (
        productionDetails?.venue.countryCode !== UK ||
        !productionDetails?.seatRestrictions ||
        !isGeneralAdmissionUKEnabled ||
        (placement === SEATING_RESTRICTION_PLACEMENT.BANNER && productionDetails.isDoaActive) ||
        (placement === SEATING_RESTRICTION_PLACEMENT.LISTINGS_HEADER && !productionDetails.isDoaActive)
    )
        return <></>

    return (
        <Text
            as="span"
            className={className}
            size="sm"
            weight="regular"
            altFont
            data-testid="seating-restrictions-disclaimer"
        >
            {productionDetails.seatRestrictions}
        </Text>
    )
}

export default SeatingRestrictionsDisclaimer
