import { createSelector } from 'reselect'

import {
    LIMITED_INVENTORY_THRESHOLD,
    MAX_DRIVING_DISTANCE,
    MAX_NEARBY_DISTANCE,
    PERCENT_REMAINING_UPPER_THRESHOLD,
    VISIBLE_BY_DEFAULT_PRODUCTION_LISTINGS_LIMIT,
} from '@/constants'
import type { Production, ProductionListSelectorType } from '@/types/app-types'
import { formatProductionDates, formatVenueLocation } from '@/utils/production'

export const getLimitedInventoryBadgeLabel = (ticketCount = 0, venueCapacity = 0) => {
    if (!ticketCount) return undefined
    if (ticketCount < LIMITED_INVENTORY_THRESHOLD) {
        return ` ${ticketCount} ticket${ticketCount === 1 ? '' : 's'} left`
    }

    if (!venueCapacity) return undefined
    const percentTicketsRemaining = Math.max(1, Math.round((ticketCount / venueCapacity) * 100))
    if (percentTicketsRemaining < PERCENT_REMAINING_UPPER_THRESHOLD) {
        return ` ${percentTicketsRemaining}% of tickets left`
    }
}

export type ItemSelector<T> = (state: T) => Production[] | undefined

export const productionListSelectorCreator = <T, U extends ItemSelector<T> = ItemSelector<T>>(itemsSelector: U) =>
    createSelector([itemsSelector], (productions: Production[] = []) => {
        return productions.map((production, index) => {
            const productionListing: ProductionListSelectorType = {
                ...formatProductionDates(production),
                ...formatVenueLocation(production.venue),
                venueName: production.venue.name,
                venueCapacity: production.venue.capacity,
                name: production.name,
                id: production.id,
                category: production.categoryId,
                // Prioritize webPath over the deprecated organicUrl
                href: production.webPath || production.organicUrl || '',
                isExternal: true,
                performers: production.performers,
                defaultVisible: index < VISIBLE_BY_DEFAULT_PRODUCTION_LISTINGS_LIMIT,
                ticketCount: production.ticketCount,
                lat: production.venue.latitude,
                long: production.venue.longitude,
                minPrice: production.minPrice,
                maxPrice: production.maxPrice,
                // Venues still use organicUrl for now
                venueUrl: production.venue.organicUrl,
                // TODO: Remove 'venueWebPath' and 'venueOrganicUrl' and use 'venueUrl' instead once 'organicUrl' gets deprecated
                venueWebPath: production.venue.webPath,
                venueOrganicUrl: production.venue.organicUrl,
                isNearByDistance: production.distance !== undefined && production.distance <= MAX_NEARBY_DISTANCE,
                isDriveDistance:
                    production.distance !== undefined &&
                    MAX_NEARBY_DISTANCE <= production.distance &&
                    production.distance <= MAX_DRIVING_DISTANCE,
                // keep the original production object for analytics
                analytics: { production, index },
                contentTags: production.contentTags,
                limitedInventoryBadgeLabel: getLimitedInventoryBadgeLabel(
                    production.ticketCount,
                    production.venue.capacity,
                ),
            }
            return productionListing
        })
    })
