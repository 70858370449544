import { ReactSDKClient, useDecision } from '@optimizely/react-sdk'

import { OPTIMIZELY_FEATURE } from '@/optimizely/constants'
import type { OptimizelyHookResult } from '@/optimizely/types'

export const useHomePageDynamicContent = (
    optimizely: Pick<ReactSDKClient, 'isFeatureEnabled'> | null,
): OptimizelyHookResult => {
    const [decision] = useDecision(OPTIMIZELY_FEATURE.homePageDynamicContent, { autoUpdate: false })
    const { variables } = decision

    const isFeatureEnabled = !!optimizely?.isFeatureEnabled(OPTIMIZELY_FEATURE.homePageDynamicContent)

    return [isFeatureEnabled, isFeatureEnabled ? variables : {}]
}
