import type { FC, ReactElement, ReactNode } from 'react'
import React, { useContext, useEffect } from 'react'

import type { WithOptimizelyProps } from '@optimizely/react-sdk'
import { withOptimizely } from '@optimizely/react-sdk'
import { Box, SectionContainer, VividLogo } from '@vividseats/vivid-ui-kit'
import { LOGO_DARK_TEXT_COLOR } from '@vividseats/vivid-ui-kit/dist/components/vivid-logo/component'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'

import { getHomepageRedesignVariable } from '@/components/pages/home/utils'
import SearchBar from '@/components/shared/searchbar'
import { CENTER_SEARCH_SCROLL_THRESHOLD_DESKTOP, TRENDING_CATEGORY_WIDTH_THRESHOLD } from '@/constants'
import headerStyleContext from '@/context/header'
import { SuggestionsProvider } from '@/context/suggestions'
import { useHomeLinkWithUrlAppend } from '@/hooks/use-home-link-with-url-append'
import useWindowSize from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'
import { OPTIMIZELY_EVENT } from '@/optimizely/constants'
import { useNflHomepageSuperbowlBanner } from '@/optimizely/features/nfl-homepage-superbowl-banner/hooks/use-nfl-homepage-superbowl-banner'
import { useTrendingCategoryFeature } from '@/optimizely/features/trending-category-feature'
import { useHomePageDynamicContent } from '@/optimizely/home-page-dynamic-content/hooks/use-home-page-dynamic-content'
import { firstPageAtom } from '@/recoil/atoms/isFirstPageInSessionAtom'

import MarketplaceDisclaimer from '../marketplace-disclaimer'

import MainMenu, { MainMenuProvider } from './components/main-menu'
import MobileNavContainer from './components/main-menu/components/mobile/components/nav-container'
import styles from './styles.module.scss'

export interface HeaderProps extends WithOptimizelyProps {
    children?: ReactNode
    className?: string
    mobileOnlyPaidSearchDisclaimer?: boolean
    narrow?: boolean // If additional left and right padding should be added to header content
    withTransparentHero?: boolean
}

const Header: FC<HeaderProps> = ({
    className,
    children,
    mobileOnlyPaidSearchDisclaimer,
    narrow,
    optimizely,
    withTransparentHero,
}) => {
    const { pathname } = useRouter()
    const isHome = pathname === '/home'
    const isPerformer = pathname.includes('/performer')

    const {
        state: { transparentHeaderClassName, forceOpaque, scrollPercentage, isTransparent, isHeaderSearchbarFocused },
        dispatch: { setForceOpaque, handleSearchbarFocus },
    } = useContext(headerStyleContext)

    const homeLink = useHomeLinkWithUrlAppend()
    const [isTrendingCategoryEnabled] = useTrendingCategoryFeature()
    const { type, width } = useWindowSize()

    const { aboveTheFold: shouldAboveTheFold } = getHomepageRedesignVariable(optimizely, type)
    const [isHomePageDynamicContentEnabled, homePageDynamicContentVariables] = useHomePageDynamicContent(optimizely)
    const [isNflHomepageSuperbowlBannerEnable] = useNflHomepageSuperbowlBanner()

    const isMobileNflHomepageSuperbowlBanner = type === DeviceType.MOBILE && isNflHomepageSuperbowlBannerEnable
    const isMobileAboveTheFold = type === DeviceType.MOBILE && shouldAboveTheFold
    const shouldShowMobileSearchBar = isHome && (isMobileAboveTheFold || isMobileNflHomepageSuperbowlBanner)

    const [firstPage, setFirstPage] = useRecoilState(firstPageAtom)

    // to track the first page in the session
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!firstPage) {
                setFirstPage({ url: window.location.href })
            }
        }
    }, [firstPage, setFirstPage])

    const isCenterSearch = isHomePageDynamicContentEnabled && homePageDynamicContentVariables.centerSearch

    const mergedHeaderClassName = classNames(styles.header, className, {
        [styles.stickyPosition]: !isPerformer,
        [styles.headerTransition]: isTransparent,
        [styles[transparentHeaderClassName]]: isTransparent,
        [styles['transparentHeader100']]: forceOpaque,
        [styles.withTransparentHero]: withTransparentHero,
        [styles.trendingNavbar]: isTrendingCategoryEnabled && !isCenterSearch,
        [styles.withCenterSearch]: isCenterSearch,
    })

    const mergedSectionContainerClassName = classNames(styles.sectionContainer, {
        [styles.narrow]: narrow,
    })

    // Set logo's svg text color based on scroll percentage if using tranpsarent mode
    let logoColor

    if (!isMobileAboveTheFold && isTransparent) {
        logoColor = (scrollPercentage && scrollPercentage >= 40) || forceOpaque ? LOGO_DARK_TEXT_COLOR : 'white'
    }

    // Render an empty div if no children exist so logo is centered
    // Render an empty div if no children exist so logo is centered on homepage and non-homepage
    let headerChildContent: ReactElement | null = null

    if (children) {
        headerChildContent = children as ReactElement
    } else if (isHome) {
        headerChildContent = <div className={styles.headerActionEmpty} data-testid="homepage-empty-div" />
    }

    const handleClick = () => {
        optimizely?.track(OPTIMIZELY_EVENT.searchBarClick)
    }

    return (
        <MainMenuProvider>
            <MarketplaceDisclaimer mobileOnlyPaidSearch={mobileOnlyPaidSearchDisclaimer} />
            {!mobileOnlyPaidSearchDisclaimer && (
                <>
                    <header
                        className={mergedHeaderClassName}
                        id="nav-header"
                        data-testid="nav-header"
                        onMouseEnter={() => setForceOpaque(true)}
                        onMouseLeave={() => {
                            if (!isHeaderSearchbarFocused) setForceOpaque(false)
                        }}
                    >
                        <SectionContainer className={mergedSectionContainerClassName} fluid>
                            <MainMenu className={styles.mainMenu} isCenterSearch={isCenterSearch} />
                            <Link href={homeLink} className={styles.logoLink} aria-label="Link to homepage">
                                <Box mobileDisplay="block" desktopDisplay="none" tabletDisplay="none">
                                    <VividLogo color={logoColor} showTriangle={false} />
                                </Box>
                                <Box desktopDisplay="block" mobileDisplay="none" tabletDisplay="block">
                                    <VividLogo color={logoColor} />
                                </Box>
                            </Link>
                            <Box
                                desktopDisplay={
                                    isCenterSearch && isHome
                                        ? scrollPercentage && scrollPercentage >= CENTER_SEARCH_SCROLL_THRESHOLD_DESKTOP
                                            ? 'block'
                                            : 'none'
                                        : !shouldAboveTheFold &&
                                            isTrendingCategoryEnabled &&
                                            width <= TRENDING_CATEGORY_WIDTH_THRESHOLD
                                          ? 'none'
                                          : 'block'
                                }
                                className={classNames(styles.headerAction, styles.headerSearchbar)}
                                onClick={handleClick}
                                data-testid="header-action-box"
                            >
                                <div className={classNames(styles.searchbar, { [styles.homepageSearchbar]: isHome })}>
                                    <SuggestionsProvider>
                                        <SearchBar handleSearchbarFocus={handleSearchbarFocus} />
                                    </SuggestionsProvider>
                                </div>
                            </Box>
                            {headerChildContent}
                        </SectionContainer>

                        {shouldShowMobileSearchBar && (
                            <SectionContainer>
                                <SuggestionsProvider>
                                    <SearchBar />
                                </SuggestionsProvider>
                            </SectionContainer>
                        )}
                    </header>
                    <MobileNavContainer />
                </>
            )}
        </MainMenuProvider>
    )
}

export default withOptimizely(Header)
