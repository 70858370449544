import { I18n } from '@shopify/react-i18n'

import { Performer, Production, ProductionDetail } from '@/types/app-types'
import { avgPrice, maxPrice, medianPrice, minPrice } from '@/utils/currency'
import { monthDateFromLocalString } from '@/utils/dates'
import { getFormattedCurrency } from '@/utils/i18n'

import { DEFAULT_CURRENCY } from '../internationalization/constants'

import type { LookupData } from './types'

/**
 * Get the first defined performer.
 * Performer page provides 'performer' and production page provides 'productionDetails'.
 */
const getPerformer = ({ performer, productionDetails }: LookupData): Performer | undefined => {
    return performer ?? productionDetails?.performers?.[0]
}

/**
 * Get the first defined production.
 * Performer page provides 'productions' and production page provides 'productionDetails'.
 */
const getProduction = ({ productions, productionDetails }: LookupData): Production | ProductionDetail | undefined => {
    return productions?.[0] ?? productionDetails ?? undefined
}

const getLocalDate = ({ productions, productionDetails }: LookupData): string | undefined => {
    return getProduction({ productions, productionDetails })?.localDate
}

const getPrices = ({ productions, productionDetails }: LookupData): number[] => {
    const prices: number[] = []
    const finalProductions: Array<{
        minPrice: number
        maxPrice: number
        localPrices?: { minPrice: number; maxPrice: number }
    }> = productions ?? (productionDetails ? [productionDetails] : [])
    finalProductions.forEach(({ minPrice, maxPrice, localPrices }) => {
        if (minPrice > 0) {
            const min = localPrices?.minPrice ? Math.ceil(localPrices?.minPrice) : minPrice
            prices.push(min)
        }
        if (maxPrice > 0) {
            const max = localPrices?.maxPrice ? Math.ceil(localPrices?.maxPrice) : maxPrice
            prices.push(max)
        }
    })
    return prices
}

/** Object that maps each supported CMS token to its replacement function */
const CmsTokenMappers = {
    // Matchup page
    '<longName>': ({ matchupPage }: LookupData) => matchupPage?.longName,

    '<shortName>': ({ matchupPage }: LookupData) => matchupPage?.shortName,

    // Performers

    '%performerName%': ({ performer, productionDetails }: LookupData) =>
        getPerformer({ performer, productionDetails })?.name,

    '%productionCount%': ({ performer, productionDetails }: LookupData) =>
        performer?.productionCount ?? productionDetails?.productionsCount,

    '%categoryName%': ({ category, performer, productionDetails }: LookupData) =>
        category?.name ?? getPerformer({ performer, productionDetails })?.category.name,

    '%categoryUrl%': ({ category, performer, productionDetails }: LookupData) =>
        category?.webPath ?? getPerformer({ performer, productionDetails })?.category.organicUrl,

    '%subCategoryName%': ({ subCategory, performer, productionDetails }: LookupData) =>
        subCategory?.name ?? getPerformer({ performer, productionDetails })?.category.subCategories?.[0]?.name,

    '%subCategoryUrl%': ({ subCategory, performer, productionDetails }: LookupData) =>
        subCategory?.webPath ?? getPerformer({ performer, productionDetails })?.category.subCategories?.[0]?.organicUrl,

    // Productions
    '%productionName%': ({ productions, productionDetails }: LookupData) =>
        getProduction({ productions, productionDetails })?.name,

    '%productionDate%': ({ productions, productionDetails }: LookupData) => {
        const localDate: string | undefined = getLocalDate({ productions, productionDetails })
        return localDate ? monthDateFromLocalString(localDate.split('T')[0]) : undefined
    },

    '%productionYear%': ({ productions, productionDetails }: LookupData) => {
        const localDate: string | undefined = getLocalDate({ productions, productionDetails })
        return localDate?.split('-')[0]
    },

    '%ticketCount%': ({ productions, productionDetails }: LookupData) =>
        getProduction({ productions, productionDetails })?.ticketCount,

    '%listingCount%': ({ productions, productionDetails }: LookupData) =>
        getProduction({ productions, productionDetails })?.listingCount,

    '%venueName%': ({ productions, productionDetails, venue }: LookupData) =>
        getProduction({ productions, productionDetails })?.venue.name || venue?.name,

    '%venueCity%': ({ productions, productionDetails, venue }: LookupData) =>
        getProduction({ productions, productionDetails })?.venue.city || venue?.city,

    '%venueState%': ({ productions, productionDetails, venue }: LookupData) =>
        getProduction({ productions, productionDetails })?.venue.state || venue?.state,

    '%venueCityState%': ({ productions, productionDetails, venue }: LookupData) => {
        const state = getProduction({ productions, productionDetails })?.venue.state || venue?.state
        const city = getProduction({ productions, productionDetails })?.venue.city || venue?.city
        return city && state ? `${city}, ${state}` : undefined
    },

    '%venueUrl%': ({ productions, productionDetails, venue }: LookupData) => {
        const production = getProduction({ productions, productionDetails })
        return production?.venue.webPath || production?.venue.organicUrl || venue?.webPath
    },

    '%venueAddressLine1%': ({ productions, productionDetails, venue }: LookupData) =>
        getProduction({ productions, productionDetails })?.venue.address1 || venue?.address1,

    '%venueAddressLine2%': ({ productions, productionDetails, venue }: LookupData) =>
        getProduction({ productions, productionDetails })?.venue.address2 || venue?.address2,

    '%venueZipCode%': ({ productions, productionDetails, venue }: LookupData) =>
        getProduction({ productions, productionDetails })?.venue.postalCode || venue?.postalCode,

    '%opponentName%': ({ productions, productionDetails }: LookupData) =>
        getProduction({ productions, productionDetails })?.performers?.[1]?.name ?? 'N/A',

    // Region

    '%regionName%': ({ region }: LookupData) => region?.name,
    '%regionTitle%': ({ region }: LookupData) => region?.title,
    '%regionListTitle%': ({ region }: LookupData) => region?.listTitle,
    '%regionSubtitle%': ({ region }: LookupData) => region?.subtitle,
    '%regionConcertsTitle%': ({ region }: LookupData) => region?.concertsOverrides?.title,
    '%regionConcertsSubtitle%': ({ region }: LookupData) => region?.concertsOverrides?.subtitle,
    '%regionSportsTitle%': ({ region }: LookupData) => region?.sportsOverrides?.title,
    '%regionSportsSubtitle%': ({ region }: LookupData) => region?.sportsOverrides?.subtitle,
    '%regionTheaterTitle%': ({ region }: LookupData) => region?.theaterOverrides?.title,
    '%regionTheaterSubtitle%': ({ region }: LookupData) => region?.theaterOverrides?.subtitle,
    '%countryAbbr%': ({ region }: LookupData) => region?.countryAbbr,
    '%stateAbbr%': ({ region }: LookupData) => region?.stateAbbr,

    // Prices
    '%minPrice%': (prices: number[]) => minPrice(prices),
    '%maxPrice%': (prices: number[]) => maxPrice(prices),
    '%avgPrice%': (prices: number[]) => avgPrice(prices),
    '%medianPrice%': (prices: number[]) => medianPrice(prices),

    // Dates

    '%thisYear%': () => new Date().getFullYear(),
    '%nextYear%': () => new Date().getFullYear() + 1,
} as const

type CmsToken = keyof typeof CmsTokenMappers

/** Regular expression with all CMS tokens, e.g. `/(%minPrice%)|(%maxPrice%)|...|(%thisYear%)|(%nextYear%)/g` */
const cmsTokensRegExp = new RegExp(
    Object.keys(CmsTokenMappers)
        .map((cmsToken) => `(${cmsToken})`)
        .join('|'),
    'g',
)

export const formatPriceToken = (
    token: number,
    shouldFormatPrice: boolean,
    currency?: string,
    i18n?: I18n | undefined,
): string => {
    const isNotDefaultCurrency = !!currency && currency !== DEFAULT_CURRENCY
    const NOT_AVAILABLE = `${shouldFormatPrice ? '$' : ''}N/A`
    const isExplicit = isNotDefaultCurrency
    const precision = 0
    const updatedToken = Number(token) > 0 ? token.toString() : NOT_AVAILABLE

    const formattedToken =
        shouldFormatPrice && i18n && updatedToken !== NOT_AVAILABLE
            ? getFormattedCurrency(i18n, token, currency, isExplicit, precision)
            : updatedToken

    return formattedToken
}

export const replaceCmsTokens = (
    text: string | undefined,
    lookupData: LookupData,
    shouldFormatPrice = true,
    currency?: string,
    i18n?: I18n,
): string => {
    if (!text) {
        return ''
    }

    // Get only those CMS tokens that are present in the text
    // Though this may contain duplicates in case of multiple occurrences
    const cmsTokensMatches: string[] | null = text.match(cmsTokensRegExp)

    // If text does not contain any of the CMS tokens then return text as-is
    if (!cmsTokensMatches) {
        return text
    }

    let _prices: number[] | undefined
    const _getPrices = (): number[] => {
        // Call 'getPrices' function at most once and only if needed, e.g. for %minPrice%, %maxPrice%, etc.
        _prices = _prices ?? getPrices(lookupData)
        return _prices
    }

    // Object to store all processed CMS tokens to avoid multiple processing of the same CMS token
    // Do not use [...new Set(cmsTokensMatches)] because Set API has a limited support in Next.js
    const processedCmsTokens: Partial<Record<CmsToken, boolean>> = {}
    for (const cmsTokenStr of cmsTokensMatches) {
        const cmsToken: CmsToken = cmsTokenStr as CmsToken // For type checking

        // Skip CMS tokens that have already been processed, e.g. if there are multiple occurrences of the same CMS token in the text
        if (processedCmsTokens[cmsToken]) {
            continue
        }
        processedCmsTokens[cmsToken] = true

        let cmsTokenReplaceValue: string | number | null | undefined
        switch (cmsToken) {
            case '%minPrice%':
            case '%maxPrice%':
            case '%avgPrice%':
            case '%medianPrice%': {
                cmsTokenReplaceValue = formatPriceToken(
                    CmsTokenMappers[cmsToken](_getPrices()),
                    shouldFormatPrice,
                    currency || '',
                    i18n,
                )
                break
            }
            default: {
                cmsTokenReplaceValue = CmsTokenMappers[cmsToken](lookupData)
                break
            }
        }

        // Replace CMS token only if its replacement value is defined (including '', 0, false, etc.)
        if (cmsTokenReplaceValue !== null && cmsTokenReplaceValue !== undefined) {
            text = text.replace(new RegExp(cmsToken, 'g'), `${cmsTokenReplaceValue}`)
        }
    }

    return text
}
