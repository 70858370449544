import React, { forwardRef, ReactNode } from 'react'

import { SectionContainer } from '@vividseats/vivid-ui-kit'
import classnames from 'classnames'

import styles from './styles.module.scss'

interface SearchSuggestionsContainerProps {
    children?: ReactNode
    isCenteredInHero?: boolean
}

const SearchSuggestionsContainer: React.ForwardRefRenderFunction<HTMLDivElement, SearchSuggestionsContainerProps> = (
    { children, isCenteredInHero },
    ref,
) => {
    const containerClasses = classnames(styles.container, {
        [styles.isCenteredInHero]: isCenteredInHero,
    })
    return (
        <div data-testid="search-suggestions-container" ref={ref} className={containerClasses}>
            <SectionContainer className={styles.sectionContainer}>{children}</SectionContainer>
        </div>
    )
}

export default forwardRef<HTMLDivElement, SearchSuggestionsContainerProps>(SearchSuggestionsContainer)
