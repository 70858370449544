import React, { useContext, useState } from 'react'

import classNames from 'classnames'

import MenuItemLink from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-item-link'
import type { MenuItemLevel1 as MenuItemLevel1Type } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import { isMenuItemLevel1WithSublinks } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import {
    getMenuAriaLabel,
    isCurrencyMenuItem,
} from '@/components/layouts/main-layout/components/header/components/main-menu/utils'
import headerStyleContext from '@/context/header'
import userDataContext from '@/context/user-data'
import { useLocalizedCurrencyFeature } from '@/optimizely/features/internationalization/hooks/localized-currency'

import HeaderCurrencyPicker from '../header-currency-picker'
import MenuLevel2 from '../menu-level-2'

import styles from './styles.module.scss'

interface Props {
    className?: string
    menuItem: MenuItemLevel1Type
    side: 'top' | 'right' | 'bottom' | 'left'
}

const MenuItemLevel1: React.FC<Props> = ({ className, menuItem, side }) => {
    const {
        state: { isAuthenticated },
    } = useContext(userDataContext)
    const {
        state: { isTransparent, transparentHeaderClassName, forceOpaque },
    } = useContext(headerStyleContext)

    const [isLocalizedCurrencyEnabled] = useLocalizedCurrencyFeature()

    const isLevel1 = isMenuItemLevel1WithSublinks(menuItem)

    const [isMenuActive, setIsMenuActive] = useState(false)

    if (menuItem.shouldRenderOnlyWhenAuthenticated && !isAuthenticated) {
        return null
    }
    if (menuItem.shouldRenderOnlyWhenNotAuthenticated && isAuthenticated) {
        return null
    }

    const mergedMenuItemLinkClassName = classNames(styles.menuItemLink, {
        [styles.headerTransition]: isTransparent,
        [styles[transparentHeaderClassName]]: isTransparent,
        [styles['transparentHeader100']]: forceOpaque,
    })

    const mergedMenuItemLevel1ClassName = classNames(styles.menuItemLevel1, className)
    const mergedSubmenuContentClassName = classNames(styles.submenuContent, styles[side], {
        [styles[transparentHeaderClassName]]: isTransparent,
    })

    if (isCurrencyMenuItem(menuItem.label)) {
        if (isLocalizedCurrencyEnabled) {
            return <HeaderCurrencyPicker className={className} isDesktop />
        }
    } else {
        return (
            <li
                className={mergedMenuItemLevel1ClassName}
                onMouseEnter={() => setIsMenuActive(true)}
                onMouseLeave={() => setIsMenuActive(false)}
                data-testid="desktop-nav-menu-item-level-1"
            >
                <MenuItemLink
                    className={mergedMenuItemLinkClassName}
                    href={menuItem.url}
                    icon={menuItem.icon}
                    level={1}
                    strong={menuItem.strong}
                    withBorder={menuItem.withBorder}
                    isDesktop={true}
                    label={getMenuAriaLabel(menuItem.label)}
                >
                    {menuItem.label}
                </MenuItemLink>
                {isLevel1 && isMenuActive && (
                    <div className={styles.submenu}>
                        <div className={mergedSubmenuContentClassName} data-testid="desktop-nav-sublinks">
                            <MenuLevel2 menuItem={menuItem} />
                        </div>
                    </div>
                )}
            </li>
        )
    }

    return null
}

export default MenuItemLevel1
