import React, { useState } from 'react'

import classNames from 'classnames'

import type {
    MenuItemLevel1WithSublinks,
    MenuItemLevel2 as MenuItemLevel2Type,
} from '@/components/layouts/main-layout/components/header/components/main-menu/types'

import MenuItemLevel2 from '../menu-item-level-2'

import styles from './styles.module.scss'

interface Props {
    menuItem: MenuItemLevel1WithSublinks
}

const MenuLevel2: React.FC<Props> = ({ menuItem }) => {
    const narrowSubmenu = menuItem.narrowSubmenu
    const menuItemsWidth = narrowSubmenu ? 'narrow' : 'wide'
    const [hoverFirstRow, sethoverFirstRow] = useState(menuItem.sublinks[0].id?.includes('trending'))
    const mergedMenuItemsClassName = classNames(styles.menuItems, {
        [styles.narrowSubmenu]: narrowSubmenu,
        [styles.trendingCategoryMenu]: menuItem?.id === 'trendingCategory',
    })

    /**
     *
     * If a child item just got hovered on, highlight it and unhighlight the trending item.
     * When the mouse leaves the menu items, highlight trending item again
     * @param item MenuItem
     * @param isHovered onMouseEnter = true. onMouseLeave = false
     */
    const onMenuItemHover = (item: MenuItemLevel2Type, isHovered: boolean) => {
        if (isHovered) {
            sethoverFirstRow(!!item.id?.includes('trending'))
        } else {
            sethoverFirstRow(true)
        }
    }

    return (
        <ul className={mergedMenuItemsClassName}>
            {menuItem.sublinks.map((sublinkItem, i) => {
                const divider = sublinkItem.divider || false
                return (
                    <MenuItemLevel2
                        parentLabel={menuItem.label}
                        divider={divider}
                        key={i}
                        menuItem={sublinkItem}
                        width={menuItemsWidth}
                        hoverFirstRow={hoverFirstRow}
                        onHover={onMenuItemHover}
                    />
                )
            })}
        </ul>
    )
}

export default MenuLevel2
