import React, { useContext } from 'react'

import { backdropContext } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import type { MenuItemLevel1 as MenuItemLevel1Type } from '@/components/layouts/main-layout/components/header/components/main-menu/types'

import MenuItemLevel1 from '../menu-item-level-1'

import styles from './styles.module.scss'

interface Props {
    menuItems: MenuItemLevel1Type[]
}

const MenuLevel1: React.FC<Props> = ({ menuItems }) => {
    const {
        dispatch: { setToggled },
    } = useContext(backdropContext)

    return (
        <ul
            className={styles.menuItems}
            onMouseOver={() => {
                setToggled({ toggled: true, animated: false, lockScroll: false })
            }}
            onFocus={() => {
                setToggled({ toggled: true, animated: false, lockScroll: false })
            }}
            onMouseOut={() => {
                setToggled({ toggled: false })
            }}
            onBlur={() => {
                setToggled({ toggled: false })
            }}
        >
            {menuItems.map((menuItem, i) => {
                const side = menuItem.side || 'right'

                const mergedMenuItemClassName = classNames(styles.menuItem, styles[side])

                return <MenuItemLevel1 className={mergedMenuItemClassName} key={i} menuItem={menuItem} side={side} />
            })}
        </ul>
    )
}
export default MenuLevel1
