import React, { useContext, useMemo } from 'react'

import { ButtonV2, Icon } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import MenuFooterItem from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-footer-item'
import MenuItemLink from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-item-link'
import type { MenuItem as MenuItemType } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import {
    isMenuItemLevel1,
    isMenuItemLevel1Or2,
    isMenuItemLevel2,
} from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import { getMenuAriaLabel } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'
import userDataContext from '@/context/user-data'

import Menu from '../menu'

import styles from './styles.module.scss'

interface Props {
    activeChild: number | null
    activeChildSubmenuPath: number[]
    index: number
    menuItem: MenuItemType
    onBackButtonClick: () => void
    onCloseButtonClick: () => void
    onItemClick: (index: number) => void
    menuTitle: string
    parentLabel: string
    isTrendingCategoryEnabled: boolean
}

const MenuItem: React.FC<Props> = ({
    activeChild,
    activeChildSubmenuPath,
    index,
    menuItem,
    onBackButtonClick,
    onCloseButtonClick,
    onItemClick,
    menuTitle,
    parentLabel,
    isTrendingCategoryEnabled,
}) => {
    const {
        state: { isAuthenticated },
    } = useContext(userDataContext)

    const shouldShowDivider = useMemo(() => {
        if (!menuItem.divider) {
            return false
        }

        // ultimately remove this once decision been made that we want to keep the trending menu
        if (menuItem.id === 'sports' && !!isTrendingCategoryEnabled) {
            return false
        }

        if (index !== 0 || isAuthenticated || !isMenuItemLevel1(menuItem)) {
            return true
        }
    }, [menuItem, index, isTrendingCategoryEnabled, isAuthenticated])

    const childMenuHeaderElement = (
        <ButtonV2 className={styles.backButton} data-testid="back-button" importance="text" onClick={onBackButtonClick}>
            <Icon className={styles.backIcon} type="back" size="md" />
            <div className={styles.menuHeaderText}>{menuTitle}</div>
        </ButtonV2>
    )

    const childMenuFooterElement =
        (isMenuItemLevel1(menuItem) && menuItem.label !== 'Trending' && (
            <MenuFooterItem
                footerLink={{
                    url: menuItem.url,
                    label: `View All ${menuItem.label}`,
                }}
            />
        )) ||
        (isMenuItemLevel2(menuItem) && menuItem.footerLink && <MenuFooterItem footerLink={menuItem.footerLink} />)

    if (menuItem.shouldRenderOnlyWhenAuthenticated && !isAuthenticated) {
        return null
    }
    if (menuItem.shouldRenderOnlyWhenNotAuthenticated && isAuthenticated) {
        return null
    }

    const withSublinks = isMenuItemLevel1Or2(menuItem) && menuItem.sublinks && menuItem.sublinks.length > 0

    const mergedMenuItemsClassName = classNames(styles.menuItem, {
        [styles[(isMenuItemLevel1(menuItem) && menuItem.side) || '']]: !!isMenuItemLevel1(menuItem) && menuItem.side,
    })

    return (
        <li
            className={mergedMenuItemsClassName}
            data-testid={`mobile-nav-menu-item-level-${menuItem.level}${withSublinks ? '-with-sublinks' : ''}`}
        >
            {shouldShowDivider && <div className={styles.divider} />}
            <MenuItemLink
                block={withSublinks}
                href={menuItem.url}
                level={2}
                strong={menuItem.strong}
                withSublinks={withSublinks}
                withBorder={menuItem.withBorder}
                isDesktop={false}
                label={getMenuAriaLabel(menuItem.label, parentLabel)}
                onClick={(e) => {
                    if (isMenuItemLevel1Or2(menuItem) && menuItem.sublinks && menuItem.sublinks.length > 0) {
                        e.preventDefault()
                        onItemClick(index)
                    }
                }}
            >
                {menuItem.label}
            </MenuItemLink>
            {index === activeChild && isMenuItemLevel1Or2(menuItem) && menuItem.sublinks && (
                <div className={styles.sublinks} data-testid="mobile-nav-sublinks">
                    <Menu
                        active={index === activeChild}
                        activeSubmenuPath={index === activeChild ? activeChildSubmenuPath : []}
                        backTitle={menuItem.label}
                        footerElement={childMenuFooterElement}
                        headerElement={childMenuHeaderElement}
                        menuItems={menuItem.sublinks}
                        parentLabel={`${menuItem.parentLabel}${menuItem.label}`}
                        isTrendingCategoryEnabled={!!isTrendingCategoryEnabled}
                        onBackButtonClick={onBackButtonClick}
                        onCloseButtonClick={onCloseButtonClick}
                        onItemClick={onItemClick}
                        title={menuItem.label}
                    />
                </div>
            )}
        </li>
    )
}

export default MenuItem
