import { createSelector } from 'reselect'

import { IMAGES, MAX_RESULTS_BY_CATEGORY_FOR_SEARCH, MAX_RESULTS_BY_VENUE_FOR_SEARCH } from '@/constants'
import { getImages } from '@/context/catalog-asset/selectors'
import type { Performer, PerformerImage, Production, ProductionListSelectorType, Venue } from '@/types/app-types'
import { formatProductionDates, formatVenueLocation, getPerformerUrl } from '@/utils/production'

import type { AlgoliaQueryIdSelector, SuggestionDataType, SuggestionStateType } from './types'

export const suggestionGlobalSelector = (state: SuggestionStateType): SuggestionStateType => state

export const getPerformersWithAssets = createSelector(
    suggestionGlobalSelector,
    (state: SuggestionStateType): Array<PerformerImage> => {
        if (!state.data) {
            return []
        }
        const { performers } = state.data as SuggestionDataType

        // Only display performers with the correct kind of url
        const performersWithAppropriateUrls = []
        for (let i = 0; i < performers.length; i++) {
            const performer = performers[i]
            const url = getPerformerUrl(performer)

            if (url) {
                const images = getImages(performer.images || [], state.fetching, false)
                if (!images.thumbnail) images.thumbnail = images.mobile || images.desktop || IMAGES.DEFAULT_HERO_MOBILE

                performersWithAppropriateUrls.push({
                    ...images,
                    id: performer.id,
                    url,
                    productionCount: performer.productionCount || 0,
                    name: performer.name,
                    category: performer.category.name,
                    mainPerformerSubCategoryName:
                        (performer.category.subCategories && performer.category.subCategories[0].name) || '',
                })
            }
            if (performersWithAppropriateUrls.length === MAX_RESULTS_BY_CATEGORY_FOR_SEARCH) break
        }

        return performersWithAppropriateUrls
    },
)

export const getPerformerById = createSelector(
    [suggestionGlobalSelector, (state: SuggestionStateType, id: number) => id],
    (state: SuggestionStateType, id: number): Performer | undefined => {
        if (!state.data) {
            return undefined
        }
        const { performers } = state.data as SuggestionDataType
        return performers.find((performer) => performer.id === id)
    },
)

export const getVenues = createSelector(suggestionGlobalSelector, (state: SuggestionStateType): Array<Venue> => {
    if (state.data) {
        const { venues } = state.data as SuggestionDataType
        return venues.filter((venue) => !!venue.webPath || !!venue.organicUrl).slice(0, MAX_RESULTS_BY_VENUE_FOR_SEARCH)
    }
    return []
})

export const getProductions = createSelector(
    suggestionGlobalSelector,
    (state: SuggestionStateType): Array<Production> => {
        if (state.data) {
            const { productions } = state.data as SuggestionDataType
            return productions.slice(0, MAX_RESULTS_BY_CATEGORY_FOR_SEARCH)
        }
        return []
    },
)

export const getProductionListRows = createSelector(
    getProductions,
    (rows: Array<Production>): Array<ProductionListSelectorType> => {
        return rows.map((row, index) => {
            let url = ''

            // Prioritize webPath over the deprecated organicUrl
            if (row.webPath) {
                url = row.webPath
            } else if (row.organicUrl) {
                url = row.organicUrl
            }

            return {
                ...formatProductionDates(row),
                ...formatVenueLocation(row.venue),
                venueName: row.venue.name,
                name: row.name,
                id: row.id,
                href: url,
                performers: row.performers,
                analytics: { production: row, index },
            }
        })
    },
)

export const hasResults = createSelector(suggestionGlobalSelector, (state: SuggestionStateType): boolean => {
    if (state.data) {
        const { performers, venues, productions } = state.data

        return performers.length > 0 || venues.length > 0 || productions.length > 0
    }
    return false
})

export const getAlgoliaQueryId = createSelector(
    suggestionGlobalSelector,
    (state: SuggestionStateType): AlgoliaQueryIdSelector => {
        if (!state.data) {
            return {
                performer: '',
                venue: '',
                production: '',
            }
        }

        return {
            performer: state.data.algoliaIndexInfo?.performers.queryId || '',
            venue: state.data.algoliaIndexInfo?.venues.queryId || '',
            production: state.data.algoliaIndexInfo?.productions.queryId || '',
        }
    },
)
