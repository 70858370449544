import React, { forwardRef, HTMLAttributes } from 'react'

import { Row, Text, Icon, Box } from '@vividseats/vivid-ui-kit'
import classnames from 'classnames'
import Link from 'next/link'

import ShopButton from '@/components/shared/searchbar/components/shop-button'

import styles from './styles.module.scss'

interface VenueListingRowType extends HTMLAttributes<HTMLAnchorElement> {
    name?: string
    productionCount?: number
    href?: string
    isSelected?: boolean
}

const VenueListing: React.ForwardRefRenderFunction<React.MutableRefObject<unknown>, VenueListingRowType> = (
    { name, productionCount, href, isSelected, children, ...props },
    ref: any,
) => {
    const venueRowClassnames = classnames(styles.venueRow, {
        [styles.selected]: isSelected,
    })
    return (
        <Link href={href || ''} {...props} ref={ref} className={styles.link} tabIndex={-1}>
            <Row align="center" justify="between" className={venueRowClassnames} data-testid="venue-row">
                <Row align="center" className={styles.venueItem}>
                    <Box className={styles.icon}>
                        <Icon className={styles.venueImage} size="md" type="building" />
                    </Box>
                    <Box className={styles.titleWrapper}>
                        <Text altFont size="lg" weight="regular-bold" className={styles.title}>
                            {name}
                        </Text>
                        <Text altFont size="sm" className={styles.category}>
                            {`${productionCount} Events`}
                        </Text>
                    </Box>
                </Row>
                <Row>
                    <ShopButton
                        className={{ buttonText: styles.shopButtonText, buttonArrow: styles.shopButtonArrow }}
                    />
                </Row>
                {children}
            </Row>
        </Link>
    )
}

export default forwardRef(VenueListing)
