import { useContext, useMemo } from 'react'

import ProductionListContext from '@/context/productions-list'
import { useSellingFastDealsAvailableSportsHomePerformerFeature } from '@/optimizely/features/selling-fast-deals-available-sports-home-performer-feature/hooks'
import { useSellingFastDealsAvailableTheatrePerformerFeature } from '@/optimizely/features/selling-fast-deals-available-theatre-performer-feature/hooks'
import { daysUntil } from '@/utils/dates'

export type UseBadgedProductionsType = {
    dealsAvailableIds: number[]
}

const FULL_COUNT = 10

const useBadgedProductions = (): UseBadgedProductionsType => {
    const {
        selectors: { sortedVolumeProductions, params },
    } = useContext(ProductionListContext)
    const [isSellingFastDealsAvailableTheatrePerformerFeatureEnabled] =
        useSellingFastDealsAvailableTheatrePerformerFeature()
    const [isSellingFastDealsAvailableSportsHomePerformerFeatureEnabled] =
        useSellingFastDealsAvailableSportsHomePerformerFeature()
    const ticketCount = sortedVolumeProductions.length
    const shouldFilterTo30Days = isSellingFastDealsAvailableTheatrePerformerFeatureEnabled && ticketCount >= FULL_COUNT

    const sortedByTicketQuantityIds = useMemo(() => {
        let filteredSortedVolumeProductions = sortedVolumeProductions
        if (shouldFilterTo30Days) {
            filteredSortedVolumeProductions = sortedVolumeProductions.filter((p) => daysUntil(p.utcDate) <= 30)
        }
        if (isSellingFastDealsAvailableSportsHomePerformerFeatureEnabled) {
            filteredSortedVolumeProductions = sortedVolumeProductions.filter((p) => {
                const masterPerformer = p.performers?.find(({ master }) => master)
                return masterPerformer?.id === params?.performerId
            })
        }
        return filteredSortedVolumeProductions.map((p) => p.id)
    }, [
        sortedVolumeProductions,
        shouldFilterTo30Days,
        isSellingFastDealsAvailableSportsHomePerformerFeatureEnabled,
        params?.performerId,
    ])

    const result: UseBadgedProductionsType = useMemo(() => {
        const dealsAvailableIds = ticketCount >= FULL_COUNT ? sortedByTicketQuantityIds.slice(-3) : []
        return { dealsAvailableIds }
    }, [sortedByTicketQuantityIds, ticketCount])

    return result
}

export default useBadgedProductions
