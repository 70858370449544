import React, { $FC, useContext } from 'react'

import { Grid, Link, Text } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import NextLink from 'next/link'

import featuredProductionsContext from '@/components/pages/performer/context'
import LDJSON from '@/components/shared/jsonld'
import { URLS } from '@/constants'
import { useNbaPlayoffsDesktopHeroFeature } from '@/optimizely/features/nba-playoffs-hero-feature/hooks/use-nba-playoffs-desktop-hero-feature'
import { useNbaPlayoffsMobileHeroFeature } from '@/optimizely/features/nba-playoffs-hero-feature/hooks/use-nba-playoffs-mobile-hero-feature'
import type { JsonLdItem } from '@/types/app-types'

import styles from './styles.module.scss'

// TODO: Remove in https://vividseats.atlassian.net/browse/BW-889 and use type from src/types/app-types.ts instead
export interface Breadcrumb {
    url: string
    title: string
    isExternal?: boolean
}

interface JsonLdBreadcrumbListItem {
    '@id': string
    name: string
}

interface JsonLdBreadcrumbItem {
    '@type': string
    position: number
    item: JsonLdBreadcrumbListItem
}

interface BreadcrumbProps {
    links: Breadcrumb[]
    pageURL?: string
    pageTitle?: string
}

/**
 * Helper function to generate a LDJson breadcrumb item
 * @param position position of breadcrumb item
 * @param path link path to append to vivid url, i.e. /pop/ => vividseats.com/pop/
 * @param name name of link page, i.e. Pop
 * @returns JsonLdBreadcrumbItem
 */
const generateItemListElement = (position: number, path: string, name: string): JsonLdBreadcrumbItem => {
    return {
        '@type': 'ListItem',
        position,
        item: { '@id': `${URLS.VIVIDSEATS}${path}`, name },
    }
}

const Breadcrumbs: $FC<BreadcrumbProps> = ({ links, pageURL, pageTitle }) => {
    const { noProductionsMessage } = useContext(featuredProductionsContext)
    const [isNbaPlayoffsMobileHeroFeatureEnabled] = useNbaPlayoffsMobileHeroFeature()
    const [isNbaPlayoffsDesktopHeroFeatureEnabled] = useNbaPlayoffsDesktopHeroFeature()
    const isNbaPlayoffsFeatureEnabled =
        (isNbaPlayoffsMobileHeroFeatureEnabled || isNbaPlayoffsDesktopHeroFeatureEnabled) && !noProductionsMessage

    // Generate LDJson breadcrumb object
    // This is later not rendered if pageURL and pageTitle are not provided
    const ldBreadcrumbObject: JsonLdItem = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            // Breadcrumb trail of category and genre
            ...links.map(({ url, title }, i) => generateItemListElement(i + 1, url, title)),
            // ...appended with self-referencing page title/url if they exist
            pageURL && pageTitle && generateItemListElement(links.length + 1, pageURL, pageTitle),
        ],
    }

    return (
        <>
            {!!pageTitle && !!pageURL && <LDJSON items={[ldBreadcrumbObject]} />}
            <Grid data-testid="breadcrumbs">
                {links.map((link, i) => (
                    <React.Fragment key={`${link.title}-${link.url}${i}`}>
                        <Link
                            className={classNames({
                                [styles.nbaPlayoffsMobileHeroFeatureBreadcrumbLink]: isNbaPlayoffsFeatureEnabled,
                            })}
                            LinkComponent={NextLink}
                            isExternal={link.isExternal}
                            href={link.url}
                            legacyBehavior
                            muted
                        >
                            <Text
                                as="span"
                                altFont
                                weight="regular-bold"
                                size={isNbaPlayoffsDesktopHeroFeatureEnabled && !noProductionsMessage ? 'body' : 'sm'}
                                className={classNames(styles.breadcrumbLinkText, {
                                    [styles.nbaPlayoffsMobileHeroFeatureBreadcrumb]: isNbaPlayoffsFeatureEnabled,
                                })}
                            >
                                {link.title}
                            </Text>
                        </Link>
                        {i !== links.length - 1 && (
                            <Text
                                as="span"
                                altFont
                                opaque={!isNbaPlayoffsFeatureEnabled}
                                weight="regular-bold"
                                size={isNbaPlayoffsDesktopHeroFeatureEnabled && !noProductionsMessage ? 'body' : 'sm'}
                                className={classNames(styles.breadcrumbLinkText, {
                                    [styles.nbaPlayoffsMobileHeroFeatureBreadcrumb]: isNbaPlayoffsFeatureEnabled,
                                })}
                            >
                                &nbsp;{'/'}&nbsp;
                            </Text>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </>
    )
}

export default Breadcrumbs
