import type { IconType } from '@vividseats/vivid-ui-kit'

export type MenuItem = MenuItemLevel1 | MenuItemLevel2 | MenuItemLevel3

interface MenuItemBase {
    level: number
    url: string
    label: string
    id?: string
    icon?: string
    divider?: boolean
    parentLabel?: string
    strong?: boolean
    withBorder?: boolean
    shouldRenderOnlyOnMobile?: boolean
    shouldRenderOnlyOnDesktop?: boolean
    shouldRenderOnlyWhenAuthenticated?: boolean
    shouldRenderOnlyWhenNotAuthenticated?: boolean
}

export interface MenuItemLevel1 extends MenuItemBase {
    level: 1
    sublinks?: MenuItemLevel2[]
    icon?: IconType
    side?: 'top' | 'right' | 'left' | 'bottom'
    narrowSubmenu?: boolean
    shouldRenderChildrenInUpperLevel?: boolean
}

export interface MenuItemLevel1WithSublinks extends MenuItemLevel1 {
    sublinks: MenuItemLevel2[]
}

export interface MenuItemLevel2 extends MenuItemBase {
    level: 2
    footerLink?: MenuFooterLink
    sublinks?: MenuItemLevel3[]
    shouldRenderChildrenInUpperLevel?: boolean
}

export interface MenuItemLevel2WithSublinks extends MenuItemLevel2 {
    sublinks: MenuItemLevel3[]
}

export interface MenuItemLevel3 extends MenuItemBase {
    level: 3
}

export interface MenuFooterLink {
    url: string
    label: string
}

export const isMenuItemLevel1 = (menuItem: MenuItem): menuItem is MenuItemLevel1 => {
    return menuItem.level === 1
}

export const isMenuItemLevel2 = (menuItem: MenuItem): menuItem is MenuItemLevel2 => {
    return menuItem.level === 2
}

export const isMenuItemLevel1Or2 = (menuItem: MenuItem): menuItem is MenuItemLevel1 | MenuItemLevel2 => {
    return menuItem.level === 1 || menuItem.level === 2
}

export const isMenuItemLevel1WithSublinks = (
    menuItemLevel1: MenuItemLevel1,
): menuItemLevel1 is MenuItemLevel1WithSublinks => {
    return typeof menuItemLevel1.sublinks !== 'undefined' && menuItemLevel1.sublinks.length > 0
}

export const isMenuItemLevel2WithSublinks = (
    menuItemLevel2: MenuItemLevel2,
): menuItemLevel2 is MenuItemLevel2WithSublinks => {
    return typeof menuItemLevel2.sublinks !== 'undefined' && menuItemLevel2.sublinks.length > 0
}
