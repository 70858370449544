export enum CategoryName {
    'Concerts' = 'Concerts',
    'Sports' = 'Sports',
    'Theater' = 'Theater',
}

export enum DiscoverCategory {
    TOP_PICKS = 'Top Picks',
    SPORTS = 'Sports',
    THEATER = 'Theater',
    CONCERTS = 'Concerts',
}
