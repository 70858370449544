import { URLS } from '@/constants'

export const DEFAULTLD = [
    {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Vivid Seats LLC',
        url: URLS.VIVIDSEATS,
        logo: 'https://a.vsstatic.com/common/logo/vs-wordmark-white.png',
        sameAs: [
            'https://en.wikipedia.org/wiki/Vivid_Seats',
            'https://www.facebook.com/VividSeats',
            'https://twitter.com/VividSeats',
            'https://www.instagram.com/vividseats',
            'https://www.linkedin.com/company/vividseats',
        ],
    },
    {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: URLS.VIVIDSEATS,
        potentialAction: {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate: `${URLS.VIVIDSEATS}/search?searchTerm={search_term_string}`,
            },
            'query-input': 'required name=search_term_string',
        },
    },
]

export enum CategoryType {
    CONCERTS = 2,
    SPORTS = 3,
    THEATHER = 4,
}

export const CATEGORY_TAGS: {
    [P in CategoryType]: {
        type: string
        performerType: string
        descriptionFn: ({}: {
            performerName?: string
            venueCity?: string
            productionDate: string
            venueCityState?: string
            venueName?: string
            productionName?: string
        }) => string
    }
} = {
    [CategoryType.CONCERTS]: {
        type: 'MusicEvent',
        performerType: 'MusicGroup',
        descriptionFn: ({ performerName, venueCity, productionDate }: { [key: string]: string }) =>
            `Buy ${performerName} in ${venueCity} tickets from Vivid Seats for the concert on ${productionDate} and shop with confidence thanks to our 100% Buyer Guarantee.`,
    },
    [CategoryType.SPORTS]: {
        type: 'SportsEvent',
        performerType: 'SportsTeam',
        descriptionFn: ({ productionName, productionDate, venueCityState }: { [key: string]: string }) =>
            `Buy ${productionName} tickets for ${productionDate} in ${venueCityState} from Vivid Seats and be there in person for all the action! `,
    },
    [CategoryType.THEATHER]: {
        type: 'TheaterEvent',
        performerType: 'TheaterGroup',
        descriptionFn: ({ performerName, venueCity, productionDate, venueName }: { [key: string]: string }) =>
            `Buy ${performerName} in ${venueCity} tickets for the ${productionDate} performance at ${venueName}. You want to catch the show. Vivid Seats makes it easy.`,
    },
}

export enum AttendanceMode {
    OFFLINE,
    ONLINE,
    MIXED,
}

export const STATUS_EVENT: { [key: string]: string } = {
    cancelled: 'http://schema.org/EventCancelled',
    moved: 'http://schema.org/EventMovedOnline',
    postponed: 'http://schema.org/EventPostponed',
    rescheduled: 'http://schema.org/EventRescheduled',
    scheduled: 'http://schema.org/EventScheduled',
}

export const ATTENDANCE_MODE_EVENT: { [key: string]: string } = {
    [AttendanceMode.OFFLINE]: 'https://schema.org/OfflineEventAttendanceMode',
    [AttendanceMode.ONLINE]: 'https://schema.org/OnlineEventAttendanceMode',
    [AttendanceMode.MIXED]: 'https://schema.org/MixedEventAttendanceMode',
}
