import React from 'react'

import type { MenuFooterLink } from '@/components/layouts/main-layout/components/header/components/main-menu/types'

import MenuItemLink from '../menu-item-link'

import styles from './styles.module.scss'

interface Props {
    footerLink: MenuFooterLink
}

const MenuFooterItem: React.FC<Props> = ({ footerLink }) => (
    <li className={styles.menuFooterItem}>
        <MenuItemLink href={footerLink.url} level={3} withBorder>
            {footerLink.label}
        </MenuItemLink>
    </li>
)

export default MenuFooterItem
