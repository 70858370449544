import React, { createContext, useContext, useReducer } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import { createSelector } from 'reselect'

import PerformersNavigationContext from '@/context/performers-navigation'

import {
    commonIconsIds,
    commonStrongIds,
    desktopDividerIds,
    desktopNarrowSubmenuIds,
    desktopRightSideIds,
    desktopShouldRenderChildrenInUpperLevelIds,
    desktopShouldRenderOnlyWhenAuthenticatedIds,
    desktopShouldRenderOnlyWhenNotAuthenticatedIds,
    desktopTopSideIds,
    desktopWithBorderIds,
    mobileDividerIds,
    mobileNarrowSubmenuIds,
    mobileRightSideIds,
    mobileShouldRenderChildrenInUpperLevelIds,
    mobileShouldRenderOnlyWhenAuthenticatedIds,
    mobileShouldRenderOnlyWhenNotAuthenticatedIds,
    mobileTopSideIds,
    mobileWithBorderIds,
} from '../constants/menu-items-optional-properties-ids'
import type { MenuItemLevel1 } from '../types'

import { addOptionalPropertiesToMenuItems, processMenuItemsLevel1 } from './utils'

enum ActionType {
    CLEAR_SUBMENU_SELECTION,
    SELECT_SUBMENU,
    TOGGLE_NAV,
    UNSELECT_SUBMENU,
}

interface ClearSubmenuSelectionAction {
    type: ActionType.CLEAR_SUBMENU_SELECTION
}

interface SelectSubmenuAction {
    type: ActionType.SELECT_SUBMENU
    payload: number
}

interface ToggleNavAction {
    type: ActionType.TOGGLE_NAV
    payload: boolean
}

interface UnselectSubmenuAction {
    type: ActionType.UNSELECT_SUBMENU
}

type Action = ClearSubmenuSelectionAction | SelectSubmenuAction | ToggleNavAction | UnselectSubmenuAction

interface State {
    activeSubmenuPath: number[]
    isNavOpen: boolean
}

interface Selectors {
    mobileMenuItems: MenuItemLevel1[]
    desktopMenuItems: MenuItemLevel1[]
}

interface Dispatch {
    selectSubmenu: (index: number) => void
    toggleNav: (isOpen: boolean) => void
    unselectSubmenu: () => void
}

interface ContextValue {
    state: State
    selectors: Selectors
    dispatch: Dispatch
}

export const defaultContextValue: ContextValue = {
    state: {
        activeSubmenuPath: [],
        isNavOpen: false,
    },
    selectors: {
        mobileMenuItems: [],
        desktopMenuItems: [],
    },
    dispatch: {
        selectSubmenu: () => undefined,
        toggleNav: () => undefined,
        unselectSubmenu: () => undefined,
    },
}
const context = createContext(defaultContextValue)

const desktopMenuItemsSelector = createSelector([(menuItems: MenuItemLevel1[]) => menuItems], (menuItems) => {
    return addOptionalPropertiesToMenuItems(
        cloneDeep(menuItems),
        desktopShouldRenderChildrenInUpperLevelIds,
        desktopDividerIds,
        desktopWithBorderIds,
        commonStrongIds,
        desktopShouldRenderOnlyWhenAuthenticatedIds,
        desktopShouldRenderOnlyWhenNotAuthenticatedIds,
        desktopNarrowSubmenuIds,
        desktopTopSideIds,
        desktopRightSideIds,
        commonIconsIds,
    ) as MenuItemLevel1[]
})

const mobileMenuItemsSelector = createSelector([(menuItems: MenuItemLevel1[]) => menuItems], (menuItems) => {
    return addOptionalPropertiesToMenuItems(
        cloneDeep(menuItems),
        mobileShouldRenderChildrenInUpperLevelIds,
        mobileDividerIds,
        mobileWithBorderIds,
        commonStrongIds,
        mobileShouldRenderOnlyWhenAuthenticatedIds,
        mobileShouldRenderOnlyWhenNotAuthenticatedIds,
        mobileNarrowSubmenuIds,
        mobileTopSideIds,
        mobileRightSideIds,
        commonIconsIds,
    ) as MenuItemLevel1[]
})

const restructuredMobileMenuItemsSelector = createSelector(mobileMenuItemsSelector, (originalMenuItemsLevel1) =>
    processMenuItemsLevel1(originalMenuItemsLevel1),
)

const restucturedDesktopMenuItemsSelector = createSelector(desktopMenuItemsSelector, (originalMenuItemsLevel1) =>
    processMenuItemsLevel1(originalMenuItemsLevel1),
)

const selectSubmenuAction = (dispatch: React.Dispatch<SelectSubmenuAction>, index: number) => {
    dispatch({ type: ActionType.SELECT_SUBMENU, payload: index })
}

const toggleNavAction = (dispatch: React.Dispatch<Action>, isOpen: boolean) => {
    dispatch({ type: ActionType.TOGGLE_NAV, payload: isOpen })
    if (!isOpen) {
        setTimeout(() => dispatch({ type: ActionType.CLEAR_SUBMENU_SELECTION }), 200)
    }
}

const unselectSubmenuAction = (dispatch: React.Dispatch<UnselectSubmenuAction>) => {
    dispatch({ type: ActionType.UNSELECT_SUBMENU })
}

const useMainMenuReducer = (): [State, React.Dispatch<Action>] => {
    return useReducer((state: State, action: Action) => {
        switch (action.type) {
            case ActionType.CLEAR_SUBMENU_SELECTION:
                return { ...state, activeSubmenuPath: [] }
            case ActionType.SELECT_SUBMENU:
                return { ...state, activeSubmenuPath: [...state.activeSubmenuPath, action.payload] }
            case ActionType.TOGGLE_NAV:
                return { ...state, isNavOpen: action.payload }
            case ActionType.UNSELECT_SUBMENU:
                return { ...state, activeSubmenuPath: state.activeSubmenuPath.slice(0, -1) }
        }
    }, defaultContextValue.state)
}

export const MainMenuProvider: React.$FC = ({ children }) => {
    const [state, dispatch] = useMainMenuReducer()
    const {
        selectors: { mergedHardCodedAndCatalogMenuItems },
    } = useContext(PerformersNavigationContext)

    return (
        <context.Provider
            value={{
                state,
                selectors: {
                    mobileMenuItems: restructuredMobileMenuItemsSelector(mergedHardCodedAndCatalogMenuItems).filter(
                        (item) => !item.shouldRenderOnlyOnDesktop,
                    ),
                    desktopMenuItems: restucturedDesktopMenuItemsSelector(mergedHardCodedAndCatalogMenuItems).filter(
                        (item) => !item.shouldRenderOnlyOnMobile,
                    ),
                },
                dispatch: {
                    selectSubmenu: (index) => selectSubmenuAction(dispatch, index),
                    toggleNav: (isOpen) => toggleNavAction(dispatch, isOpen),
                    unselectSubmenu: () => unselectSubmenuAction(dispatch),
                },
            }}
        >
            {children}
        </context.Provider>
    )
}

export default context
