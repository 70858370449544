import React from 'react'

import MenuItemLink from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-item-link'
import type { MenuItemLevel3 as MenuItemLevel3Type } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import { getMenuAriaLabel } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'

import styles from './styles.module.scss'

interface Props {
    divider: boolean
    menuItem: MenuItemLevel3Type
    parentLabel: string
}

const MenuItemLevel3: React.FC<Props> = ({ divider, menuItem, parentLabel }) => (
    <li className={styles.menuItemLevel3} data-testid="desktop-nav-menu-item-level-3">
        {divider && <div className={styles.divider} />}
        <MenuItemLink
            className={styles.level3Text}
            href={menuItem.url}
            level={3}
            strong={menuItem.strong}
            withBorder={menuItem.withBorder}
            label={getMenuAriaLabel(menuItem.label, parentLabel)}
        >
            {menuItem.label}
        </MenuItemLink>
    </li>
)

export default MenuItemLevel3
