import React, { useContext } from 'react'

import classNames from 'classnames'

import mainMenuContext from '@/components/layouts/main-layout/components/header/components/main-menu/context'

import MenuLevel1 from './components/menu-level-1'
import styles from './styles.module.scss'

interface Props {
    className?: string
}

const Desktop: React.FC<Props> = ({ className }) => {
    const {
        selectors: { desktopMenuItems: menuItems },
    } = useContext(mainMenuContext)

    const mergedMainMenuClassName = classNames(className, styles.mainMenu)

    return (
        <nav className={mergedMainMenuClassName}>
            <MenuLevel1 menuItems={menuItems} />
        </nav>
    )
}

export default Desktop
