import React from 'react'

import Head from 'next/head'

import type { JsonLdItem } from '@/types/app-types'

import { DEFAULTLD } from './constants'

const JsonLd: React.FC<{ items?: JsonLdItem[] }> = ({ items = DEFAULTLD }) => {
    return (
        <Head>
            {items.map((data, index) => (
                <script
                    type="application/ld+json"
                    key={`${index}-${data['@type']}`}
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(data, null, '\t') }}
                />
            ))}
        </Head>
    )
}

export default JsonLd
