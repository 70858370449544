import type { CatalogCategory } from '@/types/app-types'

import { Breadcrumb } from '.'

// Replace /theatre/ with /theater/ and remove trailing slash
const normalizeUrl = (url: string): string => {
    if (url === '/') {
        return url
    }
    return url.replace(/^\/theatre\//, '/theater/').replace(/\/$/, '')
}

export const getBreadcrumbsFromCategory = (
    category: CatalogCategory,
    options: { isExternalLink: boolean },
): Breadcrumb[] => {
    if (category) {
        const { organicUrl, name: title, subCategories = [] } = category
        const { isExternalLink } = options

        const breadcrumbs: Breadcrumb[] = [{ url: normalizeUrl(organicUrl), title, isExternal: isExternalLink }]

        if (subCategories[0]?.organicUrl) {
            breadcrumbs.push({
                url: normalizeUrl(subCategories[0].organicUrl),
                title: subCategories[0].name,
                isExternal: isExternalLink,
            })
        }

        return breadcrumbs
    }

    return []
}
