import dayjs from 'dayjs'

import { URLS } from '@/constants'
import { replaceCmsTokens } from '@/context/cms/utils'
import { USER_DEFINED, USER_LOCATION_KEY } from '@/context/location/constants'
import { getHeroImage } from '@/context/production-details/utils'
import type {
    CatalogAsset,
    Metadata,
    Performer,
    Production,
    ProductionListSelectorType,
    ProductionPage,
    UserLocation,
} from '@/types/app-types'
import { CategoryName } from '@/types/enums'
import { getCookie } from '@/utils/cookies'
import { decodeBase64Json } from '@/utils/data'

import { INIT_USER_LOCATION_COOKIE } from './constants'
import type { GetUserLocationCookie } from './types'

export const getMetadata = (performer?: Performer, assets?: CatalogAsset[]): Metadata => {
    const metadata: Metadata = {}
    if (performer) {
        const isParkingPerformer = performer.name.toLowerCase().endsWith('parking')
        metadata.description = getMetadataDescription(performer, isParkingPerformer)
        metadata.image = getMetadataImage(performer, assets)
        metadata.keywords = getMetadataKeywords(performer, isParkingPerformer)
        metadata.title = getMetadataTitle(performer, isParkingPerformer)
        metadata.url = getMetadataUrl(performer)
    }
    return metadata
}

const getMetadataDescription = (performer: Performer, isParkingPerformer: boolean): string | undefined => {
    if (performer.meta?.description) {
        return replaceCmsTokens(performer.meta.description, { performer })
    }
    if (isParkingPerformer) {
        return `Get ${performer.name} passes and ${performer.name} information from Vivid Seats. 100% Buyer Guarantee!`
    }
    const thisYearNextYear = `${dayjs().format('YYYY')} - ${dayjs().add(1, 'y').format('YYYY')}`
    switch (performer.category.name.toLowerCase()) {
        case CategoryName.Sports.toLowerCase():
            return `Get ${performer.name} tickets and ${thisYearNextYear} ${performer.name} schedule information from Vivid Seats. 100% Buyer Guarantee!`
        case CategoryName.Concerts.toLowerCase():
            return `Get ${performer.name} tickets, ${thisYearNextYear} tour information and the ${performer.name} concert schedule from Vivid Seats. 100% Buyer Guarantee!`
        case CategoryName.Theater.toLowerCase():
            return `Get ${performer.name} tickets and the ${performer.name} show schedule from Vivid Seats. 100% Buyer Guarantee!`
    }
}

const getMetadataImage = (performer: Performer, assets?: CatalogAsset[]): string | null => {
    if (!assets || assets.length === 0) {
        return null
    }
    return getHeroImage(performer.id, assets, 'mobile')
}

const getMetadataKeywords = (performer: Performer, isParkingPerformer: boolean): string => {
    if (performer.meta?.keywords) {
        return replaceCmsTokens(performer.meta.keywords, { performer })
    }
    const ticketsLabel = isParkingPerformer ? 'passes' : 'tickets'
    return `${performer.name} ${ticketsLabel}`
}

const getMetadataTitle = (performer: Performer, isParkingPerformer: boolean): string => {
    if (performer.meta?.title) {
        return replaceCmsTokens(performer.meta.title, { performer })
    }
    if (isParkingPerformer) {
        return `${performer.name} | Vivid Seats`
    }
    return `${performer.name} Tickets | Vivid Seats`
}

const getMetadataUrl = (performer: Performer): string | null => {
    return performer.webPath ? `${URLS.VIVIDSEATS}${performer.webPath}` : null
}

export const mergeProductions = (
    featuredProductionsData: ProductionPage,
    featuredProductionsDriveDistance: ProductionPage,
): ProductionPage => {
    const nearbyProductionsId = featuredProductionsData.items.map((production: Production) => production.id)
    const updatedItems = featuredProductionsDriveDistance.items.map((production: Production) => ({
        ...production,
        isDriveDistance: !nearbyProductionsId.includes(production.id),
    }))

    return {
        ...featuredProductionsDriveDistance,
        items: updatedItems,
    }
}

export const getDataFromSearchEngineMarketing = (
    sem: string | undefined,
): {
    latLong: string | undefined
    searchEngineMarketingCity: string | undefined
    heading: string | undefined
    subheading: string | undefined
    semShowNearYou: boolean | undefined
} => {
    let latLong: string | undefined
    let searchEngineMarketingCity: string | undefined
    let heading: string | undefined
    let subheading: string | undefined
    let semShowNearYou: boolean | undefined
    if (sem) {
        const { loc = {}, head, subhead, near } = decodeBase64Json(sem)
        if (loc.lat && loc.lng) {
            latLong = `${loc.lat};${loc.lng}`
        }
        if (loc.name) {
            searchEngineMarketingCity = loc.name
        }
        if (head) {
            heading = head
        }
        if (subhead) {
            subheading = subhead
        }
        if (typeof near == 'boolean') {
            semShowNearYou = near
        }
    }

    return { latLong, searchEngineMarketingCity, heading, subheading, semShowNearYou }
}

export const getUserLocationCookie = (cookies: string | undefined): GetUserLocationCookie => {
    if (!cookies) return INIT_USER_LOCATION_COOKIE

    const latLongCookie = getCookie(cookies, USER_LOCATION_KEY) as UserLocation
    if (!latLongCookie || !latLongCookie.latitude || !latLongCookie.longitude) return INIT_USER_LOCATION_COOKIE

    const latLongCookieValue = `${latLongCookie.latitude};${latLongCookie.longitude}`
    const hasSelectedLocation = latLongCookie.method === USER_DEFINED

    return {
        latLong: latLongCookieValue,
        hasSelectedLocation,
    }
}

export const getLocationDataFromHeader = (cookieHeader: string | number | string[] | undefined) => {
    const cookies = Array.isArray(cookieHeader) ? cookieHeader.join(';') : undefined
    return getUserLocationCookie(cookies)
}

export const filterNearbyProductions = (
    productions: ProductionListSelectorType[] | undefined,
    filteredProductions: Production[],
    hasFiltersApplied: boolean,
) => {
    if (!productions) return []
    if (!hasFiltersApplied) return productions
    return productions.filter((production) =>
        filteredProductions.some((filteredProduction) => filteredProduction.id === production.id),
    )
}
