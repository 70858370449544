import React, { FC } from 'react'

import { Text } from '@vividseats/vivid-ui-kit'

export interface CopyrightTextProps extends Record<string, unknown> {
    className?: string
    small?: boolean
}

export const COPYRIGHT_TEXT = `\u00a9${new Date().getFullYear()} Vivid Seats LLC. All Rights Reserved.`

export const CopyrightText: FC<CopyrightTextProps> = ({ small = false, ...props }) => (
    <Text as="span" size={small ? 'xs' : 'md'} weight="regular" altFont {...props}>
        {COPYRIGHT_TEXT}
    </Text>
)

export default CopyrightText
