import { ReactSDKClient } from '@optimizely/react-sdk'

import { OPTIMIZELY_FEATURE } from '@/optimizely/constants'
import type { OptimizelyHookResult } from '@/optimizely/types'

export const useSearchBarFeature = (
    optimizely: Pick<ReactSDKClient, 'isFeatureEnabled' | 'getFeatureVariables'> | null,
): OptimizelyHookResult => {
    const isFeatureEnabled = !!optimizely?.isFeatureEnabled(OPTIMIZELY_FEATURE.searchBar)
    const variables = optimizely?.getFeatureVariables(OPTIMIZELY_FEATURE.searchBar)

    if (!isFeatureEnabled || !variables) {
        return [isFeatureEnabled, {}]
    }

    return [isFeatureEnabled, variables]
}
