// variant of src/components/layouts/main-layout/components/header/components/main-menu/components/mobile/component.tsx

import React, { useContext } from 'react'

import { ButtonV2, Icon } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import mainMenuContext from '@/components/layouts/main-layout/components/header/components/main-menu/context'
import headerStyleContext from '@/context/header'

import styles from './styles.module.scss'

interface Props {
    className?: string
    isMobileAboveTheFold?: boolean
}

const MobileMainMenu: React.FC<Props> = ({ className, isMobileAboveTheFold }) => {
    const {
        dispatch: { toggleNav },
    } = useContext(mainMenuContext)
    const {
        state: { transparentHeaderClassName, isTransparent },
    } = useContext(headerStyleContext)

    const mergedMobileMainMenuClassName = classNames(styles.mobileMainMenu, className)

    const iconClassNames = classNames({
        [styles.headerTransition]: !isMobileAboveTheFold && isTransparent,
        [styles[transparentHeaderClassName]]: !isMobileAboveTheFold && isTransparent,
        [styles.isMobileAboveTheFold]: isMobileAboveTheFold,
    })

    return (
        <div className={mergedMobileMainMenuClassName} data-testid="mobile-main-menu">
            <ButtonV2
                className={styles.button}
                data-testid="sticky-menu-button"
                aria-label="Open the menu"
                importance="text"
                onClick={() => toggleNav(true)}
            >
                <Icon className={iconClassNames} size="md" type="menu" />
            </ButtonV2>
        </div>
    )
}

export default MobileMainMenu
