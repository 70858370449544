import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { ParsedUrlQuery } from 'querystring'

import { ENVIRONMENTS, URLS, USER_DATA_COOKIE, UTM_PROMO } from '@/constants'
import { getHeroImage } from '@/context/production-details/utils'
import type { Metadata, ObjectWithKeyIteration, ProductionDetail } from '@/types/app-types'
import { CategoryName } from '@/types/enums'
import { shortDateFromLocalString } from '@/utils/dates'

import { TBD, TO_BE_DETERMINED, LOCATION, VENUE } from './constants'

const getTicketsLabel = (production: ProductionDetail): string => {
    const isParkingProduction = production.name.toLowerCase().endsWith('parking')
    return isParkingProduction ? 'passes' : 'tickets'
}

export const getMetadata = (production: ProductionDetail | undefined): Metadata => {
    const metadata: Metadata = {}
    if (production) {
        const ticketsLabel = getTicketsLabel(production)
        metadata.description = getMetadataDescription(production, ticketsLabel)
        metadata.image = getMetadataImage(production)
        metadata.title = getMetadataTitle(production, ticketsLabel)
        metadata.url = getMetadataUrl(production)
    }
    return metadata
}

const getMetadataDescription = (production: ProductionDetail, ticketsLabel: string) => {
    const date = shortDateFromLocalString(production.localDate)
    switch (production.performers[0].category.name.toLowerCase()) {
        case CategoryName.Sports.toLowerCase():
            const location = `${production.venue.city}${!!production.venue.state ? `, ${production.venue.state}` : ''}${
                production.venue.countryCode.toLowerCase() !== 'us' ? `, ${production.venue.countryCode}` : ''
            }`
            return `Buy ${production.name} ${ticketsLabel} for ${date} in ${location} from Vivid Seats and be there in person for all the action!`
        case CategoryName.Concerts.toLowerCase():
            return `Buy ${production.name} in ${production.venue.city} ${ticketsLabel} from Vivid Seats for the concert on ${date} and shop with confidence thanks to our 100% Buyer Guarantee.`
        case CategoryName.Theater.toLowerCase():
            return `Buy ${production.name} in ${production.venue.city} ${ticketsLabel} for the ${date} performance at ${production.venue.name}. You want to catch the show. Vivid Seats makes it easy.`
        default:
            return null
    }
}

const getMetadataImage = (production: ProductionDetail) => {
    return getHeroImage(production.performers[0].id, production.assets, 'mobile')
}

const getMetadataTitle = (production: ProductionDetail, ticketsLabel: string) => {
    const date = shortDateFromLocalString(production.localDate)
    switch (production.performers[0].category.name.toLowerCase()) {
        case CategoryName.Sports.toLowerCase():
            return `${production.name} ${ticketsLabel} - ${production.venue.name} - ${date} | Vivid Seats`
        case CategoryName.Concerts.toLowerCase():
        case CategoryName.Theater.toLowerCase():
            return `${production.performers[0].name} ${production.venue.city} ${ticketsLabel} - ${production.venue.name} - ${date} | Vivid Seats`
        default:
            return null
    }
}

const getMetadataUrl = (production: ProductionDetail) => {
    return production.webPath ? `${URLS.VIVIDSEATS}${production.webPath}` : null
}

export const getPromoCode = (cookies: NextApiRequestCookies, query: ParsedUrlQuery): string => {
    const userDataCookie = cookies?.[USER_DATA_COOKIE]
    let parsedUserDataCookie
    if (userDataCookie) {
        try {
            parsedUserDataCookie = JSON.parse(userDataCookie) as ObjectWithKeyIteration
        } catch (err) {
            console.error('Unable to parse userData cookie', (err as Error).message)
        }
    }

    return query?.[UTM_PROMO] || parsedUserDataCookie?.utmPromo || ''
}

export const getDisplayName = (text: string | undefined): string => {
    if (text) {
        return text.includes(TO_BE_DETERMINED) || text.includes(TBD) ? TBD : text
    }
    return TBD
}

export const getDisplayText = (locationName?: string, venueName?: string, initialSeparator: string = 'in') => {
    let locationDisplayName = getDisplayName(locationName)
    let venueDisplayName = getDisplayName(venueName)
    let separator = initialSeparator

    if (locationDisplayName === TBD || venueDisplayName === TBD) {
        separator = '|'
        locationDisplayName = `${LOCATION}: ${locationDisplayName}`
        venueDisplayName = `${VENUE}: ${venueDisplayName}`
    }
    return { locationDisplayName, venueDisplayName, separator }
}

export const getRefererIsExternal = (referer?: string) =>
    !referer || !Object.values(ENVIRONMENTS).some((substring) => referer.includes(substring))

export const getRefererIsCheckout = (referer: string) => {
    const url = new URL(referer)
    const { pathname } = url
    return pathname.includes('/checkout')
}

export const getProductionRefererPath = () => {
    if (typeof sessionStorage === 'undefined') return ''
    return sessionStorage.getItem('productionRefererPath')
}