import React, { useContext } from 'react'

import { IMAGES } from '@/constants'
import SuggestionsContext from '@/context/suggestions'
import type { PerformerImage } from '@/types/app-types'
import { buildExternalImageUrl } from '@/utils/build-external-image-url'

import PerformerSuggestionContent from './components/performer-suggestion-content'

type PerformerSuggestionProps = {
    performer: PerformerImage
    index: number
}

const PerformerSuggestion: React.FC<PerformerSuggestionProps> = ({ performer, index }) => {
    const {
        state: {
            cursorPositionByCategory: { performerSelectState },
        },
        dispatch: { handleNavigatePerformerItem },
    } = useContext(SuggestionsContext)

    const onClick = () => {
        handleNavigatePerformerItem(performer, index)
    }

    const imageUrl = performer.external
        ? buildExternalImageUrl(`${process.env.NEXT_PUBLIC_EXTERNAL_IMAGE_URL_PREFIX}`, performer.external, 42, 42)
        : performer.thumbnail || performer.mobile || IMAGES.DEFAULT_HERO_MOBILE

    return (
        <PerformerSuggestionContent
            data-testid={`performer-suggestion-${performer.id}`}
            name={performer.name}
            imageUrl={imageUrl}
            mainPerformerSubCategoryName={performer.mainPerformerSubCategoryName}
            productionCount={performer.productionCount}
            href={performer.url}
            onClickCapture={onClick}
            isSelected={performerSelectState === index + 1}
        />
    )
}

export default PerformerSuggestion
