import { atom } from 'recoil'

export interface UtahPerformerVenueState {
    isUtahPerformerOrVenue: boolean
}

const initialState: UtahPerformerVenueState = { isUtahPerformerOrVenue: false }

export const utahPerformerVenueAtom = atom<UtahPerformerVenueState>({
    key: 'utahPerformerVenueState',
    default: initialState,
})
