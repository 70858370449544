import React, { forwardRef, HTMLAttributes } from 'react'

import { Row, Text, Badge, Box } from '@vividseats/vivid-ui-kit'
import classnames from 'classnames'
import Link from 'next/link'

import ShopButton from '@/components/shared/searchbar/components/shop-button'

import styles from './styles.module.scss'

interface ProductionListingRowType extends HTMLAttributes<HTMLAnchorElement> {
    date?: string
    day?: string
    title: string
    label: string
    sublabel?: string
    href?: string
    isSelected?: boolean
}

const ProductionSuggestionContent: React.ForwardRefRenderFunction<
    React.MutableRefObject<unknown>,
    ProductionListingRowType
> = ({ date, day, title, label, sublabel, href, children, isSelected, ...props }, ref: any) => {
    const seperatedDate = date?.split(', ')
    const productionRowClassnames = classnames(styles.productionRow, {
        [styles.selected]: isSelected,
    })

    return (
        <Link href={href || ''} {...props} ref={ref} className={styles.link} tabIndex={-1}>
            <Row
                direction="row"
                align="center"
                justify="between"
                className={productionRowClassnames}
                data-testid="production-row"
            >
                <Row align="center" className={styles.productionItem}>
                    <Box className={styles.icon}>
                        <Text altFont size="sm" weight="regular" alignment="center">
                            {day}
                        </Text>
                        <Text altFont size="sm" weight="regular-bold" alignment="center" className={styles.date}>
                            {seperatedDate && seperatedDate[0]}
                        </Text>
                        {seperatedDate && seperatedDate[1] && (
                            <Badge className={styles.year}>
                                <Text altFont size="sm" weight="regular-bold" alignment="center">
                                    {seperatedDate[1]}
                                </Text>
                            </Badge>
                        )}
                    </Box>
                    <Box className={styles.titleWrapper}>
                        <Text altFont size="md" weight="regular-bold" className={styles.title}>
                            {title}
                        </Text>
                        <Text altFont size="sm" className={styles.label}>
                            {label}
                        </Text>
                        {!!sublabel && (
                            <Text altFont size="sm" opaque>
                                {sublabel}
                            </Text>
                        )}
                    </Box>
                </Row>
                <Row>
                    <ShopButton
                        className={{ buttonText: styles.shopButtonText, buttonArrow: styles.shopButtonArrow }}
                    />
                </Row>
                {children}
            </Row>
        </Link>
    )
}

export default forwardRef(ProductionSuggestionContent)
