import { useRouter } from 'next/router'

const usePageName = (): string => {
    const { route } = useRouter() || {}
    if (new RegExp('/performer/').test(route)) return 'Performer'
    else if (new RegExp('/production/').test(route)) return 'Production'
    else if (new RegExp('/region').test(route)) return 'Region'
    else return '404'
}

export default usePageName
