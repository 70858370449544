import React, { SyntheticEvent, useContext } from 'react'

import { Box, ButtonV2, Icon, Link, Text } from '@vividseats/vivid-ui-kit'
import classnames from 'classnames'
import NextLink from 'next/link'

import SuggestionsContext from '@/context/suggestions/context'
import { useRecentSearchResults } from '@/hooks/use-recent-search-results'
import type { RecentSearchResult } from '@/hooks/use-recent-search-results/types'

import styles from './styles.module.scss'

interface RecentSearchResultSuggestionProps {
    recentSearchResult: RecentSearchResult
    index: number
    isSelected?: boolean
}

const RecentSearchResultSuggestion: React.FC<RecentSearchResultSuggestionProps> = ({
    recentSearchResult,
    index,
    isSelected,
}) => {
    const {
        dispatch: { handleNavigateRecentItem },
    } = useContext(SuggestionsContext)
    const { removeRecentSearchResult } = useRecentSearchResults()

    const handleClick = () => {
        handleNavigateRecentItem(recentSearchResult.title, index)
    }
    const recentSearchClassnames = classnames(styles.recentSearchRow, {
        [styles.selected]: isSelected,
    })
    return (
        <Box
            data-testid={`recent-search-result-${recentSearchResult.title}`}
            mobileDisplay="flex"
            tabletDisplay="flex"
            mobileJustify="between"
            tabletJustify="between"
            mobileAlign="center"
            tabletAlign="center"
            className={recentSearchClassnames}
        >
            <Link
                href={recentSearchResult.url}
                className={styles.link}
                onClick={handleClick}
                LinkComponent={NextLink}
                legacyBehavior
                tabIndex={-1}
            >
                <Box className={styles.iconWrapper}>
                    <Icon type="recent-arrow" size="md" className={styles.icon} />
                </Box>
                <Text size="md" className={styles.title} altFont>
                    {recentSearchResult.title}
                </Text>
            </Link>
            <ButtonV2
                importance="none"
                data-testid={`recent-search-result-${recentSearchResult.title}-remove`}
                className={styles.removeButton}
                onClick={(e: SyntheticEvent) => {
                    e.preventDefault()
                    e.stopPropagation()
                    removeRecentSearchResult(recentSearchResult)
                }}
            >
                <Icon type="close" size="sm" className={styles.icon} />
            </ButtonV2>
        </Box>
    )
}

export default RecentSearchResultSuggestion
