import { useCallback, useEffect, useState } from 'react'
interface ScrollPercentage {
    scrollPercentage: number
}

// Every TRANSITION_PERCENTAGE_STEP percent, update the transparency. The lower the value, the more renders will occur
// A trade-off between performance (higher values) and smoothness (lower values)
export const TRANSITION_PERCENTAGE_STEP = 20

// At FULL_OPAQUE_HEIGHT_CAP pixels and above, "jump" to using the full opacity value (rgba 255, 255, 255, 1)
export const FULL_OPAQUE_HEIGHT_CAP = 700

/**
 * Hook to calculate the window's scroll position relative to a goal
 * ...performant useCallback logic inspired by https://gist.github.com/joshuacerbito/ea318a6a7ca4336e9fadb9ae5bbb87f4#gistcomment-3562404
 * @param enabled If this flag is true, attach event listener for scroll. Otherwise, all functionality is ignored
 * @returns the ScrollPercentage object (scroll percentage)
 */
const useScrollPercentage = (
    enabled = true,
    transitionPercentage = TRANSITION_PERCENTAGE_STEP,
    heightCap = FULL_OPAQUE_HEIGHT_CAP,
): ScrollPercentage => {
    const [scrollPercentage, setScrollPercentage] = useState(() => 0)

    // Called on every scroll change
    const handleScrollEvent = useCallback(() => {
        // Immediately call setScrollPercentage() so that scrollPercentage is not a required dependency to useCallback()
        setScrollPercentage(() => {
            // [0%..100%]
            // How far down the page you are towards a goal
            if (window.scrollY < 0) return 0
            const newScrollPercentage = Math.min((window.scrollY / heightCap) * 100, 100)

            // Round down to the closest multiple of TRANSITION_PERCENTAGE_STEP, i.e. 0, 20, 40, 60 ....
            const roundedScrollPercentage =
                Math.floor(newScrollPercentage / transitionPercentage) * transitionPercentage

            return roundedScrollPercentage
        })
    }, [heightCap, transitionPercentage])

    useEffect(() => {
        if (window && enabled) {
            window.addEventListener('scroll', handleScrollEvent, {
                passive: true,
            })
        }
        return () => window.removeEventListener('scroll', handleScrollEvent)
    }, [enabled, handleScrollEvent])

    // [0..100]
    // Return 100 if disabled (i.e. "100% at the goal")
    return { scrollPercentage: enabled ? scrollPercentage : 100 }
}

export default useScrollPercentage
