import React, { forwardRef, InputHTMLAttributes } from 'react'

import classNames from 'classnames'

import styles from './styles.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    sizing?: 'small' | 'large'
    isCenteredInHero?: boolean
    isFullWidth?: boolean
}

const Input = forwardRef<HTMLInputElement, Props>(
    ({ className, sizing, isCenteredInHero, isFullWidth, ...htmlAttributes }, ref) => {
        const mergedClassName = classNames(styles.input, className, {
            [styles[sizing || '']]: !!sizing,
            [isCenteredInHero ? styles.isCenteredInHero : '']: !!isCenteredInHero,
            [isFullWidth ? styles.isFullWidth : '']: !!isFullWidth,
        })

        return <input ref={ref} className={mergedClassName} {...htmlAttributes} />
    },
)

Input.displayName = 'SearchFormInput'

export default Input
