import type { RecentSearchResult } from './types'

export const excludeRecentSearchResult = (
    allRecentSearchResults: RecentSearchResult[],
    excludedRecentSearchResult: RecentSearchResult,
): RecentSearchResult[] => {
    return allRecentSearchResults.filter(
        ({ type, title }: RecentSearchResult) =>
            type !== excludedRecentSearchResult.type || title !== excludedRecentSearchResult.title,
    )
}
