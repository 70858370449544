import { useContext } from 'react'

import { PAID_SEARCH_URL_APPEND } from '@/constants'
import userDataContext from '@/context/user-data'

export const useHomeLinkWithUrlAppend = (): string => {
    const {
        selectors: { isFromAdWordsSelector: hasPaidSearchDisclaimer },
    } = useContext(userDataContext)

    return `/${hasPaidSearchDisclaimer ? PAID_SEARCH_URL_APPEND : ''}`
}
