import React, { useContext } from 'react'

import SuggestionsContext from '@/context/suggestions'
import { Venue } from '@/types/app-types'

import VenueListing from './components/venue-listing'

type VenueSuggestionProps = {
    venue: Venue
    index: number
}

const VenueSuggestion: React.FC<VenueSuggestionProps> = ({ venue, index }) => {
    const {
        state: {
            cursorPositionByCategory: { venueSelectState },
        },
        dispatch: { handleNavigateVenueItem: handleNavigateVenuItem },
    } = useContext(SuggestionsContext)

    const venueUrl: string | undefined = venue.webPath || venue.organicUrl

    const onClick = () => {
        handleNavigateVenuItem(venue, index)
    }

    return (
        <VenueListing
            data-testid={`venue-suggestion-${venue.id}`}
            name={venue.name}
            productionCount={venue.productionCount}
            href={venueUrl || `/404?id=${venue.id}&message=${encodeURIComponent('No url set for this venue')}`}
            onClickCapture={onClick}
            isSelected={venueSelectState === index + 1}
        />
    )
}

export default VenueSuggestion
