import React, { useContext } from 'react'

import { Box, Link, backdropContext } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import NextLink from 'next/link'

import SuggestionsContext from '@/context/suggestions'
import TrendingsContext from '@/context/trendings'
import { Trending } from '@/types/app-types'

import styles from './styles.module.scss'
import { FIRST_TRENDING_SET } from './types'

export interface TrendingProps {
    selectedIndex: number
    displayAll?: boolean
}
interface TrendingItemProps {
    trendingItem: Trending
    index: number
    handleClick: (itemTitle: string, index: number) => void
    isSelected?: boolean
}

export const TrendingItem: React.FC<TrendingItemProps> = ({ trendingItem, index, handleClick, isSelected }) => {
    const trendingListClassnames = classNames(styles.pill, {
        [styles.selected]: isSelected,
    })

    if (!trendingItem.term || !trendingItem.url) return <></>

    return (
        <Link
            LinkComponent={NextLink}
            className={styles.noFocusUnderline}
            href={trendingItem.url}
            key={trendingItem.term}
            onClick={() => handleClick(trendingItem.term, index)}
            tabIndex={-1}
            data-testid="trending-item-link"
            legacyBehavior
        >
            <li className={trendingListClassnames} data-testid={trendingItem.term.toLowerCase()}>
                <span className={styles.icon} role="img" aria-label={trendingItem.term}>
                    {trendingItem.icon}
                </span>
                {trendingItem.term}
            </li>
        </Link>
    )
}

const BrowseByTrending: React.FC<TrendingProps> = ({ selectedIndex, displayAll = true }) => {
    const {
        dispatch: { setToggled },
    } = useContext(backdropContext)

    const {
        dispatch: { handleNavigateTrendingItem },
    } = useContext(SuggestionsContext)

    const {
        selectors: { trendings },
    } = useContext(TrendingsContext)

    const handleClick = (title: string, index: number) => {
        setToggled({ toggled: false })
        handleNavigateTrendingItem(title, index)
    }

    const trendingList = displayAll ? trendings : trendings.slice(0, FIRST_TRENDING_SET)

    return (
        <Box className={styles.container} data-testid="browse-by-trending">
            <ul className={styles.listStyles}>
                {trendingList.map((trendingItem, i) => {
                    return (
                        <TrendingItem
                            key={i}
                            index={i}
                            trendingItem={trendingItem}
                            handleClick={handleClick}
                            isSelected={selectedIndex === i}
                        />
                    )
                })}
            </ul>
        </Box>
    )
}

export default BrowseByTrending
