import React, { useContext, useState } from 'react'

import { backdropContext } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import MenuItemLink from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-item-link/component'
import headerStyleContext from '@/context/header'
import InternationalizationContext from '@/context/internationalization'
import { CountryFlag } from '@/design-system/custom-icons'

import CurrencyPicker from './components/currency-picker'
import styles from './components/currency-picker/styles.module.scss'

interface HeaderCurrencyPickerProps {
    className?: string
    isDesktop?: boolean
}

const HeaderCurrencyPicker: React.FC<HeaderCurrencyPickerProps> = ({ className, isDesktop }) => {
    const {
        selectors: { flag, selectedCurrency },
    } = useContext(InternationalizationContext)

    const {
        state: { isTransparent, transparentHeaderClassName, forceOpaque },
        dispatch: { setForceOpaque },
    } = useContext(headerStyleContext)

    const {
        dispatch: { setToggled },
    } = useContext(backdropContext)

    const [showSubmenu, setShowSubmenu] = useState(false)

    const mergedMenuItemLevel1ClassName = classNames(styles.menuItemLevel1, className)
    const mergedMenuItemLinkClassName = classNames(styles.menuItemLink, {
        [styles.headerTransition]: isTransparent,
        [styles[transparentHeaderClassName]]: isTransparent,
        [styles['transparentHeader100']]: forceOpaque,
    })
    const mergedSubmenuContentClassName = classNames(styles.submenuContent, styles['right'], {
        [styles[transparentHeaderClassName]]: isTransparent,
    })

    const handleHidingSubmenu = () => {
        // Force the submenu to hide after the user makes a currency selction
        setShowSubmenu(false)
        setToggled({ toggled: false })
        setForceOpaque(false)
    }

    const onMouseEnter = () => {
        setShowSubmenu(true)
    }
    const onMouseLeave = () => {
        setShowSubmenu(false)
    }

    return (
        <li
            data-testid="header-currency-picker"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={mergedMenuItemLevel1ClassName}
        >
            <MenuItemLink isDesktop={isDesktop} className={mergedMenuItemLinkClassName} level={1} href="">
                <CountryFlag className={styles.flagImg} flag={flag} />
                {selectedCurrency}
            </MenuItemLink>
            {showSubmenu && (
                <div data-testid="desktop-currency-picker" className={styles.submenu}>
                    <div className={mergedSubmenuContentClassName}>
                        <CurrencyPicker onItemSelection={handleHidingSubmenu} />
                    </div>
                </div>
            )}
        </li>
    )
}

export default HeaderCurrencyPicker
