import React, { startTransition, useContext, useEffect, useRef } from 'react'

import { ButtonV2 } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import { getFlagImage } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'
import ErrorMessage from '@/components/shared/error-messages/try-loading/component'
import InternationalizationContext from '@/context/internationalization'

import CurrencyPickerItem from '../currency-picker-item'

import styles from './styles.module.scss'

interface Props {
    active: boolean
    footerElement?: React.ReactNode
    headerElement: React.ReactNode
    title?: string
    onCloseButtonClick: () => void
}

const CurrencyPicker: React.FC<Props> = ({ active, footerElement, headerElement, title, onCloseButtonClick }) => {
    const {
        state: { data },
        selectors: { selectedCurrency },
        dispatch: { selectCurrency },
    } = useContext(InternationalizationContext)
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined)

    const countries = data?.countries ?? []
    const availableCurrencies = data?.availableCurrencies ?? []

    const mergedMenuClassNames = classNames(styles.menu, { [styles.active]: active })

    const handleClick = (selectedOption: string) => {
        startTransition(() => {
            selectCurrency(selectedOption)
        })
        // Slightly delay closing the menu so the user can see that their currency has been selected
        timerRef.current = setTimeout(onCloseButtonClick, 250)
    }

    useEffect(() => {
        // Clean up timer before unmounting
        return () => timerRef.current && clearTimeout(timerRef.current)
    }, [])

    const renderContent = () => {
        if (!countries.length || !availableCurrencies.length) {
            return <ErrorMessage />
        }
        return (
            <>
                <ul data-testid="menu-items" className={styles.menuItems}>
                    {countries.map((country, index) => {
                        if (availableCurrencies.includes(country?.currencyDefault)) {
                            return (
                                <CurrencyPickerItem
                                    key={index}
                                    label={country?.currencyDefault}
                                    flag={getFlagImage(country?.countryCode)}
                                    isSelected={country?.currencyDefault === selectedCurrency}
                                    onClick={handleClick}
                                />
                            )
                        }
                    })}
                </ul>
                {footerElement}
            </>
        )
    }

    return (
        <div data-testid="mobile-currency-picker" className={mergedMenuClassNames}>
            <div className={styles.header}>
                {headerElement}
                <ButtonV2
                    className={styles.closeButton}
                    aria-label="Close Button"
                    data-testid="close-button"
                    icon="close"
                    importance="text"
                    onClick={onCloseButtonClick}
                />
            </div>
            <div className={styles.content}>
                {title && <div className={styles.title}>{title}</div>}
                {renderContent()}
            </div>
        </div>
    )
}

export default CurrencyPicker
