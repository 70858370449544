import React from 'react'

import MenuFooterItem from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-footer-item'
import type { MenuItemLevel2WithSublinks } from '@/components/layouts/main-layout/components/header/components/main-menu/types'

import MenuItemLevel3 from '../menu-item-level-3'

import styles from './styles.module.scss'

interface Props {
    menuItem: MenuItemLevel2WithSublinks
    parentLabel: string
}

const MenuLevel3: React.FC<Props> = ({ menuItem, parentLabel }) => (
    <ul className={styles.menuItems}>
        {menuItem.sublinks.map((subLinkItem, i) => {
            return (
                <MenuItemLevel3
                    divider={subLinkItem.divider || false}
                    parentLabel={`${parentLabel},${menuItem.label}`}
                    key={i}
                    menuItem={subLinkItem}
                />
            )
        })}
        {menuItem.footerLink && <MenuFooterItem footerLink={menuItem.footerLink} />}
    </ul>
)

export default MenuLevel3
