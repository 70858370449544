/**
 w_ - Image width
 h_ - Image hight. This is important since our designs have fixed height
 c_fill - Creates an asset with the exact specified width and height without distorting the asset. This option first scales as much as needed to at least fill both of the specified dimensions. If the requested aspect ratio is different than the original, cropping will occur on the dimension that exceeds the requested size after scaling.
 g_auto - Automatically crops to the most interesting area. The default is g_auto:faces, which sets any detected faces as the focal point, and crops accordingly. If no faces are detected (like MLB logos) then the image is centered
 dpr_auto - Automatically adjusts the image when devices support Retina and HiDPI. Improves image quality, when supported.
 f_auto - Automatically delivers the best image format, supported by the client's browser. If the browser capability cannot be detected, the returned image will be determined by the included file extension. For example, if a modern browser is detected, AVIF formatted image will be returned, greatly reducing the image size.
 q_auto - Automatically determines the best quality, further optimizing the image size and presentation. Does not always work when defined in transformation.

 For more information, please also see https://cloudinary.com/documentation/transformation_reference
 */

export const buildExternalImageUrl = (
    imagePrefix: string,
    imageFilePath: string | undefined,
    width: number,
    height: number,
): string | undefined => {
    if (!imageFilePath) return undefined
    const commonParams = `q_auto,c_fill,w_${width * 2},h_${height * 2}`
    return `${imagePrefix}/if_fc_gte_1/g_auto,${commonParams}/if_else/g_center,${commonParams}/if_end/dpr_auto,f_auto${imageFilePath}`
}
