import React from 'react'

import { Text } from '@vividseats/vivid-ui-kit'

import { PerformerImage, ProductionListSelectorType, Venue } from '@/types/app-types'

import PerformerSuggestion from '../performer-suggestion'
import ProductionSuggestion from '../production-suggestion'
import SearchSuggestionsContainer from '../search-suggestions-container'
import VenueSuggestion from '../venue-suggestion'

import styles from './styles.module.scss'

interface SearchSuggestionsProps {
    performers: PerformerImage[]
    productions: ProductionListSelectorType[]
    venues: Venue[]
    query?: string
    isCenteredInHero?: boolean
    cursorPosition: number
}

const RenderPerformers: React.FC<Pick<SearchSuggestionsProps, 'performers'>> = ({ performers }) => {
    return performers.length ? (
        <div className={styles.suggestionType}>
            <Text as="h3" size="title-md" weight="bold">
                Performers
            </Text>
            {performers.map((performer, i) => {
                return <PerformerSuggestion key={performer.id} performer={performer} index={i} />
            })}
        </div>
    ) : null
}

const RenderProductions: React.FC<Pick<SearchSuggestionsProps, 'productions'>> = ({ productions }) => {
    return productions.length ? (
        <div className={styles.suggestionType}>
            <Text as="h3" size="title-md" weight="bold">
                Events
            </Text>
            {productions.map((production, i) => {
                return <ProductionSuggestion key={production.id} production={production} index={i} />
            })}
        </div>
    ) : null
}

const RenderVenues: React.FC<Pick<SearchSuggestionsProps, 'venues'>> = ({ venues }) => {
    return venues.length ? (
        <div className={styles.suggestionType}>
            <Text as="h3" size="title-md" weight="bold">
                Venues
            </Text>
            {venues.map((venue, i) => {
                return <VenueSuggestion key={venue.id} venue={venue} index={i} />
            })}
        </div>
    ) : null
}

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({ performers, productions, venues, isCenteredInHero }) => {
    return (
        <SearchSuggestionsContainer isCenteredInHero={isCenteredInHero}>
            <RenderPerformers performers={performers} />
            <RenderProductions productions={productions} />
            <RenderVenues venues={venues} />
        </SearchSuggestionsContainer>
    )
}

export default SearchSuggestions
