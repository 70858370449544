import React, { useContext, useEffect, useRef } from 'react'

import { Text } from '@vividseats/vivid-ui-kit'

import { getFlagImage } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'
import ErrorMessage from '@/components/shared/error-messages/try-loading/component'
import InternationalizationContext from '@/context/internationalization'

import CurrencyPickerItem from '../currency-picker-item'

import styles from './styles.module.scss'

interface Props {
    onItemSelection: () => void
}

const CurrencyPicker: React.FC<Props> = ({ onItemSelection }) => {
    const {
        state: { data },
        selectors: { selectedCurrency },
        dispatch: { selectCurrency },
    } = useContext(InternationalizationContext)
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined)

    const countries = data?.countries ?? []
    const availableCurrencies = data?.availableCurrencies ?? []

    const handleClick = (selectedOption: string) => {
        selectCurrency(selectedOption)
        timerRef.current = setTimeout(() => onItemSelection(), 250)
    }

    useEffect(() => {
        // Clean up timer before unmounting
        return () => timerRef.current && clearTimeout(timerRef.current)
    }, [])

    const renderContent = () => {
        if (!countries.length || !availableCurrencies.length) {
            return (
                <div className={styles.currencyList}>
                    <ErrorMessage />
                </div>
            )
        }
        return (
            <>
                <ul data-testid="menu-items" className={styles.currencyList}>
                    {countries.map((country, index) => {
                        if (availableCurrencies.includes(country?.currencyDefault)) {
                            return (
                                <CurrencyPickerItem
                                    key={index}
                                    label={country.currencyDefault}
                                    flag={getFlagImage(country.countryCode)}
                                    isSelected={country.currencyDefault === selectedCurrency}
                                    onClick={handleClick}
                                />
                            )
                        }
                    })}
                </ul>
                <div className={styles.divider} />
                <div className={styles.disclaimerWrapper}>
                    <Text altFont weight="regular" className={styles.disclaimer}>
                        Prices in the selected currency are informational only.
                    </Text>
                    <Text altFont weight="regular" className={styles.disclaimer}>
                        You will be charged in USD.
                    </Text>
                </div>
            </>
        )
    }

    return (
        <div className={styles.listContainer}>
            <Text className={styles.currencyTitle} altFont weight="bold">
                Choose your display currency
            </Text>
            {renderContent()}
        </div>
    )
}

export default CurrencyPicker
