import { useEffect, useState } from 'react'

import throttle from 'lodash/throttle'

import { STYLES } from '@/constants'

import type { Size } from './types'
import { DeviceType } from './types'

export const isTypeDesktop = (type: DeviceType): boolean => {
    return type !== DeviceType.MOBILE
}

const useWindowSize = (): Size => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<Size>({
        width: 0,
        height: 0,
        type: DeviceType.DESKTOP_XL,
    })

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = throttle(() => {
            // Set window width/height and type to state
            const type =
                window.innerWidth < STYLES.breakpoint.tablet
                    ? DeviceType.MOBILE
                    : window.innerWidth < STYLES.breakpoint.desktop
                      ? DeviceType.TABLET
                      : window.innerWidth < STYLES.breakpoint.desktopXl
                        ? DeviceType.DESKTOP
                        : DeviceType.DESKTOP_XL
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                type,
            })
        }, 100)

        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount
    return windowSize
}

export default useWindowSize
