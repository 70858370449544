import React, { HTMLAttributes } from 'react'

import { FullStory, isInitialized } from '@fullstory/browser'
import { IconType, Link } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import NextLink from 'next/link'

import styles from './styles.module.scss'

interface Props extends HTMLAttributes<HTMLElement> {
    block?: boolean
    className?: string
    href: string
    icon?: IconType
    level: 1 | 2 | 3
    strong?: boolean
    withBorder?: boolean
    withSublinks?: boolean
    id?: string
    isDesktop?: boolean
    label?: string
    onClick?: (e: React.MouseEvent) => void
    onMouseOver?: (e: React.MouseEvent) => void
}

const MenuItemLink: React.FC<Props> = ({
    block,
    children,
    className,
    href,
    icon,
    level,
    label,
    id,
    strong = false,
    withBorder = false,
    withSublinks = false,
    isDesktop,
    onClick,
    onMouseOver,
    ...htmlAttributes
}) => {
    const mergedClassName = classNames(styles.menuItemLink, className, styles[`level${level}`], {
        [styles.strong]: strong,
        [styles.withBorder]: withBorder,
        [styles.withIcon]: !!icon,
        [styles.justIcon]: !children,
        [styles.withSublinks]: withSublinks,
    })
    return (
        <Link
            LinkComponent={NextLink}
            id={id}
            block={block}
            className={mergedClassName}
            href={href}
            icon={icon}
            importance="tertiary"
            isExternal
            legacyBehavior
            {...(isDesktop === false && !href && level === 1 ? { 'data-testid': 'mobile-menu-trending' } : {})}
            {...(isDesktop === false && ['/theater/', '/theater'].includes(href)
                ? { 'data-testid': 'mobile-menu-theater' }
                : {})}
            {...(isDesktop === false && ['/concerts/', '/concerts'].includes(href)
                ? { 'data-testid': 'mobile-menu-concerts' }
                : {})}
            {...(isDesktop === false && ['/sports/', '/sports'].includes(href)
                ? { 'data-testid': 'mobile-menu-sports' }
                : {})}
            {...htmlAttributes}
            onClick={(e) => {
                onClick?.(e)
                if (isDesktop === false && label && isInitialized()) {
                    FullStory('trackEvent', {
                        name: 'Mobile Menu Item Clicked',
                        properties: {
                            element_level: level.toString(),
                            element_label: label,
                        },
                    })
                }
            }}
            onMouseOver={(e) => {
                onMouseOver?.(e)
                if (isDesktop !== false && label && isInitialized()) {
                    FullStory('trackEvent', {
                        name: 'Menu Item Hovered',
                        properties: {
                            element_level: level.toString(),
                            element_label: label,
                        },
                    })
                }
            }}
            aria-label={label}
        >
            {children}
        </Link>
    )
}

export default MenuItemLink
