import React from 'react'

import classNames from 'classnames'

import MenuItemLink from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-item-link'
import type { MenuItemLevel2 as MenuItemLevel2Type } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import { isMenuItemLevel2WithSublinks } from '@/components/layouts/main-layout/components/header/components/main-menu/types'
import { getMenuAriaLabel } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'

import MenuLevel3 from '../menu-level-3'

import styles from './styles.module.scss'

interface Props {
    parentLabel: string
    divider: boolean
    menuItem: MenuItemLevel2Type
    width: 'narrow' | 'wide'
    hoverFirstRow?: boolean
    onHover: (id: MenuItemLevel2Type, over: boolean) => void
}

const MenuItemLevel2: React.FC<Props> = ({ divider, menuItem, width, parentLabel, hoverFirstRow = false, onHover }) => {
    const withSublinks = isMenuItemLevel2WithSublinks(menuItem)

    const mergedMenuItemLevel2ClassName = classNames(styles.menuItemLevel2, styles[width], styles.withSublinks, {
        [styles.hoverFirstRow]: hoverFirstRow,
        [styles.firstWithoutSublink]: divider,
    })

    // If there are sublinks, add hover handlers from parent
    const hoverHandlers = withSublinks && {
        onMouseOver: () => onHover(menuItem, true),
        onMouseOut: () => onHover(menuItem, false),
    }

    return (
        <li
            className={mergedMenuItemLevel2ClassName}
            {...hoverHandlers}
            data-testid={`desktop-nav-menu-item-level-2${withSublinks ? '-with-sublinks' : ''}`}
        >
            {divider && <div className={styles.divider} />}
            <MenuItemLink
                block
                className={styles.menuItemLink}
                href={menuItem.url}
                level={2}
                strong={menuItem.strong}
                withSublinks={withSublinks}
                withBorder={menuItem.withBorder}
                label={getMenuAriaLabel(menuItem.label, parentLabel)}
            >
                {menuItem.label}
            </MenuItemLink>
            {isMenuItemLevel2WithSublinks(menuItem) && (
                <div className={styles.submenu}>
                    <MenuLevel3 menuItem={menuItem} parentLabel={parentLabel} />
                </div>
            )}
        </li>
    )
}

export default MenuItemLevel2
