import React, { useMemo } from 'react'

import { Box, Text, Link } from '@vividseats/vivid-ui-kit'
import classnames from 'classnames'
import NextLink from 'next/link'

import DOADisclaimer from '@/components/shared/doa-disclaimer'
import SeatingRestrictionsDisclaimer from '@/components/shared/seating-restrictions-disclaimer/component'
import { SEATING_RESTRICTION_PLACEMENT } from '@/components/shared/seating-restrictions-disclaimer/types'
import { PAID_SEARCH_DISCLAIMER_TEXT, PATHS, SECONDARY_MARKETPLACE_DISCLAIMER_TEXT, STYLES } from '@/constants'
import useWindowSize from '@/hooks/use-window-size'

import useMarketplaceDisclaimer from '../../hooks/use-marketplace-disclaimer'
import { UKDisclaimerTypes } from '../../hooks/use-marketplace-disclaimer/constants'

import styles from './styles.module.scss'
import type { DisclaimerTypeMap } from './types'

export const DesktopDisclaimerTypeMap: DisclaimerTypeMap = {
    buyerGuarantee: (
        <>
            Tickets you can trust: 100 million sold,&nbsp;
            <Link href={PATHS.GUARANTEE} target="_blank" className={styles.buyerGuaranteeDisclaimer}>
                100% Buyer Guarantee
            </Link>
            . Learn More.
        </>
    ),
    secondaryMarketplace: SECONDARY_MARKETPLACE_DISCLAIMER_TEXT,
    paidSearch: PAID_SEARCH_DISCLAIMER_TEXT,
    DOA: <DOADisclaimer className={styles.doaContent} />,
    seatingRestrictions: (
        <SeatingRestrictionsDisclaimer
            className={styles.seatingRestrictionsDisclaimer}
            placement={SEATING_RESTRICTION_PLACEMENT.BANNER}
        />
    ),
}

export const MobileDisclaimerTypeMap: DisclaimerTypeMap = {
    buyerGuarantee: (
        <>
            100 million sold,&nbsp;
            <Link href={PATHS.GUARANTEE} target="_self" className={styles.buyerGuaranteeDisclaimer}>
                100% Buyer Guarantee
            </Link>
            . Learn More.
        </>
    ),
    secondaryMarketplace: SECONDARY_MARKETPLACE_DISCLAIMER_TEXT,
    paidSearch: PAID_SEARCH_DISCLAIMER_TEXT,
    DOA: (
        <>
            Resale of tickets are prohibited. Vivid Seats&apos;{' '}
            <Link
                LinkComponent={NextLink}
                href={PATHS.GUARANTEE}
                target="_blank"
                className={styles.buyerGuaranteeDisclaimer}
                data-testid="doa-disclaimer-link"
                legacyBehavior
            >
                Buyer Guarantee
            </Link>{' '}
            protects you against entry refusal.
        </>
    ),
    seatingRestrictions: (
        <SeatingRestrictionsDisclaimer
            className={styles.seatingRestrictionsDisclaimer}
            placement={SEATING_RESTRICTION_PLACEMENT.BANNER}
        />
    ),
}

interface Props {
    mobileOnlyPaidSearch?: boolean
}

const MarketplaceDisclaimer: React.FC<Props> = ({ mobileOnlyPaidSearch }) => {
    const windowSize = useWindowSize()
    // using react-responsive doesn't work with SSR - https://www.joshwcomeau.com/react/the-perils-of-rehydration/
    const isDesktop = !!windowSize.width && windowSize.width > STYLES.breakpoint.desktop

    const { hasDisclaimers, desktopDisclaimerTypes, mobileDisclaimerTypes } = useMarketplaceDisclaimer()

    // This sets banner height to one of the fixed values (see _tokens.scss) depending on the number of messages in the banner
    // Fixed height is required by ticket details component to absolutely position the sliding ticket information pane so that it does not overlap with the banner and the header
    // See src/components/pages/production/components/ticket-details/component.tsx and src/components/pages/production/components/ticket-details/styles.module.scss
    const tabletAndDesktopClassName = classnames(styles.disclaimer, {
        [styles.disclaimer1Line]: desktopDisclaimerTypes.length === 1,
        [styles.disclaimer2Lines]: desktopDisclaimerTypes.length === 2,
        [styles.disclaimer3Lines]: desktopDisclaimerTypes.length === 3,
        [styles.doaDisclaimer]: desktopDisclaimerTypes.includes('DOA'),
    })

    const mobileClassName = classnames(styles.disclaimer, {
        [styles.doaDisclaimer]: mobileDisclaimerTypes.includes('DOA'),
    })

    const mobileDisclaimers = useMemo(() => {
        if (mobileDisclaimerTypes.some((disclaimer) => UKDisclaimerTypes.includes(disclaimer)))
            return mobileDisclaimerTypes.filter((disclaimer) => UKDisclaimerTypes.includes(disclaimer))
        else if (mobileOnlyPaidSearch) return mobileDisclaimerTypes.filter((disclaimer) => disclaimer === 'paidSearch')
        else return mobileDisclaimerTypes
    }, [mobileOnlyPaidSearch, mobileDisclaimerTypes])

    if (!isDesktop && mobileDisclaimers.length === 0) {
        return null
    }

    return hasDisclaimers ? (
        <div id="marketplace-disclaimers">
            <Box
                desktopDisplay="flex"
                tabletDisplay="flex"
                mobileDisplay="none"
                desktopFlexDirection="column"
                tabletFlexDirection="column"
                desktopAlign="center"
                tabletAlign="center"
                desktopJustify="center"
                tabletJustify="center"
                className={tabletAndDesktopClassName}
            >
                {desktopDisclaimerTypes.map((desktopDisclaimerType) => (
                    <div key={desktopDisclaimerType} data-testid="marketplace-disclaimer-desktop">
                        <Text size="sm" alignment="center" altFont>
                            {DesktopDisclaimerTypeMap[desktopDisclaimerType]}
                        </Text>
                    </div>
                ))}
            </Box>
            <Box
                desktopDisplay="none"
                tabletDisplay="none"
                mobileDisplay="flex"
                mobileFlexDirection="column"
                mobileAlign="center"
                mobileJustify="center"
                mobilePaddingTop={4}
                mobilePaddingBottom={4}
                className={mobileClassName}
            >
                {mobileDisclaimers.map((mobileDisclaimerType) => (
                    <div key={mobileDisclaimerType} data-testid="marketplace-disclaimer-mobile">
                        <Text size="sm" alignment="center" altFont>
                            {MobileDisclaimerTypeMap[mobileDisclaimerType]}
                        </Text>
                    </div>
                ))}
            </Box>
        </div>
    ) : null
}

export default MarketplaceDisclaimer
