import React from 'react'

import { Text, Box, ArrowForwardIcon } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface Props {
    className?: {
        buttonText?: string
        buttonArrow?: string
    }
}

const ShopButton: React.FC<Props> = ({ className }) => {
    return (
        <Box className={styles.shopButton}>
            <Text altFont size="md" weight="regular-bold" className={classNames(styles.text, className?.buttonText)}>
                Shop
            </Text>
            <ArrowForwardIcon
                className={classNames(styles.arrow, className?.buttonArrow)}
                color="currentColor"
                fontSize="1rem"
            />
        </Box>
    )
}

export default ShopButton
