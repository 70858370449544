import { COUNTRY_FLAG_IMAGE_PATH } from '@/constants'

export const getMenuAriaLabel = (label: string, parentLabel?: string): string => {
    return parentLabel ? `${parentLabel?.concat(',')}${label}` : label
}

export const isCurrencyMenuItem = (label: string): boolean => {
    return label === 'Currency'
}

export const getFlagEmoji = (currencyCode: string): string => {
    const countryCode = currencyCode.substring(0, 2)
    const flagOffset = 127397

    return String.fromCodePoint(...countryCode.split('').map((char: string) => char.charCodeAt(0) + flagOffset))
}

export const getFlagImage = (countryCode: string): string => {
    if (!countryCode) return ''

    return `${COUNTRY_FLAG_IMAGE_PATH}/${countryCode}.svg`
}
