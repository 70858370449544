import { normalizeSecureImages } from '@/context/utils/image-path'
import type { ImageSelectorType } from '@/types/app-types'

// TODO replace selector places
// Please use the selector
export const getResponsiveImages = (defaultImages: ImageSelectorType, overrideImageUrl?: string) => {
    // Get responsive image URLs
    const imageUrl: string | undefined = normalizeSecureImages(overrideImageUrl)
    const desktopUrl = imageUrl || defaultImages.desktop
    const mobileUrl = imageUrl || defaultImages.mobile

    return {
        desktop: desktopUrl ? `${desktopUrl}?dimensions=840x336` : undefined,
        mobile: mobileUrl ? `${mobileUrl}?dimensions=735x288` : undefined,
        thumbnail: defaultImages.thumbnail,
        externalFilePath: defaultImages.external,
    }
}
