export const normalizeSecureImages = (imagePath: string | undefined): string | undefined => {
    if (!imagePath) {
        return undefined
    }

    if (imagePath.startsWith('/v3')) {
        imagePath = `${process.env.NEXT_PUBLIC_LEGACY_WEB_URL}${imagePath}`
    } else if (process.env.NEXT_PUBLIC_CLOUDFRONT_URL) {
        imagePath = imagePath.replace(
            new RegExp(`${process.env.NEXT_PUBLIC_CDN_URL}|${process.env.NEXT_PUBLIC_ASSETS_DOMAIN}`, 'g'),
            process.env.NEXT_PUBLIC_CLOUDFRONT_URL,
        )
    }

    return imagePath.replace(/^(https:|http:)/g, '')
}
