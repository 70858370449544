import { getMetadata } from '@/components/pages/production/utils'
import {
    CategoryType,
    CATEGORY_TAGS,
    STATUS_EVENT,
    ATTENDANCE_MODE_EVENT,
    AttendanceMode,
} from '@/components/shared/jsonld/constants'
import { URLS } from '@/constants'
import type { CatalogAsset, JsonLdItem, Performer, ProductionDetail, Venue } from '@/types/app-types'
import { formatDateForLDJSON, fromLocalDateString } from '@/utils/dates'

export interface ProductionItem {
    id: number
    localDate: string
    utcDate: string
    name: string
    venue: Venue
    maxPrice: number
    minPrice: number
    listingCount: number
    ticketCount: number
    categoryId: number
    subCategoryId: number
    dateTbd: boolean
    timeTbd: boolean
    ifNecessary: boolean
    organicUrl?: string
    webPath?: string
    performers: Performer[]
    assets?: CatalogAsset[]
    formattedDate?: {
        date: string
        time?: string
    }
    productionsCount?: number
    eventId?: number
    hidden: boolean
}

interface SportsData {
    homeTeam?: PerformerLDJSON
    awayTeam?: PerformerLDJSON
}

interface PerformerLDJSON {
    name: string
    '@type': string
    url?: string
}

export const getProductionJson = ({ ...production }: ProductionItem): JsonLdItem => {
    const dateObject = fromLocalDateString(production.localDate)
    const startDate = dateObject.format('YYYY-MM-DDTHH:mm:ss').toString()
    const endDate = dateObject.format('YYYY-MM-DD').toString()
    const { name, webPath, venue, ticketCount, minPrice, maxPrice, categoryId, performers: apiPerformers } = production

    const URL = webPath ? `${URLS.VIVIDSEATS}${webPath}?utm_medium=organic&utm_source=google_eventsearch` : undefined

    const geo = {
        '@type': 'GeoCoordinates',
        latitude: venue.latitude,
        longitude: venue.longitude,
    }

    const regionURL = venue.regionId
        ? `${URLS.VIVIDSEATS}/region/${venue.countryCode}/${venue.state}/${venue.city}-tickets/${venue.regionId}`.toLowerCase()
        : undefined
    const venueURL = venue.webPath ? `${URLS.VIVIDSEATS}${venue.webPath}` : undefined

    const location = {
        '@context': 'http://schema.org',
        '@type': 'Place',
        name: venue.name,
        address: {
            '@type': 'PostalAddress',
            streetAddress: `${venue.address1}${venue.address2 ? ` ${venue.address2}` : ''}`,
            addressCountry: {
                '@type': 'Country',
                name: venue.countryCode,
            },
            postalCode: venue.postalCode,
            addressLocality: venue.city,
            addressRegion: venue.state,
            ...(regionURL && { url: regionURL }),
        },
        geo,
        ...(venueURL && { url: venueURL }),
    }

    const offers = {
        '@type': 'AggregateOffer',
        url: URL,
        priceCurrency: 'USD',
        availability: `https://schema.org/${ticketCount > 0 ? 'InStock' : 'OutOfStock'}`,
        lowPrice: minPrice,
        highPrice: maxPrice,
        price: minPrice,
        validFrom: formatDateForLDJSON(),
        validThrough: startDate,
    }

    // if the category can't be found, don't blow up -- even though this should not be possible
    if (!categoryId || !CATEGORY_TAGS[categoryId as CategoryType]) return { '@context': 'http://schema.org' }
    const { type, performerType } = CATEGORY_TAGS[categoryId as CategoryType]

    const { description, image } = getMetadata(production as ProductionDetail)

    const performer = apiPerformers.map(({ name, organicUrl, webPath }) => {
        return {
            name,
            '@type': performerType,
            // Prioritize webPath over the deprecated organicUrl
            url: `${webPath ? webPath : organicUrl}`,
        }
    })
    let additionalSportsPerformer = {}
    if (type === CATEGORY_TAGS[CategoryType.SPORTS].type) {
        additionalSportsPerformer = apiPerformers.reduce((acc, { name, organicUrl, webPath, master }) => {
            acc[master ? 'homeTeam' : 'awayTeam'] = {
                name,
                '@type': performerType,
                // Prioritize webPath over the deprecated organicUrl
                url: `${webPath ? webPath : organicUrl}`,
            }
            return acc
        }, {} as SportsData)
    }

    const status = 'scheduled' // TODO replace once we have the data
    const eventStatus = STATUS_EVENT[status as string]

    // Productions are always offline. Virtual events fall under /streams
    const eventAttendanceMode = ATTENDANCE_MODE_EVENT[AttendanceMode.OFFLINE]

    return {
        '@context': 'http://schema.org',
        name: `${venue.city} ${venue.state || venue.countryCode} - ${name}`,
        eventStatus,
        description,
        eventAttendanceMode,
        URL,
        startDate,
        endDate,
        location,
        offers,
        '@type': type,
        performer,
        image,
        ...additionalSportsPerformer,
    }
}
