import type { IconType } from '@vividseats/vivid-ui-kit'

import type { MenuItem, MenuItemLevel1, MenuItemLevel2, MenuItemLevel3 } from '../types'
import { isMenuItemLevel1, isMenuItemLevel1Or2 } from '../types'

export const processMenuItemsLevel1: (originalMenuItemsLevel1: MenuItemLevel1[]) => MenuItemLevel1[] = (
    originalMenuItemsLevel1,
) => {
    const newMenuItemsLevel1 = originalMenuItemsLevel1.reduce(
        (newMenuItemsLevel1: MenuItemLevel1[], originalMenuItemLevel1) => {
            newMenuItemsLevel1.push(...processMenuItemLevel1(originalMenuItemLevel1))
            return newMenuItemsLevel1
        },
        [],
    )
    return newMenuItemsLevel1
}

const processMenuItemLevel1 = (originalMenuItemLevel1: MenuItemLevel1) => {
    const newMenuItemsLevel1: MenuItemLevel1[] = []
    if (originalMenuItemLevel1.shouldRenderChildrenInUpperLevel) {
        const transformedMenuItems = transformMenuItemLevel1SublinksIntoMenuItemsLevel1(originalMenuItemLevel1)
        if (transformedMenuItems) {
            newMenuItemsLevel1.push(...transformedMenuItems)
        }
    } else {
        newMenuItemsLevel1.push(originalMenuItemLevel1)
    }
    return newMenuItemsLevel1.map(processMenuItemLevel1Sublinks)
}

const processMenuItemLevel1Sublinks = (originalMenuItemLevel1: MenuItemLevel1) => {
    if (originalMenuItemLevel1.sublinks) {
        originalMenuItemLevel1.sublinks = processMenuItemsLevel2(originalMenuItemLevel1.sublinks)
    }
    return originalMenuItemLevel1
}

const processMenuItemsLevel2 = (originalMenuItemsLevel2: MenuItemLevel2[]) => {
    const newMenuItemsLevel2 = originalMenuItemsLevel2.reduce(
        (newMenuItemsLevel2: MenuItemLevel2[], originalMenuItemLevel2) => {
            newMenuItemsLevel2.push(...processMenuItemLevel2(originalMenuItemLevel2))
            return newMenuItemsLevel2
        },
        [],
    )
    return newMenuItemsLevel2
}

const processMenuItemLevel2 = (originalMenuItemLevel2: MenuItemLevel2) => {
    const newMenuItemsLevel2: MenuItemLevel2[] = []
    if (originalMenuItemLevel2.shouldRenderChildrenInUpperLevel) {
        const transformedMenuItems = transformMenuItemLevel2SublinksIntoMenuItemsLevel2(originalMenuItemLevel2)
        if (transformedMenuItems) {
            newMenuItemsLevel2.push(...transformedMenuItems)
        }
    } else {
        newMenuItemsLevel2.push(originalMenuItemLevel2)
    }
    return newMenuItemsLevel2
}

const transformMenuItemLevel1SublinksIntoMenuItemsLevel1 = (originalMenuItemLevel1: MenuItemLevel1) => {
    if (!originalMenuItemLevel1.sublinks || !originalMenuItemLevel1.sublinks.length) return undefined
    const newMenuItemsLevel1: MenuItemLevel1[] = originalMenuItemLevel1.sublinks
        .map(transformMenuItemLevel2IntoMenuItemLevel1)
        .map((newMenuItemLevel1) => {
            if (originalMenuItemLevel1.side) newMenuItemLevel1.side = originalMenuItemLevel1.side
            if (originalMenuItemLevel1.shouldRenderOnlyWhenAuthenticated)
                newMenuItemLevel1.shouldRenderOnlyWhenAuthenticated =
                    originalMenuItemLevel1.shouldRenderOnlyWhenAuthenticated
            if (originalMenuItemLevel1.shouldRenderOnlyWhenNotAuthenticated)
                newMenuItemLevel1.shouldRenderOnlyWhenNotAuthenticated =
                    originalMenuItemLevel1.shouldRenderOnlyWhenNotAuthenticated
            return newMenuItemLevel1
        })
    if (originalMenuItemLevel1.divider) {
        newMenuItemsLevel1[0].divider = true
    }
    return newMenuItemsLevel1
}

const transformMenuItemLevel2IntoMenuItemLevel1 = (originalMenuItemLevel2: MenuItemLevel2) => {
    const newMenuItemLevel1: MenuItemLevel1 = {
        level: 1,
        url: originalMenuItemLevel2.url,
        label: originalMenuItemLevel2.label,
    }
    const sublinks = transformMenuItemLevel2SublinksIntoMenuItemsLevel2(originalMenuItemLevel2)

    if (originalMenuItemLevel2.id) newMenuItemLevel1.id = originalMenuItemLevel2.id
    if (sublinks) newMenuItemLevel1.sublinks = sublinks
    if (originalMenuItemLevel2.divider) newMenuItemLevel1.divider = originalMenuItemLevel2.divider
    if (originalMenuItemLevel2.strong) newMenuItemLevel1.strong = originalMenuItemLevel2.strong
    if (originalMenuItemLevel2.withBorder) newMenuItemLevel1.withBorder = originalMenuItemLevel2.withBorder
    if (originalMenuItemLevel2.shouldRenderOnlyWhenAuthenticated)
        newMenuItemLevel1.shouldRenderOnlyWhenAuthenticated = originalMenuItemLevel2.shouldRenderOnlyWhenAuthenticated
    if (originalMenuItemLevel2.shouldRenderOnlyWhenNotAuthenticated)
        newMenuItemLevel1.shouldRenderOnlyWhenNotAuthenticated =
            originalMenuItemLevel2.shouldRenderOnlyWhenNotAuthenticated
    if (originalMenuItemLevel2.shouldRenderChildrenInUpperLevel)
        newMenuItemLevel1.shouldRenderChildrenInUpperLevel = originalMenuItemLevel2.shouldRenderChildrenInUpperLevel

    return newMenuItemLevel1
}

const transformMenuItemLevel2SublinksIntoMenuItemsLevel2 = (originalMenuItemLevel2: MenuItemLevel2) => {
    if (!originalMenuItemLevel2.sublinks || !originalMenuItemLevel2.sublinks.length) return undefined
    const newMenuItemsLevel2: MenuItemLevel2[] = originalMenuItemLevel2.sublinks.map(
        transformMenuItemLevel3IntoMenuItemLevel2,
    )
    if (originalMenuItemLevel2.divider) {
        newMenuItemsLevel2[0].divider = true
    }
    return newMenuItemsLevel2
}

const transformMenuItemLevel3IntoMenuItemLevel2 = (menuItemLevel3: MenuItemLevel3) => {
    const menuItemLevel2: MenuItemLevel2 = { ...menuItemLevel3, level: 2 }
    return menuItemLevel2
}

export const addOptionalPropertiesToMenuItems = (
    menuItems: MenuItem[],
    shouldRenderChildrenInUpperLevelIds: string[],
    dividerIds: string[],
    withBorderIds: string[],
    strongIds: string[],
    shouldRenderOnlyWhenAuthenticatedIds: string[],
    shouldRenderOnlyWhenNotAuthenticatedIds: string[],
    narrowSubmenuIds: string[],
    topSideIds: string[],
    rightSideIds: string[],
    commonIconsIds: Map<IconType, string[]>,
): MenuItem[] => {
    return menuItems.map((menuItem) => {
        const transformedMenuItem: MenuItem = menuItem
        if (menuItem.id) {
            if (isMenuItemLevel1Or2(menuItem) && shouldRenderChildrenInUpperLevelIds.includes(menuItem.id)) {
                ;(transformedMenuItem as MenuItemLevel1 | MenuItemLevel2).shouldRenderChildrenInUpperLevel = true
            }
            if (dividerIds.includes(menuItem.id)) {
                transformedMenuItem.divider = true
            }
            if (withBorderIds.includes(menuItem.id)) {
                transformedMenuItem.withBorder = true
            }
            if (strongIds.includes(menuItem.id)) {
                transformedMenuItem.strong = true
            }
            if (shouldRenderOnlyWhenAuthenticatedIds.includes(menuItem.id)) {
                transformedMenuItem.shouldRenderOnlyWhenAuthenticated = true
            }
            if (shouldRenderOnlyWhenNotAuthenticatedIds.includes(menuItem.id)) {
                transformedMenuItem.shouldRenderOnlyWhenNotAuthenticated = true
            }
            if (isMenuItemLevel1(menuItem) && narrowSubmenuIds.includes(menuItem.id)) {
                ;(transformedMenuItem as MenuItemLevel1).narrowSubmenu = true
            }
            if (isMenuItemLevel1(menuItem) && topSideIds.includes(menuItem.id)) {
                ;(transformedMenuItem as MenuItemLevel1).side = 'top'
            }
            if (isMenuItemLevel1(menuItem) && rightSideIds.includes(menuItem.id)) {
                ;(transformedMenuItem as MenuItemLevel1).side = 'right'
            }
            if (isMenuItemLevel1(menuItem)) {
                commonIconsIds.forEach((iconIds, icon) => {
                    if (menuItem.id && iconIds.includes(menuItem.id)) {
                        transformedMenuItem.icon = icon
                    }
                })
            }
        }

        if (isMenuItemLevel1Or2(menuItem) && menuItem.sublinks) {
            addOptionalPropertiesToMenuItems(
                menuItem.sublinks,
                shouldRenderChildrenInUpperLevelIds,
                dividerIds,
                withBorderIds,
                strongIds,
                shouldRenderOnlyWhenAuthenticatedIds,
                shouldRenderOnlyWhenNotAuthenticatedIds,
                narrowSubmenuIds,
                topSideIds,
                rightSideIds,
                commonIconsIds,
            )
        }

        return transformedMenuItem
    })
}
