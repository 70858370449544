// variant of src/components/layouts/main-layout/components/header/component.tsx

import React, { $FC, useContext, useEffect } from 'react'

import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk'
import { Box, SectionContainer, VividLogo } from '@vividseats/vivid-ui-kit'
import { LOGO_DARK_TEXT_COLOR } from '@vividseats/vivid-ui-kit/dist/components/vivid-logo/component'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'

import MainMenu, { MainMenuProvider } from '@/components/layouts/main-layout/components/header/components/main-menu'
import MobileNavContainer from '@/components/layouts/main-layout/components/header/components/main-menu/components/mobile/components/nav-container'
import MarketplaceDisclaimer from '@/components/layouts/main-layout/components/marketplace-disclaimer'
import SearchBar from '@/components/shared/searchbar'
import headerStyleContext from '@/context/header'
import { useHomeLinkWithUrlAppend } from '@/hooks/use-home-link-with-url-append'
import { TRANSITION_PERCENTAGE_STEP } from '@/hooks/use-scroll-percentage'
import useWindowSize, { isTypeDesktop } from '@/hooks/use-window-size'
import { OPTIMIZELY_EVENT } from '@/optimizely/constants'
import { useTrendingCategoryFeature } from '@/optimizely/features/trending-category-feature'
import { firstPageAtom } from '@/recoil/atoms/isFirstPageInSessionAtom'

import styles from './styles.module.scss'

export interface StickySearchBarHeaderProps extends WithOptimizelyProps {
    mobileOnlyPaidSearchDisclaimer?: boolean
    narrow?: boolean // If additional left and right padding should be added to header content
    withTransparentHero?: boolean
    className?: string
}

const StickySearchBarHeader: $FC<StickySearchBarHeaderProps> = ({
    mobileOnlyPaidSearchDisclaimer,
    narrow,
    className,
    children,
    withTransparentHero,
    optimizely,
}) => {
    const {
        state: { transparentHeaderClassName, forceOpaque, scrollPercentage, isTransparent },
        dispatch: { setForceOpaque },
    } = useContext(headerStyleContext)

    const homeLink = useHomeLinkWithUrlAppend()
    const [isTrendingCategoryEnabled] = useTrendingCategoryFeature()

    const { pathname } = useRouter()
    const isHome = pathname === '/home'
    const { type } = useWindowSize()
    const isDesktop = isTypeDesktop(type)
    const isSticky = !!scrollPercentage && scrollPercentage >= TRANSITION_PERCENTAGE_STEP

    const [firstPage, setFirstPage] = useRecoilState(firstPageAtom)
    // to track the first page in the session
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!firstPage) {
                setFirstPage({ url: window.location.href })
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const mergedHeaderClassName = classNames(styles.header, className, {
        [styles.headerTransition]: isTransparent,
        [styles[transparentHeaderClassName]]: isTransparent,
        [styles.transparentHeader100]: forceOpaque,
        [styles.withTransparentHero]: withTransparentHero,
        [styles.headerHomeSticky]: isHome && isSticky,
        [styles.trendingNavbar]: isTrendingCategoryEnabled,
    })

    const mergedSectionContainerClassName = classNames(styles.sectionContainer, {
        [styles.narrow]: narrow,
        [styles.sectionContainerHomeSticky]: isHome && isSticky,
    })

    const mergedLogoLinkClassName = classNames(styles.logoLink, {
        [styles.logoLinkHomeSticky]: isHome && isSticky,
    })

    const mergedSearchbarContainerClassName = classNames(styles.searchbarContainer, {
        [styles.searchbarContainerHome]: isHome,
    })

    // Set logo's svg text color based on scroll percentage if using tranpsarent mode
    let logoColor
    if (isTransparent) {
        logoColor = (scrollPercentage && scrollPercentage >= 40) || forceOpaque ? LOGO_DARK_TEXT_COLOR : 'white'
    }
    const mobileLogoColorHome = 'white'
    const mobileLogoColor = LOGO_DARK_TEXT_COLOR

    // If there are no children on the homepage, render an empty div so the logo is centered.
    let headerChildContent = null
    if (children) {
        headerChildContent = children
    } else {
        if (isHome) {
            headerChildContent = <div className={styles.spacer} data-testid="homepage-empty-div" />
        }
    }

    const handleClick = () => {
        optimizely?.track(OPTIMIZELY_EVENT.searchBarClick)
    }

    const shouldRenderSearchbar = isDesktop || !isHome || isSticky

    return (
        <MainMenuProvider>
            <MarketplaceDisclaimer mobileOnlyPaidSearch={mobileOnlyPaidSearchDisclaimer} />

            {!mobileOnlyPaidSearchDisclaimer && (
                <>
                    <header
                        className={mergedHeaderClassName}
                        data-testid="sticky-nav-header"
                        data-test-id="sticky-nav-header"
                        onMouseEnter={() => {
                            setForceOpaque(true)
                        }}
                        onMouseLeave={() => {
                            setForceOpaque(false)
                        }}
                    >
                        <SectionContainer className={mergedSectionContainerClassName} fluid>
                            <MainMenu className={styles.mainMenu} isCenterSearch={false} />
                            <Link href={homeLink} className={mergedLogoLinkClassName} aria-label="Link to homepage">
                                <Box mobileDisplay="block" desktopDisplay="none" tabletDisplay="none">
                                    <VividLogo
                                        color={isHome ? mobileLogoColorHome : mobileLogoColor}
                                        showTriangle={false}
                                    />
                                </Box>
                                <Box mobileDisplay="none" desktopDisplay="none" tabletDisplay="block">
                                    <VividLogo color={isHome ? mobileLogoColorHome : mobileLogoColor} />
                                </Box>
                                <Box desktopDisplay="block" mobileDisplay="none" tabletDisplay="none">
                                    <VividLogo color={logoColor} />
                                </Box>
                            </Link>
                            {shouldRenderSearchbar && (
                                <Box className={mergedSearchbarContainerClassName} onClick={handleClick}>
                                    <SearchBar />
                                </Box>
                            )}
                            {headerChildContent}
                        </SectionContainer>
                    </header>
                    <MobileNavContainer />
                </>
            )}
        </MainMenuProvider>
    )
}

export default withOptimizely(StickySearchBarHeader)
