import pickBy from 'lodash/pickBy'
import { createSelector } from 'reselect'

import { CONTENT_TAGS_TO_SEPARATE, VISIBLE_BY_DEFAULT_PRODUCTION_LISTINGS_LIMIT } from '@/constants'
import { getProductionJson } from '@/context/utils/jsonld'
import { ItemSelector, productionListSelectorCreator } from '@/context/utils/production'
import type { JsonLdItem, Performer, Production, ProductionListSelectorType } from '@/types/app-types'
import { formatProductionDates, formatVenueLocation } from '@/utils/production'

import { CALENDAR_PARAM, QUERYPARAM, TAG_FILTER_OPTIONS } from './constants'
import type {
    FilterMappers,
    ProductionListData,
    ProductionListParams,
    ProductionListParamValue,
    ProductionListState,
} from './types'
import { isValuePresent } from './utils'

export const productionsSelector = createSelector(
    [({ data }: ProductionListState) => data],
    (productionListData: ProductionListData | undefined): Production[] => {
        return productionListData?.items ?? []
    },
)

export const composedProductionsSelector = productionListSelectorCreator(productionsSelector as ItemSelector<unknown>)

export const jsonLdProductionsSelector = createSelector(
    productionsSelector,
    (productions: Production[]): JsonLdItem[] => productions.map(getProductionJson),
)

export const lastFetchedPageSelector = createSelector(
    [({ data }: ProductionListState) => data],
    (data: ProductionListData | undefined): number => data?.page ?? 0,
)

export const remainingProductionCountSelector = createSelector(
    [({ data }: ProductionListState) => data],
    (data: ProductionListData | undefined): number => {
        // Ensure the returned value is non-negative
        return Math.max((data?.total ?? 0) - VISIBLE_BY_DEFAULT_PRODUCTION_LISTINGS_LIMIT, 0)
    },
)

export const hasMoreProductionsSelector = createSelector(
    [({ data }: ProductionListState) => data],
    (data: ProductionListData | undefined): boolean => {
        return (data?.items.length || 0) < (data?.total || 0)
    },
)

export const visibleByDefaultProductionsSelector = createSelector(
    composedProductionsSelector,
    (composedProductions: ProductionListSelectorType[]): ProductionListSelectorType[] => {
        return composedProductions.filter(({ defaultVisible }: ProductionListSelectorType) => defaultVisible)
    },
)

export const hiddenByDefaultProductionsSelector = createSelector(
    composedProductionsSelector,
    (composedProductions: ProductionListSelectorType[]): ProductionListSelectorType[] => {
        return composedProductions.filter(({ defaultVisible }: ProductionListSelectorType) => !defaultVisible)
    },
)

export const hasScheduleContentTagsSelector = createSelector(
    composedProductionsSelector,
    (composedProductions: ProductionListSelectorType[]): boolean => {
        const productionsWithValidContentTags = composedProductions.filter(({ contentTags }) => {
            if (!contentTags?.length) return false
            return contentTags.some(({ label }) =>
                [
                    CONTENT_TAGS_TO_SEPARATE.NFL_PRESEASON,
                    CONTENT_TAGS_TO_SEPARATE.NFL_REGULAR_SEASON,
                    CONTENT_TAGS_TO_SEPARATE.NFL_FULL_SEASON,
                ].includes(label),
            )
        })
        return productionsWithValidContentTags.length > 0
    },
)

export const hasGiveawayContentTagsSelector = createSelector(
    composedProductionsSelector,
    (composedProductions: ProductionListSelectorType[]): boolean => {
        const productionsWithValidContentTags = composedProductions.filter(({ contentTags }) => {
            if (!contentTags?.length) return false
            return contentTags.some((tag) => tag.label === TAG_FILTER_OPTIONS[0])
        })
        return productionsWithValidContentTags.length > 0
    },
)

export const sortedByTicketQuantityProductionSelector = createSelector(
    productionsSelector,
    (productions: Production[]): Production[] => {
        return [...productions].sort((a, b) => {
            return (a.ticketCount || 0) - (b.ticketCount || 0)
        })
    },
)

export const dealsAvailableIdsSelector = createSelector(
    sortedByTicketQuantityProductionSelector,
    (sortedProductions: Production[]): number[] => {
        if (sortedProductions.length >= 10) {
            return sortedProductions.slice(-3).map((p) => p.id)
        }
        return []
    },
)

export const filterParamsSelector = createSelector(
    (input: [ProductionListParams, FilterMappers]) => input,
    ([params, filterMappers]): Partial<ProductionListParams> => {
        // Extract only supported filter parameters
        const filterParamKeys: string[] = Object.keys(filterMappers)

        const filterParams: Partial<ProductionListParams> = pickBy(
            params,
            (paramValue: ProductionListParamValue, paramKey: string) =>
                filterParamKeys.includes(paramKey) && isValuePresent(paramValue),
        )

        return filterParams
    },
)

export const hasFiltersAppliedSelector = createSelector(
    filterParamsSelector,
    (filterParams: Partial<ProductionListParams>): boolean =>
        !!Object.keys(filterParams).filter((key) => key !== QUERYPARAM && key !== CALENDAR_PARAM).length,
)

export const filteredProductionListTitleSelector = createSelector(
    productionsSelector,
    (productions: Production[]): string =>
        `${productions?.length || 0} Matching Event${(productions?.length || 0) !== 1 ? 's' : ''}`,
)

export const relatedProductionsListSelector = createSelector(
    productionsSelector,
    (productions: Production[]): ProductionListSelectorType[] => {
        return productions.map((production: Production, index) => {
            // Prioritize webPath over the deprecated organicUrl
            const url: string = production.webPath || production.organicUrl || ''

            return {
                ...formatProductionDates(production),
                ...formatVenueLocation(production.venue),
                venueName: production.venue.name,
                name: production.name,
                id: production.id,
                href: url,
                performers: production.performers,
                // keep the original production object for analytics
                analytics: { production, index },
            }
        })
    },
)

export const performerIdsSelector = createSelector(productionsSelector, (productions: Production[]): number[] => {
    const performerIds: number[] = []
    const performers = productions.map((production: Production) => production.performers)
    performers.forEach((performersByProduction: Performer[]) =>
        performersByProduction.forEach((performer: Performer) => {
            if (!performerIds.find((performerId: number) => performerId === performer.id)) {
                performerIds.push(performer.id)
            }
        }),
    )
    return performerIds
})
