export const decodeBase64Json = (encodedString: string | undefined) => {
    if (!encodedString) {
        return {}
    }
    const decoded = Buffer.from(encodedString, 'base64').toString()
    try {
        return JSON.parse(decoded)
    } catch {
        return {}
    }
}

/**
 * Plays a role in the initial mixing of the input data and helps ensure that
 * small differences in the input result in large differences in the hash
 * output. The choice of prime numbers in hashing functions helps achieve a good
 * distribution of hash values.
 *
 * Decimal: 2,654,435,857
 */
const PRIME_1 = 0x9e3779b1

/**
 * Used in the multiplication and rotation steps during the mixing process. This
 * constant further contributes to the diffusion of the input data into the hash
 * value, helping to minimize collisions.
 *
 * Decimal: 2,243,927,415
 */
const PRIME_2 = 0x85ebca77

/**
 * Used in the multiplication steps when processing chunks of the input data.
 * The use of this prime helps to ensure that the bits of the input data are
 * thoroughly mixed, contributing to the uniformity and unpredictability of the
 * hash output.
 *
 * Decimal: 3,259,396,413
 */
const PRIME_3 = 0xc2b2ae3d

/**
 * Involved in the mixing process, specifically in the final steps of mixing
 * each chunk of the input data. This constant helps to spread the influence of
 * each byte of the input across the entire hash value.
 *
 * Decimal: 666,029,799
 */
const PRIME_4 = 0x27d4eb2f

/**
 * Used in the initial computation of the hash value based on the seed and the
 * length of the input data. This constant helps to start the hashing process in
 * a well-distributed state, contributing to the overall effectiveness of the
 * hash function.
 *
 * Decimal: 374,761,393
 */
const PRIME_5 = 0x165667b1

/**
 * Writes a checksum using xxHash, a fast non-cryptographic hash algorithm
 * suitable for checksums and hash tables.
 *
 * @param input - The input string to hash, usually data stringified with `JSON.stringify()`
 * @param seed - [Optional] A seed value to initialize the hash, default is 0.
 *
 * @returns The 32-bit hash of the input string as an unsigned integer.
 */
export const fastChecksum = (input: string, seed = 0) => {
    const { length } = input

    let hash = seed + PRIME_5 + length
    let i = 0

    while (i <= length - 4) {
        let k1 = input.charCodeAt(i) & 0xff

        k1 |= (input.charCodeAt(i + 1) & 0xff) << 8
        k1 |= (input.charCodeAt(i + 2) & 0xff) << 16
        k1 |= (input.charCodeAt(i + 3) & 0xff) << 24

        k1 = Math.imul(k1, PRIME_3)
        k1 = (k1 << 17) | (k1 >>> (32 - 17))
        k1 = Math.imul(k1, PRIME_4)

        hash ^= k1
        hash = (hash << 13) | (hash >>> (32 - 13))
        hash = Math.imul(hash, 5) + 0xe6546b64

        i += 4
    }

    while (i < length) {
        let k1 = input.charCodeAt(i) & 0xff

        k1 = Math.imul(k1, PRIME_5)
        k1 = (k1 << 11) | (k1 >>> (32 - 11))
        k1 = Math.imul(k1, PRIME_1)

        hash ^= k1
        hash = (hash << 17) | (hash >>> (32 - 17))
        hash = Math.imul(hash, PRIME_4)

        i++
    }

    hash ^= hash >>> 15
    hash = Math.imul(hash, PRIME_2)
    hash ^= hash >>> 13
    hash = Math.imul(hash, PRIME_3)
    hash ^= hash >>> 16

    return hash >>> 0
}
