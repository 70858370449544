import React, { useContext } from 'react'

import { Column, Row, Text, Button, SectionContainer } from '@vividseats/vivid-ui-kit'

import BrowseByTrending from '@/components/shared/searchbar/components/focus-search-suggestions/components/browse-by-trending'
import SuggestionsContext from '@/context/suggestions'
import { useRecentSearchResults } from '@/hooks/use-recent-search-results'

import RecentSearchResultSuggestion from './components/recent-search-result-suggestion'
import styles from './styles.module.scss'

const FocusSearchSuggestions: React.FC = () => {
    // cursorPosition here depends on RecentSearh + Trending
    const {
        state: { cursorPosition },
    } = useContext(SuggestionsContext)
    const { recentSearchResults, removeAllRecentSearchResults } = useRecentSearchResults()

    return (
        <SectionContainer className={styles.container} data-testid="focus-search-suggestions">
            {recentSearchResults.length !== 0 && (
                <Column mb={0.4375} className={styles.recentColumn}>
                    <Row justify="between" align="center">
                        <Text size="subtitle-3xl" state="hover" weight="bold" altFont>
                            Recent
                        </Text>
                        <Button
                            data-testid="clear-button"
                            importance="primary"
                            size="tiny"
                            noFill
                            onClick={removeAllRecentSearchResults}
                        >
                            Clear
                        </Button>
                    </Row>
                </Column>
            )}
            {recentSearchResults.map((item, index) => (
                <Column key={index} mb={1} noFlex>
                    <RecentSearchResultSuggestion
                        recentSearchResult={item}
                        index={index}
                        isSelected={cursorPosition === index + 1}
                    />
                </Column>
            ))}
            <>
                <Column pl={0.25} mb={1} className={styles.browseTitle}>
                    <Row justify="between" align="center">
                        <Text size="subtitle-3xl" state="hover" weight="bold" altFont>
                            Trending
                        </Text>
                    </Row>
                </Column>
                <Column pl={0.25} pr={0.25} noFlex>
                    <BrowseByTrending
                        displayAll={recentSearchResults.length === 0}
                        selectedIndex={cursorPosition - recentSearchResults.length - 1}
                    />
                </Column>
            </>
        </SectionContainer>
    )
}

export default FocusSearchSuggestions
