import React, { createContext, useContext } from 'react'

import { createSelector } from 'reselect'

import { serverSideQuery, useQuery } from '@/api'
import {
    CATEGORY_IDS,
    MAX_DRIVING_DISTANCE,
    MAX_NEARBY_DISTANCE,
    MAX_PRODUCTIONS_TO_SHOW,
    PRODUCTIONS_ENDPOINT,
} from '@/constants'
import ProductionListContext from '@/context/productions-list'
import { productionListSelectorCreator } from '@/context/utils/production'
import useBadgedProductions from '@/hooks/use-badged-productions'
import type { GenericResultType, Production, ProductionListSelectorType, ProductionPage } from '@/types/app-types'

import type { FeaturedProductionsParams, FeaturedProductionsProps } from './types'
import { mergeProductions } from './utils'

const defaultState: FeaturedProductionsSelectorType = {
    totalShowsNearby: 0,
    totalShowsInDrivingDistance: 0,
    isLoading: true,
}
const context = createContext(defaultState)
const { Provider } = context

export interface FeaturedProductionsSelectorType {
    closestProduction?: ProductionListSelectorType
    nearbyProductions?: ProductionListSelectorType[]
    nearbyBadgedProductions?: ProductionListSelectorType[]
    drivingDistanceProductions?: ProductionListSelectorType[]
    drivingDistanceBadgedProductions?: ProductionListSelectorType[]
    totalShowsNearby: number
    showCountLabel?: string
    noProductionsMessage?: string
    showsWithinDrivingDistanceLabel?: string
    totalShowsInDrivingDistance: number
    isLoading?: boolean
    initialParams?: FeaturedProductionsParams
}

interface ProductionListState {
    data?: Partial<GenericResultType<Production>>
}

const productionListSelector = productionListSelectorCreator<ProductionListState>((state) => state?.data?.items)

const productionsSelector = createSelector(productionListSelector, (items = []): FeaturedProductionsSelectorType => {
    const productions: FeaturedProductionsSelectorType = {
        nearbyProductions: [],
        drivingDistanceProductions: [],
        totalShowsNearby: 0,
        totalShowsInDrivingDistance: 0,
    }

    let productionLabel = 'Show'
    if (items && items[0]) {
        const categoryId = items[0].performers.filter((item) => item.master === true)[0].category.id
        if (categoryId === CATEGORY_IDS.SPORTS) {
            productionLabel = 'Event'
        }
    }

    items.forEach((production) => {
        if (production.isNearByDistance) {
            productions.nearbyProductions?.push(production)
            productions.totalShowsNearby += 1
        } else if (production.isDriveDistance) {
            productions.drivingDistanceProductions?.push(production)
            productions.totalShowsInDrivingDistance += 1
        }
    })

    if (productions.totalShowsNearby > 0) {
        productions.showCountLabel = `${productionLabel}s Near `
    } else {
        productions.showCountLabel = `No ${productionLabel}s Near `
    }

    productions.showsWithinDrivingDistanceLabel =
        productions.totalShowsInDrivingDistance > 0
            ? `${productions.totalShowsInDrivingDistance} ${productionLabel}${
                  productions.totalShowsInDrivingDistance === 1 ? '' : 's'
              } Within 150 Miles of `
            : `We couldn't find any shows near `

    return productions
})

export interface ProductionsWithBadgesInput {
    readonly dealsAvailableIds: number[]
}

const productionsWithBadges = createSelector(
    [(input: ProductionsWithBadgesInput) => input, productionsSelector],
    ({ dealsAvailableIds }, featuredProductions) => {
        featuredProductions.nearbyBadgedProductions = featuredProductions.nearbyProductions?.map((nearbyProduction) => {
            const badgedProduction: ProductionListSelectorType = { ...nearbyProduction } // so changes dont affect nearbyProductions
            badgedProduction.isLowPricesDealsAvailable = dealsAvailableIds.includes(badgedProduction.id)
            return badgedProduction
        })
        featuredProductions.drivingDistanceBadgedProductions = featuredProductions.drivingDistanceProductions?.map(
            (nearbyProduction) => {
                const badgedProduction: ProductionListSelectorType = { ...nearbyProduction } // so changes dont affect nearbyProductions
                badgedProduction.isLowPricesDealsAvailable = dealsAvailableIds.includes(badgedProduction.id)
                return badgedProduction
            },
        )
        return featuredProductions
    },
)

export interface ProductionsWithBummerLabelInput {
    readonly productionList: Production[]
    readonly hasFiltersApplied: boolean
}

const productionsWithBummerLabel = createSelector(
    [(input: ProductionsWithBummerLabelInput) => input, productionsWithBadges],
    ({ productionList, hasFiltersApplied }, featuredProductions) => {
        delete featuredProductions.noProductionsMessage
        if (hasFiltersApplied) {
            return featuredProductions
        }
        if (productionList.length === 0) {
            featuredProductions.noProductionsMessage = 'Bummer, no events happening'
        }
        return featuredProductions
    },
)

export interface ProductionsWithSearchEngineMarketingCityInput {
    readonly searchEngineMarketingCity?: string
}

const productionsWithSearchEngineMarketingCity = createSelector(
    [(input: ProductionsWithSearchEngineMarketingCityInput) => input, productionsWithBummerLabel],
    ({ searchEngineMarketingCity }, featuredProductions) => {
        if (searchEngineMarketingCity) {
            return {
                ...featuredProductions,
                showsWithinDrivingDistanceLabel: featuredProductions.showsWithinDrivingDistanceLabel?.replace(
                    'You',
                    searchEngineMarketingCity,
                ),
                noProductionsMessage: featuredProductions.noProductionsMessage?.replace(
                    'You',
                    searchEngineMarketingCity,
                ),
            }
        }
        return featuredProductions
    },
)

const FeaturedProductionsProvider: React.$FC<FeaturedProductionsProps> = ({
    children,
    initialParams,
    initialFetchDisabled,
    initialProps,
}) => {
    const {
        state: { fetching },
        selectors: { productions: productionList, hasFiltersApplied },
    } = useContext(ProductionListContext)
    const { dealsAvailableIds } = useBadgedProductions()

    const { data: featuredProductionsData, fetching: isFetchingNearby } = useQuery<ProductionPage>({
        endpoint: PRODUCTIONS_ENDPOINT,
        params: { ...initialParams, radius: MAX_NEARBY_DISTANCE, pageSize: MAX_PRODUCTIONS_TO_SHOW },
        initialFetchDisabled,
        initialData: initialProps?.data,
    })

    const isFeaturedProductionsDriveDistanceEnabled = !featuredProductionsData?.items.length

    const { data: featuredProductionsDriveDistanceData, fetching: isFetchingDrivingDistance } =
        useQuery<ProductionPage>({
            endpoint: PRODUCTIONS_ENDPOINT,
            params: { ...initialParams, radius: MAX_DRIVING_DISTANCE, pageSize: MAX_PRODUCTIONS_TO_SHOW },
            initialFetchDisabled,
            enabled: isFeaturedProductionsDriveDistanceEnabled,
        })

    const mutableResult = {
        ...featuredProductionsDriveDistanceData,
    }

    if (featuredProductionsDriveDistanceData && featuredProductionsData) {
        const nearbyProductionsId = featuredProductionsData.items
            .filter((production) => production.distance === undefined || production.distance > MAX_DRIVING_DISTANCE)
            .map((production) => production.id)
        mutableResult.items = featuredProductionsDriveDistanceData.items.map((production) => ({
            ...production,
            isDriveDistance: !nearbyProductionsId.includes(production.id),
        }))
    }

    const formattedDataWithSEMCity: FeaturedProductionsSelectorType = productionsWithSearchEngineMarketingCity({
        data: isFeaturedProductionsDriveDistanceEnabled
            ? featuredProductionsDriveDistanceData
            : featuredProductionsData,
        dealsAvailableIds,
        hasFiltersApplied,
        productionList,
        searchEngineMarketingCity: initialParams?.searchEngineMarketingCity,
    })

    const isLoading = fetching || isFetchingDrivingDistance || isFetchingNearby

    // temporarily commenting out the event tracking to test impact on sessions
    // const { totalShowsNearby, totalShowsInDrivingDistance } = formattedDataWithSEMCity
    // useEffect(() => {
    //     if (isLoading) return
    //     if (window && totalShowsNearby > 0)
    //         trackEvent('Featured Components Section', 'Load Row 1', 'Near You', totalShowsNearby)
    //     if (window && totalShowsInDrivingDistance > 0)
    //         trackEvent('Featured Components Section', 'Load Row 2', 'Within 150 Miles', totalShowsInDrivingDistance)
    //     if (window && totalShowsNearby + totalShowsInDrivingDistance < 1)
    //         trackEvent('Featured Components Section', undefined, 'Lead Capture', 1)
    // }, [totalShowsNearby, totalShowsInDrivingDistance, isLoading])

    return <Provider value={{ ...formattedDataWithSEMCity, isLoading, initialParams }}>{children}</Provider>
}

export { context as default, FeaturedProductionsProvider }

export const serverSideRead = async ({
    performerId,
    latLong,
    includeIpAddress,
}: {
    performerId: string | number
    latLong?: string
    includeIpAddress?: boolean
}): Promise<ProductionPage> => {
    const queryInstance = serverSideQuery<ProductionPage>(PRODUCTIONS_ENDPOINT)
    const params = {
        performerId,
        radius: MAX_NEARBY_DISTANCE,
        pageSize: MAX_PRODUCTIONS_TO_SHOW,
        latLong,
        includeIpAddress,
    }
    const featuredProductionsData = await queryInstance(params)
    if (featuredProductionsData.items.length < 10) {
        const featuredProductionsDriveDistance = await queryInstance({ ...params, radius: MAX_DRIVING_DISTANCE })
        return mergeProductions(featuredProductionsData, featuredProductionsDriveDistance)
    }
    return featuredProductionsData
}
