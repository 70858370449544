import type { FC } from 'react'
import React from 'react'

import type { WithOptimizelyProps } from '@optimizely/react-sdk'
import { withOptimizely } from '@optimizely/react-sdk'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { getHomepageRedesignVariable } from '@/components/pages/home/utils'
import NoSsr from '@/components/shared/no-ssr'
import useWindowSize from '@/hooks/use-window-size'
import { DeviceType } from '@/hooks/use-window-size/types'
import { useLocalizedCurrencyFeature } from '@/optimizely/features/internationalization/hooks/localized-currency'
import MobileMainMenu from '@/optimizely/features/search-bar-feature/components/sticky-search-bar-header/components/mobile'
import { useSearchBarFeature } from '@/optimizely/features/search-bar-feature/hooks/use-search-bar-feature'
import { useTrendingCategoryFeature } from '@/optimizely/features/trending-category-feature'

import Desktop from './components/desktop'
import Mobile from './components/mobile'
import styles from './styles.module.scss'

interface MainMenuProps extends WithOptimizelyProps {
    className?: string
    isCenterSearch: boolean
}

const MainMenu: FC<MainMenuProps> = ({ className, optimizely, isCenterSearch }) => {
    const { type } = useWindowSize()
    const { pathname } = useRouter()
    const isHome = pathname === '/home'
    const [isTrendingCategoryEnabled] = useTrendingCategoryFeature()
    const [isSearchBarFeatureEnabled] = useSearchBarFeature(optimizely)
    const { aboveTheFold: shouldAboveTheFold } = getHomepageRedesignVariable(optimizely, type)
    const [isLocalizedCurrencyEnabled] = useLocalizedCurrencyFeature()

    const isMobileAboveTheFold = type === DeviceType.MOBILE && shouldAboveTheFold

    const mergedMainMenuClassName = classNames(
        styles[isLocalizedCurrencyEnabled ? 'mainMenuCurrency' : 'mainMenu'],
        className,
        {
            [styles.trendingMenu]: isTrendingCategoryEnabled && !isCenterSearch,
            [styles.isMobileAboveTheFold]: isMobileAboveTheFold,
            [styles.homePageMainMenu]: isHome,
        },
    )

    return (
        <div data-testid="main-menu" className={mergedMainMenuClassName}>
            <NoSsr>
                <Desktop className={styles.desktop} />
                {isSearchBarFeatureEnabled ? (
                    <MobileMainMenu className={styles.mobile} isMobileAboveTheFold={isMobileAboveTheFold} />
                ) : (
                    <Mobile className={styles.mobile} isMobileAboveTheFold={isMobileAboveTheFold} />
                )}
            </NoSsr>
        </div>
    )
}

export default withOptimizely(MainMenu)
