import { UserAttributes } from '@optimizely/optimizely-sdk'
import { ReactSDKClient } from '@optimizely/react-sdk'
import { UserInfo } from '@optimizely/react-sdk/dist/utils'
import cloneDeep from 'lodash/cloneDeep'

import { applyUserInfo } from '@/utils/optimizely/utils'

/*
NOTE: Custom hook to set Optimizely user attributes.
*/

const useOptimizelyUser = (
    optimizely: ReactSDKClient | null,
    userInfoSetter?: (attributes: UserAttributes) => void,
): UserInfo | undefined => {
    const user = cloneDeep(optimizely?.user)
    if (user?.attributes) {
        if (userInfoSetter) {
            userInfoSetter(user.attributes)
            applyUserInfo(optimizely, user)
        }
    }

    return user
}

export default useOptimizelyUser
