import React, { FC, useEffect } from 'react'

import { Box, Link, ResponsiveLazyImage, Text, Span } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import CopyrightText from '@/components/shared/copyright-text'
import NoSsr from '@/components/shared/no-ssr'
import { ACCESSIBILITY_IDS } from '@/constants'
import type { LabelLink } from '@/types/app-types'

import content from './constants'
import styles from './styles.module.scss'

const FOOTER_LOGO_SRC = 'https://a.vsstatic.com/common/logo/vs-wordmark-white.png'
const LOGO_IMG_HEIGHT = 32
const LOGO_IMG_WIDTH = 140

const Footer: FC = () => {
    const { bottom, top } = content

    useEffect(() => {
        // to kick off the privacy preferences link in the footer powered by trustarc
        window.truste?.eu?.icon?.initialize()
    }, [])

    const footerContent = (
        <>
            <NoSsr>
                <ResponsiveLazyImage
                    className={styles.logo}
                    fallbackUrl={FOOTER_LOGO_SRC}
                    widths={{
                        mobile: LOGO_IMG_WIDTH,
                        desktop: LOGO_IMG_WIDTH,
                    }}
                    heights={{
                        mobile: LOGO_IMG_HEIGHT,
                        desktop: LOGO_IMG_HEIGHT,
                    }}
                />
            </NoSsr>
            <div className={styles.sectionContainer}>
                <div className={styles.linkSection}>
                    {top.map(({ title, items: links }, i) => (
                        <div key={`${title}-${i}`} className={styles.linkColumn}>
                            <Text className={styles.label} as="label" height="expanded" size="md" weight="bold">
                                {title}
                            </Text>
                            {links.map(({ label, to }, j) =>
                                to ? (
                                    <Link
                                        key={`${label}-${j}`}
                                        LinkComponent={NextLink}
                                        className={styles.link}
                                        href={to}
                                        isExternal
                                        legacyBehavior
                                    >
                                        <Text as="span" height="compressed" size="md" weight="regular">
                                            {label}
                                        </Text>
                                    </Link>
                                ) : (
                                    <Text
                                        key={`${label}-${j}`}
                                        as="label"
                                        height="compressed"
                                        size="md"
                                        weight="regular"
                                    >
                                        {label}
                                    </Text>
                                ),
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.legalSection}>
                    {/* copyright text appears after legal links on mobile and before on desktop */}
                    <Box
                        className={styles.copyrightWrapper}
                        desktopDisplay={'block'}
                        mobileDisplay={'none'}
                        tabletDisplay={'none'}
                    >
                        <CopyrightText />
                    </Box>
                    <div className={styles.legalLinks}>
                        {bottom.map((link, i) => {
                            const { id, label, to } = link as LabelLink
                            return to ? (
                                <Link
                                    LinkComponent={NextLink}
                                    className={styles.link}
                                    key={`${i}-${label}`}
                                    href={to || ''}
                                    isExternal
                                    legacyBehavior
                                >
                                    <Text as="span" size="md" weight="regular">
                                        <Span id={id}>{label}</Span>
                                    </Text>
                                </Link>
                            ) : (
                                <Text
                                    as="label"
                                    size="md"
                                    weight="regular"
                                    className={styles.label}
                                    key={`${i}-${label}`}
                                >
                                    {label}
                                </Text>
                            )
                        })}
                    </div>
                    <Box
                        className={styles.copyright}
                        desktopDisplay={'none'}
                        mobileDisplay={'block'}
                        tabletDisplay={'block'}
                    >
                        <CopyrightText />
                    </Box>
                </div>
            </div>
        </>
    )

    return (
        <footer id={ACCESSIBILITY_IDS.FOOTER} className={styles.footer}>
            <div className={styles.container}>{footerContent}</div>
        </footer>
    )
}

export default Footer
