import { atom } from 'recoil'

export interface IsFirstPageInSession {
    url: string
}

export const firstPageAtom = atom<IsFirstPageInSession>({
    key: 'firstPageState',
    default: undefined,
})
