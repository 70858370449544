import React, { FC, useContext, useEffect, useState } from 'react'

import { Backdrop } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import Link from 'next/link'

import mainMenuContext from '@/components/layouts/main-layout/components/header/components/main-menu/context'
import NoSsr from '@/components/shared/no-ssr'
import { useHomeLinkWithUrlAppend } from '@/hooks/use-home-link-with-url-append'
import { VividSeatsLogotypeIcon as LogotypeIcon } from '@/icons/logos/logotype'
import { useTrendingCategoryFeature } from '@/optimizely/features/trending-category-feature'

import Menu from '../menu'

import styles from './styles.module.scss'

const NavContainer: FC = () => {
    const [isComponentMounted, setIsComponentMounted] = useState(false)
    useEffect(() => setIsComponentMounted(true), [])

    const {
        state: { activeSubmenuPath, isNavOpen },
        selectors: { mobileMenuItems: menuItems },
        dispatch: { selectSubmenu, toggleNav, unselectSubmenu },
    } = useContext(mainMenuContext)

    const homeLink = useHomeLinkWithUrlAppend()
    const [isTrendingCategoryEnabled] = useTrendingCategoryFeature()

    const menuHeaderElement = (
        <Link className={styles.logoLink} href={homeLink}>
            <LogotypeIcon className={styles.logo} />
        </Link>
    )

    const mergedNavContainerClassName = classNames(styles.navContainer, { [styles.open]: isNavOpen })
    const mergedBackdropContainerClassName = classNames(styles.backdropContainer, {
        [styles.trending]: isTrendingCategoryEnabled,
    })

    return isComponentMounted ? (
        <NoSsr>
            <div className={mergedBackdropContainerClassName}>
                <Backdrop zIndex="sheet" data-testid="backdrop" isOpen={isNavOpen} onClick={() => toggleNav(false)} />
                <div className={mergedNavContainerClassName} data-testid="nav-container">
                    <div className={styles.menuHeaderBackground} />
                    <nav className={styles.navContent} style={{ left: `-${activeSubmenuPath.length * 100}%` }}>
                        <Menu
                            active
                            activeSubmenuPath={activeSubmenuPath}
                            backTitle="Main Menu"
                            parentLabel={''}
                            headerElement={menuHeaderElement}
                            menuItems={menuItems}
                            isTrendingCategoryEnabled={!!isTrendingCategoryEnabled}
                            onBackButtonClick={() => unselectSubmenu()}
                            onCloseButtonClick={() => toggleNav(false)}
                            onItemClick={selectSubmenu}
                            isNavOpen={isNavOpen}
                        />
                    </nav>
                </div>
            </div>
        </NoSsr>
    ) : null
}

export default NavContainer
