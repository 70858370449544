import React, { forwardRef, HTMLAttributes } from 'react'

import { Row, Text, Box, LazyBackgroundImage } from '@vividseats/vivid-ui-kit'
import classnames from 'classnames'
import Link from 'next/link'

import ShopButton from '@/components/shared/searchbar/components/shop-button'

import styles from './styles.module.scss'

interface PerformerListingRowType extends HTMLAttributes<HTMLAnchorElement> {
    name?: string
    imageUrl?: string
    mainPerformerSubCategoryName?: string
    productionCount?: number
    href?: string
    isSelected?: boolean
}

const PerformerSuggestionContent: React.ForwardRefRenderFunction<
    React.MutableRefObject<unknown>,
    PerformerListingRowType
> = (
    { name, imageUrl, mainPerformerSubCategoryName, productionCount, href, isSelected, children, ...props },
    ref: any,
) => {
    const performerRowClassnames = classnames(styles.performerRow, {
        [styles.selected]: isSelected,
    })
    return (
        <Link href={href || ''} {...props} ref={ref} className={styles.link} tabIndex={-1}>
            <Row align="center" justify="between" className={performerRowClassnames} data-testid="performer-row">
                <Row align="center" className={styles.performerItem}>
                    <Box className={styles.performerRowColumn}>
                        <LazyBackgroundImage url={imageUrl} className={styles.performerRowThumbnail} />
                    </Box>
                    <Box className={styles.performerRowInfo}>
                        <Text altFont size="lg" weight="regular-bold" className={styles.name}>
                            {name}
                        </Text>
                        <Row>
                            <Text altFont size="sm">
                                {mainPerformerSubCategoryName}
                            </Text>
                            <Text altFont size="sm" className={styles.category}>
                                {` • ${productionCount} Events`}
                            </Text>
                        </Row>
                    </Box>
                </Row>
                <Row>
                    <ShopButton
                        className={{ buttonText: styles.shopButtonText, buttonArrow: styles.shopButtonArrow }}
                    />
                </Row>
                {children}
            </Row>
        </Link>
    )
}

export default forwardRef(PerformerSuggestionContent)
