import React from 'react'

import { Button } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'

import useMarketplaceDisclaimer from '@/components/layouts/main-layout/hooks/use-marketplace-disclaimer'
import { ACCESSIBILITY_IDS } from '@/constants'

import styles from './styles.module.scss'

// "Skip to Content" and "Skip to Footer" buttons when tabbing
const AccessibilityNavigation: React.FC = () => {
    const { hasDisclaimers } = useMarketplaceDisclaimer()

    return (
        <>
            <Button
                href={`#${ACCESSIBILITY_IDS.CONTENT}`}
                size="base"
                id="skip-to-content"
                tabIndex={0}
                className={classNames(styles.skipToContent, !hasDisclaimers && styles.skipToContentNoBanner)}
            >
                Skip to Content
            </Button>
            <Button
                href={`#${ACCESSIBILITY_IDS.FOOTER}`}
                size="base"
                id="skip-to-footer"
                tabIndex={0}
                className={classNames(styles.skipToFooter, !hasDisclaimers && styles.skipToFooterNoBanner)}
            >
                Skip to Footer
            </Button>
        </>
    )
}

export default AccessibilityNavigation
