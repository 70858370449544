import { useCallback, useMemo } from 'react'

import { useLocalStorage } from 'usehooks-ts'

import { MAX_RECENT_SEARCH_RESULTS, RECENT_SEARCH_RESULTS_KEY } from './constants'
import type { RecentSearchResult, UseRecentSearchResults } from './types'
import { excludeRecentSearchResult } from './utils'

export const useRecentSearchResults = (): UseRecentSearchResults => {
    const [recentSearchResults, setRecentSearchResults] = useLocalStorage<RecentSearchResult[]>(
        RECENT_SEARCH_RESULTS_KEY,
        [],
    )

    const addRecentSearchResult = useCallback(
        (recentSearchResult: RecentSearchResult) => {
            const { type, title, url } = recentSearchResult
            if (type && title && url) {
                setRecentSearchResults((prevRecentSearchResults: RecentSearchResult[]) =>
                    [
                        recentSearchResult,
                        // Exclude duplicate search results
                        ...excludeRecentSearchResult(prevRecentSearchResults, recentSearchResult),
                    ].slice(0, MAX_RECENT_SEARCH_RESULTS),
                )
            }
        },
        [setRecentSearchResults],
    )

    const removeRecentSearchResult = useCallback(
        (recentSearchResult: RecentSearchResult) => {
            setRecentSearchResults((prevRecentSearchResults: RecentSearchResult[]) =>
                excludeRecentSearchResult(prevRecentSearchResults, recentSearchResult),
            )
        },
        [setRecentSearchResults],
    )

    const removeAllRecentSearchResults = useCallback(() => setRecentSearchResults([]), [setRecentSearchResults])

    const result: UseRecentSearchResults = useMemo(
        () => ({ recentSearchResults, addRecentSearchResult, removeRecentSearchResult, removeAllRecentSearchResults }),
        [recentSearchResults, addRecentSearchResult, removeRecentSearchResult, removeAllRecentSearchResults],
    )

    return result
}
