import { useContext, useMemo } from 'react'

import { useRouter } from 'next/router'
import { useRecoilValue } from 'recoil'

import { SECONDARY_MARKETPLACE_DISCLAIMER_STATES, UK } from '@/constants'
import { BUYER_GUARANTEE_EXCLUDED_COUNTRIES } from '@/context/internationalization/constants'
import { useIsCountryFeatured } from '@/context/location/hooks/use-is-country-featured'
import productionDetailsContext from '@/context/production-details'
import userDataContext from '@/context/user-data'
import { useGeneralAdmissionUKFeature } from '@/optimizely/features/internationalization/hooks/general-admission-uk'
import { utahPerformerVenueAtom } from '@/recoil/atoms/utahPerformerVenueAtom'

import type { DisclaimerType, UseMarketplaceDisclaimerResult } from './types'

const useMarketplaceDisclaimer = (): UseMarketplaceDisclaimerResult => {
    const {
        selectors: { isFromAdWordsSelector: hasPaidSearchDisclaimer },
    } = useContext(userDataContext)

    const {
        selectors: { productionDetails },
    } = useContext(productionDetailsContext)

    const [isGeneralAdmissionUKEnabled] = useGeneralAdmissionUKFeature()
    const hideBuyerGuaranteeForCountry =
        useIsCountryFeatured(BUYER_GUARANTEE_EXCLUDED_COUNTRIES) ||
        productionDetails?.isDoaActive ||
        productionDetails?.seatRestrictions
    const displayBuyerGuarantee = !(isGeneralAdmissionUKEnabled && hideBuyerGuaranteeForCountry)
    const { isUtahPerformerOrVenue } = useRecoilValue(utahPerformerVenueAtom)

    const { pathname } = useRouter()

    const result: UseMarketplaceDisclaimerResult = useMemo(() => {
        const hasDOADisclaimer: boolean =
            isGeneralAdmissionUKEnabled && pathname.includes('/production/[id]') && !!productionDetails?.isDoaActive
        const hasSeatRestrictionsDisclaimer: boolean =
            isGeneralAdmissionUKEnabled &&
            pathname.includes('/production/[id]') &&
            !!productionDetails?.seatRestrictions &&
            !productionDetails.isDoaActive &&
            productionDetails?.venue.countryCode === UK
        const hasSecondaryMarketplaceDisclaimer: boolean =
            (pathname.includes('/production/[id]') &&
                !!productionDetails &&
                SECONDARY_MARKETPLACE_DISCLAIMER_STATES.includes(productionDetails.venue.state.toUpperCase())) ||
            isUtahPerformerOrVenue
        // On desktops always add 100% buyer guarantee disclaimer first unless the user is in the UK: I18N-165
        const desktopDisclaimerTypes: DisclaimerType[] = displayBuyerGuarantee ? ['buyerGuarantee'] : []
        // On desktops secondary marketplace disclaimer should override paid search disclaimer
        if (hasDOADisclaimer) {
            desktopDisclaimerTypes.push('DOA')
        } else if (hasSeatRestrictionsDisclaimer) {
            desktopDisclaimerTypes.push('seatingRestrictions')
        } else if (hasSecondaryMarketplaceDisclaimer) {
            desktopDisclaimerTypes.push('secondaryMarketplace')
        } else if (hasPaidSearchDisclaimer) {
            desktopDisclaimerTypes.push('paidSearch')
        }

        const mobileDisclaimerTypes: DisclaimerType[] = []
        // On mobiles secondary marketplace disclaimer should override paid search disclaimer
        // And paid search disclaimer should override 100% buyer guarantee disclaimer
        if (hasDOADisclaimer) {
            mobileDisclaimerTypes.push('DOA')
        } else if (hasSeatRestrictionsDisclaimer) {
            mobileDisclaimerTypes.push('seatingRestrictions')
        } else if (hasSecondaryMarketplaceDisclaimer) {
            mobileDisclaimerTypes.push('secondaryMarketplace')
        } else if (hasPaidSearchDisclaimer) {
            mobileDisclaimerTypes.push('paidSearch')
        } else if (displayBuyerGuarantee) {
            mobileDisclaimerTypes.push('buyerGuarantee')
        }

        return {
            hasDisclaimers: !!Math.max(desktopDisclaimerTypes.length, mobileDisclaimerTypes.length),
            desktopDisclaimerTypes,
            mobileDisclaimerTypes,
        }
    }, [
        hasPaidSearchDisclaimer,
        pathname,
        productionDetails,
        isUtahPerformerOrVenue,
        displayBuyerGuarantee,
        isGeneralAdmissionUKEnabled,
    ])

    return result
}

export default useMarketplaceDisclaimer
