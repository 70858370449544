// Define general type for useWindowSize hook, which includes width and height

export enum DeviceType {
    DESKTOP_XL = 'desktopXL',
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile',
}

export interface Size {
    width: number
    height: number
    type: DeviceType
}
